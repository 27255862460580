import { Navigate, Route, Routes } from "react-router-dom"
import { Listado } from "../../views/Devoluciones/Listado";
import { Form } from "../../views/Devoluciones/Form";
import { Detalle } from "../../views/Devoluciones/Detalle";
import { MotivosRouter } from "./MotivosRouter";
import { BancosRouter } from "./BancosRouter";
import { ReportesRouter } from "./ReportesRouter";


export const DevolucionesRouter = () => {
   return (
      <Routes>
         <Route path='/' element={<Listado />} />

         <Route path='/create' element={<Form />} />

         <Route path='/update/:id' element={<Form />} />

         <Route path='/detalle/:id' element={<Detalle />} />

         <Route path='/motivos/*' element={<MotivosRouter />} />

         <Route path='/bancos/*' element={<BancosRouter />} />

         <Route path='/reportes/*' element={<ReportesRouter />} />

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   );
}
