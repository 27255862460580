import {
   FILTER_FECHA_FINAL,
   FILTER_FECHA_INICIO,
   FILTER_CLEAR,
   RESULTADOS_LOGISTICA_LINEAS_DESPACHO,
   FILTER_CODIGO_VENDEDOR,
   FILTER_ADD_BODEGA,
   FILTER_ADD_METODO_ENTREGA,
   FILTER_REMOVE_METODO_ENTREGA,
} from "../redux-types";

const initialState = {
   filtros: {
      fecha_desde: '',
      fecha_hasta: '',
      cod_vendedor: '',
      cod_bodega: '',
      cods_metodos_entrega: [],
   },
   resultados: null
}


export const logisticaLineasDespachoReducer = (state = initialState, action) => {
   let isListed

   switch (action.type) {
      case FILTER_FECHA_FINAL:
         return {
            ...state, filtros: {
               ...state.filtros,
               fecha_hasta: action.payload
            }
         };

      case FILTER_FECHA_INICIO:
         return {
            ...state, filtros: {
               ...state.filtros,
               fecha_desde: action.payload
            }
         };

      case FILTER_CODIGO_VENDEDOR:
         return {
            ...state, filtros: {
               ...state.filtros,
               cod_vendedor: action.payload
            }
         };

      case FILTER_ADD_BODEGA:
         return {
            ...state, filtros: {
               ...state.filtros,
               cod_bodega: action.payload
            }
         };

      case FILTER_ADD_METODO_ENTREGA:
         isListed = state.filtros.cods_metodos_entrega.find(el => el === action.payload)
         return {
            ...state, filtros: {
               ...state.filtros,
               cods_metodos_entrega: isListed
                  ? state.filtros.cods_metodos_entrega
                  : [...state.filtros.cods_metodos_entrega, action.payload]
            }
         };

      case FILTER_REMOVE_METODO_ENTREGA:
         return {
            ...state, filtros: {
               ...state.filtros,
               cods_metodos_entrega: state.filtros.cods_metodos_entrega.filter(el => el !== action.payload)
            }
         };

      case RESULTADOS_LOGISTICA_LINEAS_DESPACHO:
         return {
            ...state, resultados: action.payload
         };

      case FILTER_CLEAR:
         return {
            ...state,
            filtros: initialState.filtros
         };

      default:
         return state;
   }
};