export const isValidCreateForm = (form) => {
  const stringValuesArr = ['hora_despacho', 'hora_cierre']
  const intValuesArr = ['']
  const idValuesArr = ['metodo_entrega_id']
  const boolValuesArr = ['es_despacho_express']
  const required = ['hora_despacho', 'hora_cierre']

  let result = { isValid: true }

  Object.entries(form).forEach(([k, v]) => {
    if (required.includes(k) && !v?.length) {
      result = {
        ...result,
        [k]: `[required]`,
        isValid: false
      }
    }

    // strings
    if (stringValuesArr.includes(k) && typeof v === 'string' && !v?.length) {
      result = {
        ...result,
        [k]: `[${typeof v}], value: v, length: ${v?.length ?? 0}`,
        isValid: false
      }
      return
    }

    // int
    if (intValuesArr.includes(k) && (typeof Number(v) !== 'number' || Number(v) < 0)) {
      result = {
        ...result,
        [k]: `[${typeof v}], value: ${v}`,
        isValid: false
      }
      return
    }

    // id
    if (idValuesArr.includes(k) && Number(v) <= 0) {
      result = {
        ...result,
        [k]: `[${typeof v}], value: ${v}`,
        isValid: false
      }
      return
    }

    // bool
    if (boolValuesArr.includes(k) && typeof v === 'number' && v < 0) {
      result = {
        ...result,
        [k]: `[${typeof v}], value: ${v}`,
        isValid: false
      }
      return
    }
  })

  return result
}

export const isValidUpdateForm = (form) => {
  const stringValuesArr = ['hora_despacho', 'hora_cierre']
  const intValuesArr = ['']
  const idValuesArr = ['metodo_entrega_id']
  const boolValuesArr = ['es_despacho_express']
  const required = ['hora_despacho', 'hora_cierre']

  let result = { isValid: true }

  Object.entries(form).forEach(([k, v]) => {
    if (required.includes(k) && !v?.length) {
      result = {
        ...result,
        [k]: `[required]`,
        isValid: false
      }
    }

    // strings
    if (stringValuesArr.includes(k) && typeof v === 'string' && !v?.length) {
      result = {
        ...result,
        [k]: `[${typeof v}], value: v, length: ${v?.length ?? 0}`,
        isValid: false
      }
      return
    }

    // int
    if (intValuesArr.includes(k) && (typeof Number(v) !== 'number' || Number(v) < 0)) {
      result = {
        ...result,
        [k]: `[${typeof v}], value: ${v}`,
        isValid: false
      }
      return
    }

    // id
    if (idValuesArr.includes(k) && Number(v) <= 0) {
      result = {
        ...result,
        [k]: `[${typeof v}], value: ${v}`,
        isValid: false
      }
      return
    }

    // bool
    if (boolValuesArr.includes(k) && typeof v === 'number' && v < 0) {
      result = {
        ...result,
        [k]: `[${typeof v}], value: ${v}`,
        isValid: false
      }
      return
    }
  })

  return result
}