import React from 'react'
import Modal from '../../Modal'
import Table from '../../Table';

export const ModalLineaDespachoDetalle = ({ display, handleDisplay, data }) => {
  const handleCloseFull = () => {
    handleDisplay(null)
  }


  return (
    <Modal
      size={`col-8`}
      sizeDesk={`col-md-12`}
      display={display}
      handleValue={handleCloseFull}
    >
      <div>
        <h3>Líneas neúmaticos | Nota de Venta #{data?.nv?.ORDER_NUM ?? 'Error'}</h3>
      </div>

      <hr className='my-50' />

      <section>
        <Table>
          <thead>
            <tr>
              <th>SKU</th>
              
              <th>Descripción</th>
              
              <th>Bodega</th>
              
              <th>Qty</th>
            </tr>
          </thead>
          <tbody>
            {data?.lineas?.length
              ? data?.lineas?.map(({ PART_CODE, DESC_TEXT, WARE_CODE, UOM_QTY }, i) => (
                <tr key={`linea-neu-${PART_CODE}-${WARE_CODE}-${UOM_QTY}-i${i}`}>
                  <td>{PART_CODE}</td>

                  <td>{DESC_TEXT}</td>

                  <td className='text-center'>{WARE_CODE}</td>

                  <td className='text-center'>{UOM_QTY}</td>
                </tr>
              ))

              : <tr><td colSpan={4}><i>Sin líneas para mostrar</i></td></tr>
            }
          </tbody>
        </Table>
      </section>
    </Modal>
  )
}
