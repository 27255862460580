import axios from "axios"
import { getAll } from "../../../endpoints/getAll"
import { successResponse } from "../../../helpers/successResponse"


const URL = getAll.usuarios_sucursales


export const createUsuarioSucursal = async (data) => {
  try {
    console.log(data);
    
    const response = await axios(URL, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST',
      data
    })

    if (!successResponse(response)) throw response

    return response
  } catch (err) {
    return Promise.reject(err)
  }
}