import {
   FILTER_CLEAR,
   FILTER_FECHA_FINAL,
   FILTER_FECHA_INICIO,
   RESULTADOS_LOGISTICA_REPORTES,
} from "../redux-types";

export const fechaInicialFilter = (data) => ({ type: FILTER_FECHA_INICIO, payload: data })
export const fechaFinalFilter = (data) => ({ type: FILTER_FECHA_FINAL, payload: data })

export const resultadosLogisticaReportes = (data) => ({ type: RESULTADOS_LOGISTICA_REPORTES, payload: data })

export const clearFilterLogisticaReportes = () => ({ type: FILTER_CLEAR });
