import { Navigate, Route, Routes } from "react-router-dom"
import { Listado as ReporteGeneral } from "../../views/Devoluciones/Reportes/General/Listado";
import { Listado as ReporteOperativo } from "../../views/Devoluciones/Reportes/Operativo/Listado";
import { Listado as ReporteParcial } from "../../views/Devoluciones/Reportes/Parcial/Listado";


export const ReportesRouter = () => {
   return (
      <Routes>
         <Route path='/general' element={<ReporteGeneral />} />

         <Route path='/operativo' element={<ReporteOperativo />} />

         <Route path='/parcial/*' element={<ReporteParcial />} />

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   );
}
