import { Navigate, Route, Routes } from "react-router-dom"
import { ResposicionStockTable } from "../../components/Tables/Logistica/ReposicionStockTable";
import { NoResposicionStockTable } from "../../components/Tables/Logistica/NoReposicionStockTable ";


export const LogisticaReportesRouter = () => {
  return (
    <Routes>
      <Route path='/reposicion-stock' element={<ResposicionStockTable />} />

      <Route path='/no-reposicion-stock' element={<NoResposicionStockTable />} />

      <Route path='*' element={<Navigate to='/home' replace />} />
    </Routes>
  );
}
