import { Navigate, Route, Routes } from "react-router-dom"
import { Listado } from "../../views/Autoservicio/Listado"

export const AutoservicioRouter = () => {

   return (
      <Routes>
         <Route path='/' element={<Listado />} />

         {/* <Route path='/detalle/:id' element={<Detalle />} /> */}

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   )
}
