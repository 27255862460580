import { idExtractor } from "../../../helpers/idExtractor"
import { isValidCreateForm, isValidUpdateForm } from "./validations"

export const createFormatter = (form) => {
  let formData = {
    es_despacho_express: Number(form?.es_despacho_express), // int
    metodo_entrega_id: form?.es_despacho_express ? '*SKIP*' : idExtractor(form?.metodo_entrega_id), // int
    hora_cierre: form?.hora_cierre, // str
    hora_despacho: form?.hora_despacho, // str
  }

  if (!isValidCreateForm(formData).isValid) {
    console.error({ msg: "Error en validaciones", validations: isValidCreateForm(formData) })
    return {}
  }

  formData = {
    ...formData,
    metodo_entrega_id: formData?.metodo_entrega_id === '*SKIP*' ? null : idExtractor(form?.metodo_entrega_id), // int
  }

  return formData
}

export const updateFormatter = (form) => {
  let formData = {
    es_despacho_express: Number(form?.es_despacho_express), // int
    metodo_entrega_id: form?.es_despacho_express ? '*SKIP*' : idExtractor(form?.metodo_entrega_id), // int
    hora_cierre: form?.hora_cierre, // str
    hora_despacho: form?.hora_despacho, // str
  }


  if (!isValidUpdateForm(formData).isValid) {
    console.error({ msg: "Error en validaciones", validations: isValidCreateForm(formData) })
    return {}
  }

  formData = {
    ...formData,
    metodo_entrega_id: formData?.metodo_entrega_id === '*SKIP*' ? null : idExtractor(form?.metodo_entrega_id), // int
  }

  return formData
}