import moment from 'moment/moment';


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { clearFilterHorariosDespacho, resultadosHorariosDespacho } from '../../redux/actions/horariosDespacho';


// Components
import CustomLoader from "../CustomLoader";
import Table from "../Table";
import Button from '../Button';


// Endpoints
import { getOne } from "../../endpoints/getOne";


// Layouts
import AccordionTable from "../../layout/AccordionTable";


// Helpers
import { filtrosFormatter } from '../AccordionFilter/Empresas';


// Service
import { deleteHorarioDespacho } from '../../services/horariosDespacho/delete.service';
import { getAllHorariosDespacho } from '../../services/horariosDespacho/getAll.service';
import { successResponse } from '../../helpers/successResponse';
import { fireToaster } from '../../redux/actions/toaster';
import { responseToasterContent } from '../../helpers/toasterContent';

const CONSTANT = {
	reduxSelector: 'horariosDespacho',
	pathUrl: '/horarios-despacho',
	queryUrlGetOne: getOne.servicios,
	title: 'Horarios de despacho',
	reduxClearFilters: clearFilterHorariosDespacho,
	reduxSetResults: resultadosHorariosDespacho,
}


export const HorariosDespachoTable = () => {
	const { resultados: response, filtros } = useSelector(state => state[CONSTANT.reduxSelector])
	const { showing_results } = useSelector(state => state.listedResults)
	const dispatch = useDispatch()


	// FUNCION MANEJADORA DE PAGINACIÓN
	const handleUrl = (url) => {
		dispatch(CONSTANT.reduxSetResults(null))

		getAllHorariosDespacho(showing_results, filtrosFormatter(filtros), url)
			.then(({ data }) => {
				dispatch(CONSTANT.reduxSetResults(data))
			})
			.catch(err => {
				dispatch(fireToaster(responseToasterContent(err)))
			})
	}

	const handleDelete = (id, url) => {
		deleteHorarioDespacho(id).finally(() => { handleUrl(url) })
	}


	return (
		<>
			<AccordionTable title={`${CONSTANT.title} (${response?.data?.length ?? 0} resultados)`}>
				{!response && <CustomLoader blocking={'partial'} />}

				<Table response={response} handlePagination={handleUrl}>
					<thead className="table-light position-sticky top-0 w-100 z-100 shadow">
						<tr role="row">
							<th>ID</th>
							<th>Método de entrega</th>
							<th>Horario</th>
							<th>Creación</th>
							<th className="w-max">Acciones</th>
						</tr>
					</thead>

					<tbody>
						{response?.data.length
							? response.data?.map((data) => {
								return (
									<tr className="odd" key={`user-${data.id}-${data.titulo}`}>
										<td className='col-1'>{data?.id}</td>

										<td><b style={{ color: data?.metodo_entrega?.nombre ? '#6e6b7b' : '#ff9f43' }}>{data?.metodo_entrega?.nombre ?? "Despacho Express"}</b></td>

										<td>
											<div className='d-flex flex-column'>
												<span>Hora cierre: <b>{moment(`01/01/0001 ${data?.hora_cierre}`).format('HH:mm')}</b></span>
												<span>Hora despacho: <b>{moment(`01/01/0001 ${data?.hora_despacho}`).format('HH:mm')}</b></span>
											</div>
										</td>

										<td className='col-2'>{moment(data?.created_at).format('DD-MM-YYYY') || 'Error'}</td>

										<td className='col-1'>
											<div className='d-flex flex-column gap-25'>
												<Button
													text={'Detalle'}
													icon={'ExternalLink'}
													className={'p-25'}
													isLink
													url={`${CONSTANT.pathUrl}/detalle/${data.id}`}
												/>

												<Button
													text={'Editar'}
													icon={'Edit'}
													className={'p-25'}
													color={'warning'}
													isLink
													url={`${CONSTANT.pathUrl}/update/${data.id}`}
												/>

												<Button
													text={'Borrar'}
													icon={'Trash'}
													className={'p-25'}
													color={'danger'}
													onClick={() => handleDelete(data?.id, response.path + '?page=' + response.current_page + `&limit=${showing_results}`)}
												/>
											</div>
										</td>
									</tr>
								)
							})
							: <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
						}
					</tbody>
				</Table>
			</AccordionTable >
		</>
	)
}