import { Route, Routes } from "react-router-dom"
import { ResposicionStockTable } from "../../components/Tables/Logistica/ReposicionStockTable";
import { LineasAgendamientosInvalidasTable } from "../../components/Tables/Logistica/LineasAgendamientosInvalidasTable";
import { LineasAgendamientosValidasTable } from "../../components/Tables/Logistica/LineasAgendamientosValidasTable";


export const LogisticaLineasAgendamientosRouter = () => {
  return (
    <Routes>
      <Route path='/validas' element={<LineasAgendamientosValidasTable />} />

      <Route path='/invalidas' element={<LineasAgendamientosInvalidasTable />} />

      <Route path='*' element={<ResposicionStockTable />} />
    </Routes>
  );
}
