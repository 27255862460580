import axios from "axios"
import { getAll } from "../../../endpoints/getAll"
import { successResponse } from "../../../helpers/successResponse"


const URL = getAll.sucursales

export const deleteSucursal = async (id) => {
  try {
    if (!id) throw Error(`Missing "id"`)

    const response = await axios(URL + `/${id}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'DELETE'
    })

    if (!successResponse(response)) throw response

    return response
  } catch (err) {
    return Promise.reject(err)
  }
}
