import React, { useEffect, useState } from 'react'


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { FILTER_METODO_ENTREGA } from '../../redux/redux-types'
import { fireToaster } from '../../redux/actions/toaster'
import { clearFilterHorariosDespacho, metodoEntregaFilter, resultadosHorariosDespacho } from '../../redux/actions/horariosDespacho'


// Components
import SelectFilter from '../SelectFilter'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Helpers
import { filtrosAplicadosTotal } from '../../helpers/filtrosAplicadosTotal'
import { idExtractor } from '../../helpers/idExtractor'
import { responseToasterContent } from '../../helpers/toasterContent'


// Service
import { getAllMetodosEntrega } from '../../services/metodosEntrega/getAll.service'
import { getAllHorariosDespacho } from '../../services/horariosDespacho/getAll.service'


const CONSTANT = {
  redirectUrl: '/horarios-despacho',
  reduxStateSelector: 'horariosDespacho',
  title: 'Búsqueda de horarios de despacho',
  reduxClearFilters: clearFilterHorariosDespacho,
  reduxSetResults: resultadosHorariosDespacho,
  despachoExpress: { id: 'express', nombre: 'Despacho Express' },

  serviceFetch: getAllHorariosDespacho,

  selectFilter: {
    metodosEntrega: []
  }
}


export const filtrosFormatter = (filtros) => {
  let validFormat = {}

  Object.entries(filtros).forEach(([k, v]) => {
    if (k === `metodo_entrega_id` && v.length && !v.includes('NULL')) {
      validFormat = {
        ...validFormat,
        [k]: idExtractor(v, true)
      }
    }
  })

  return validFormat
}


export const handleSelectsData = async () => {
  const metodosEntrega = await getAllMetodosEntrega()
    .then(({ data }) => [CONSTANT.despachoExpress, ...data?.data])
    .catch(err => {
      console.error(err);
      return []
    })

  return { metodosEntrega }
}


export const HorariosDespachoFiltros = () => {
  const { filtros } = useSelector(state => state[CONSTANT.reduxStateSelector])
  const { showing_results } = useSelector(state => state.listedResults)
  const { metodo_entrega_id } = filtros
  const dispatch = useDispatch()
  const [selectFilter, setSelectFilter] = useState(CONSTANT.sele)
  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE RENDERIZA POR PRIMERA VEZ LA LISTA
  useEffect(() => {
    setIsConsulting(true)

    CONSTANT.serviceFetch(showing_results)
      .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))

    handleSelectsData().then(res => setSelectFilter(res))

    setIsConsulting(false)

    return () => dispatch(CONSTANT.reduxClearFilters())
  }, [showing_results, dispatch])


  // FUNCION QUE CONSULTA EL BACKEND CON LOS FILTROS SELECCIONADOS
  const handleFetchFilter = async (reset = false) => {
    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())
      // CONSTANT.serviceFetch(showing_results).then(res => dispatch(CONSTANT.reduxSetResults(res)))
      return
    }

    setIsConsulting(true)

    await CONSTANT.serviceFetch(showing_results, filtrosFormatter(filtros))
      .then(({ data }) => {
        dispatch(CONSTANT.reduxSetResults(data))
      })
      .catch(err => {
        console.error(err);
        dispatch(fireToaster(responseToasterContent(err)))
        dispatch(CONSTANT.reduxSetResults([]))
        return
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }

  // MANEJADORES DE FILTRO
  const handleAddFilter = (e, filter) => {
    const { value } = e.target

    switch (filter) {
      case FILTER_METODO_ENTREGA:
        dispatch(metodoEntregaFilter(value))
        break;

      default:
        break;
    }
  }


  return (
    <div className="card mb-50 shadow-none bg-transparent">
      <ListadoHeader
        classes='border rounded-2'
        title={`${CONSTANT.title} (${filtrosAplicadosTotal(filtros)})`}
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetchFilter()}
        handleClickClearFilter={() => handleFetchFilter(true)}
      >
        <>
          <SelectFilter
            altSeparator
            labelText='Métodos de entrega'
            size='col-12'
            sizeDesk='col-md-4'
            handleValue={(e) => handleAddFilter(e, FILTER_METODO_ENTREGA)}
            optionObj={selectFilter?.metodosEntrega.map(({ id, nombre }) => ({ id, name: nombre })) ?? []}
            value={metodo_entrega_id?.split('%-%')?.[1] ?? 'Seleccione'}
          />
        </>
      </ListadoHeader>
    </div>
  )
}