import { Route, Routes } from "react-router-dom"
import { ResposicionStockTable } from "../../components/Tables/Logistica/ReposicionStockTable";
import { LineasDespachoValidasTable } from "../../components/Tables/Logistica/LineasDespachoValidasTable";
import { LineasDespachoInvalidasTable } from "../../components/Tables/Logistica/LineasDespachoInvalidasTable";


export const LogisticaLineasDespachoRouter = () => {
  return (
    <Routes>
      <Route path='/validas' element={<LineasDespachoValidasTable />} />

      <Route path='/invalidas' element={<LineasDespachoInvalidasTable />} />

      <Route path='*' element={<ResposicionStockTable />} />
    </Routes>
  );
}
