import {
   FILTER_ADD_BODEGA,
   FILTER_ADD_METODO_ENTREGA,
   FILTER_CLEAR,
   FILTER_CODIGO_VENDEDOR,
   FILTER_FECHA_FINAL,
   FILTER_FECHA_INICIO,
   FILTER_REMOVE_METODO_ENTREGA,
   RESULTADOS_LOGISTICA_LINEAS_AGENDAMIENTOS,
} from "../redux-types";

export const planificacionDesdeFilter = (data) => ({ type: FILTER_FECHA_INICIO, payload: data })
export const planificacionHastaFilter = (data) => ({ type: FILTER_FECHA_FINAL, payload: data })

export const metodosEntregaFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_METODO_ENTREGA, payload: data }
      : { type: FILTER_ADD_METODO_ENTREGA, payload: data };

export const vendedorFilter = (data) => ({ type: FILTER_CODIGO_VENDEDOR, payload: data })
export const bodegaFilter = (data) => ({ type: FILTER_ADD_BODEGA, payload: data })

export const resultadosLogisticaLineasAgendamientos = (data) => ({ type: RESULTADOS_LOGISTICA_LINEAS_AGENDAMIENTOS, payload: data })

export const clearFilterLogisticaLineasAgendamientos = () => ({ type: FILTER_CLEAR });
