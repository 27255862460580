import { useEffect, useState } from "react";

// Redux
import { fireToaster } from "../../redux/actions/toaster";
import { useDispatch } from "react-redux";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import CustomLoader from "../../components/CustomLoader";
import Switch from "../../components/Switch";
import CheckBoxInput from "../../components/CheckboxInput";


// React-router
import { useNavigate, useParams } from "react-router-dom";


// Helpers
import { createFormatter, updateFormatter } from "./helpers/formFormatter";
import { responseToasterContent } from "../../helpers/toasterContent";
import { successResponse } from "../../helpers/successResponse";


// Services
import { getDetailedBodega } from "../../services/bodegas/getDetail.service";
import { updateBodega } from "../../services/bodegas/update.service";
import { createBodega } from "../../services/bodegas/create.service";


const CONSTANT = {
  redirectUrl: '/bodegas',
  componentTitle: id => `${id ? 'Editar' : 'Crear'} Bodega`,
  title: 'Bodegas',

  form: {
    nombre: '', //str
    codigo: '', //int
    estatus: 1, //int
    es_stock_erp: 1 //int
  },

  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
}


const Form = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const [form, setForm] = useState(CONSTANT.form)
  const [isConsulting, setIsConsulting] = useState(false)

  useEffect(() => {
    if (!id) return

    getDetailedBodega(id).then(({ data }) => {

      setForm((prev) => ({
        ...prev,
        ...data
      }))
    })

  }, [id])


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  // FUNCIÓN MANEJADORA QUE CREA/MODIFICA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()
    setIsConsulting(true)
    
    if (id) {
      updateBodega(id, updateFormatter({ ...form }))
        .then(res => {
          dispatch(fireToaster(responseToasterContent(res)))
          if (successResponse(res)) navigate(CONSTANT.redirectUrl)
        })
        .finally(() =>
          setIsConsulting(false)
        )

      return
    }

    createBodega(createFormatter({ ...form }))
      .then(res => {
        dispatch(fireToaster(responseToasterContent(res)))
        if (successResponse(res)) navigate(CONSTANT.redirectUrl)
      })
      .finally(() =>
        setIsConsulting(false)
      )

    return
  }


  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='row'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} className='col-6' />

          {id &&
            <Button text={`Volver a la bodega #${id}`} icon='File' style='flat' isLink url={`/bodegas/detalle/${id}`} className='col-6' />
          }
        </div>

        {id && !form?.id &&
          <CustomLoader blocking={'partial'} />
        }

        {true
          ? <CustomForm size="" title={CONSTANT.componentTitle(id)}>

            <div className="row">
              <Switch
                name='estatus'
                labelText='Estatus'
                value={form.estatus}
                handleValue={handleInputChange}
                classes='col-6 my-auto'
              />

              <CheckBoxInput
                name='es_stock_erp'
                value={form.es_stock_erp}
                labelText='Es Stock ERP'
                handleValue={handleInputChange}
                classes='col-6 my-auto'
              />

              <hr className="mt-1 separator" />

              <FormInput
                labelText='* Nombre'
                placeholder='Nombre'
                name='nombre'
                value={form.nombre}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <FormInput
                labelText='* Código'
                placeholder='Escriba un código'
                name='codigo'
                type='number'
                value={form.codigo}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />
            </div>

            <Button
              type="submit"
              text={id ? 'Actualizar' : "Crear"}
              color="primary"
              disabled={isConsulting}
              icon={id ? 'Check' : "Plus"}
              onClick={(e) => handleFetch(e)}
            />
          </CustomForm>
          : <CustomLoader />
        }
      </div >
    </div >
  );
};

export default Form;
