
// Components
import { useState } from "react";
import ReporteVentasTable from "../../components/Tables/ReporteVentasTable";
import mock from '../../mocks/reporte_ventas.json'
import { PrecioFormatter } from "../../helpers/precioFormatter";
import ReporteVentasFiltros from "../../components/AccordionFilter/ReporteVentas";
import { useSelector } from "react-redux";


const PrecioRaw = (v) => new PrecioFormatter(v).producto(true)


const CONSTANT = {
	title: 'Reporte de ventas/día',
	path: 'reporte-ventas',
}



const Listado = () => {
	const { resultados } = useSelector(state => state.reporteVentas)

	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b> - Listado</p>
			</h3>

			<ReporteVentasFiltros />

			<div className="col">
				<div className="card">
					<h3 className="text-center pt-1 mb-0">Totales</h3>
					<div className="d-flex justify-content-around p-2 fs-4">
						<span className="tag px-50 py-25 success">Venta Total: <b>{PrecioRaw(resultados?.totales?.venta_total)}</b></span>

						<span className="tag px-50 py-25 yellow">Precio Promedio: <b>{PrecioRaw(resultados?.totales?.monto_promedio_producto)}</b></span>

						<span className="tag px-50 py-25">Cantidad Total: <b>{resultados?.totales?.cantidad_total}</b></span>
					</div>
				</div>
			</div>

			<ReporteVentasTable />
		</div>
	);
}

export default Listado;