import {
	FILTER_AUTOSERVICIO_SUCURSALES_INPUT,
	FILTER_CLEAR,
	RESULTADOS_AUTOSERVICIO_SUCURSALES,
} from "../redux-types";


const initialState = {
	filtros: {
		busqueda: '',
	},
	resultados: null
}


export const autoservicioUsuariosSucursalesReducer = (state = initialState, action) => {

	switch (action.type) {
		case FILTER_AUTOSERVICIO_SUCURSALES_INPUT:
			const { key, value } = action.payload

			return {
				...state, filtros: {
					...state.filtros,
					[key]: value
				}
			};

		case RESULTADOS_AUTOSERVICIO_SUCURSALES:
			return {
				...state, resultados: action.payload
			};

		case FILTER_CLEAR:
			return initialState;

		default:
			return state;
	}
};