import React, { useEffect, useState } from 'react'

// Redux
import { useSelector } from 'react-redux'
import {
  clearFilterLogisticaLineasDespacho,
  resultadosLogisticaLineasDespacho
} from '../../../redux/actions/logisticaLineasDespacho';


// Components
import Table from "../../Table";
import Icon from '../../Icon';
import Button from '../../Button';
import { ModalLineaDespachoDetalle } from '../../Modal/Logisitica/ModalLineaDespachoDetalle';
import SelectFilter from '../../SelectFilter';


// Layouts
import AccordionTable from "../../../layout/AccordionTable";


// Services


// Helpers/Hooks
import { useCopyClipboard } from '../../../hooks/useCopyClipboard';
import { idExtractor } from '../../../helpers/idExtractor';
import { clearFilterLogisticaLineasAgendamientos, resultadosLogisticaLineasAgendamientos } from '../../../redux/actions/logisticaLineasAgendamientos';


const CONSTANT = {
  permissionsPath: 'notas-venta',
  pathUrl: '/logistica/lineas-agendamientos/validas',
  title: 'Líneas Agendamientos Válidas',

  reduxSelector: 'logisticaLineasAgendamientos',
  reduxClearFilters: clearFilterLogisticaLineasAgendamientos,
  reduxSetResults: resultadosLogisticaLineasAgendamientos,

  bodegaFilters: [
    { id: '*NULL*', nombre: 'Sin filtros' }
  ]
}


const calculateAmountLineasNeumaticos = (lineas) => {
  if (!lineas || !lineas.length) return 0

  return lineas.reduce((prev, current) => {
    return prev += Number(current?.SCHED_QTY) ?? 0
  }, 0)
}



export const LineasAgendamientosValidasTable = () => {
  const { resultados: response } = useSelector(state => state[CONSTANT.reduxSelector])
  const [copy] = useCopyClipboard()
  const [modalLineaDetalle, setModalLineaDetalle] = useState({ display: false, data: null })
  const [bodegaFilter, setBodegaFilter] = useState({ current: '*NULL*%-%Sin Filtros', filters: CONSTANT.bodegaFilters })
  const [lineasAgrupadasFiltered, setLineasAgrupadasFiltered] = useState([])

  // EFECTO QUE SETEA DE MANERA DINAMICA LOS FILTROS DE LAS BODEGAS PRESENTES EN LA TABLA DE LINEAS AGRUPADAS
  useEffect(() => {
    if (!response || !response?.agendamientos_validos?.agrupadas?.length) return

    // Separacion de las bodegas presentes en la respuesta
    const values = response?.agendamientos_validos?.agrupadas.reduce((prev, current) => {
      const bodegas = current?.bodegas.map(({ bodega }) => bodega)

      return prev = [...prev, ...bodegas]
    }, [])

    // Limpieza de duplicados del array "values"
    const cleanedValues = [...new Set(values)]

    // Agregado de las bodegas filtradas y los filtros por defecto a los filtros de la tabla
    setBodegaFilter(prev => ({ ...prev, filters: [...prev?.filters, ...cleanedValues.map(v => ({ id: String(v), nombre: String(v) }))] }))

    return () => {
      setBodegaFilter({ current: '*NULL*%-%Sin Filtro', filters: CONSTANT.bodegaFilters })
    }
  }, [response])


  // EFECTO QUE FILTRA LOS RESULTADOS DE LA TABLA LINEAS AGRUPADAS
  useEffect(() => {
    if (!response || !response?.agendamientos_validos?.agrupadas?.length) return

    if (bodegaFilter.current.includes('*NULL*')) {
      setLineasAgrupadasFiltered(response?.agendamientos_validos?.agrupadas)
      return
    }

    const filteredValues = response?.agendamientos_validos?.agrupadas.map(({ bodegas, ...rest }) => {
      const hasValues = bodegas.filter(({ bodega }) => bodega === Number(idExtractor(bodegaFilter.current, true)))

      return { ...rest, bodegas: hasValues } ?? null
    })
      .filter(({ bodegas }) => bodegas?.length)

    setLineasAgrupadasFiltered(filteredValues)

    return () => {
      setLineasAgrupadasFiltered([])
    }
  }, [response, bodegaFilter])


  const handleModalLineaDetalle = (data) => {
    setModalLineaDetalle(prev => ({ display: !prev.display, data }))
  }


  const handleLineasAgrupadasFilter = (e) => {
    const { value } = e.target

    setBodegaFilter(prev => ({
      ...prev,
      current: value
    }))
  }


  return (
    <>
      {response?.agendamientos_validos
        ?
        <>
          {/* MARK: LINEAS DESPACHO */}
          <AccordionTable isOpen={false} title={`${CONSTANT.title} (${response?.agendamientos_validos?.listado?.length ?? 0} resultados)`}>
            <Table response={response}>
              <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
                <tr role="row">
                  <th>NV</th>

                  <th>Tipo despacho</th>

                  <th>Líneas neúmaticos</th>
                </tr>
              </thead>
              <tbody>
                {response?.agendamientos_validos?.listado?.length
                  ? response.agendamientos_validos?.listado?.map(({ nv, lineas }, i) => {
                    return (
                      <tr className="odd" key={`user-${nv.ORDER_NUM}-${i}`}>
                        <td className='col-1'>{nv.ORDER_NUM}</td>

                        <td>{nv?.TIPO_DESPACHO ?? "N/A"}</td>

                        <td>
                          <Button
                            text={`Neumáticos ${calculateAmountLineasNeumaticos(lineas) ?? "Error"}`}
                            icon={'ExternalLink'}
                            onClick={() => handleModalLineaDetalle({ nv, lineas })}
                          />
                        </td>
                      </tr>
                    )
                  })
                  : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={9}><i>Sin datos para mostrar...</i></td></tr>
                }
              </tbody>
            </Table>
          </AccordionTable>

          {/* MARK: LINEAS AGRUPADAS */}
          <AccordionTable title={`Líneas Agrupadas`} classes='mt-2'>
            <Table pb={false} style={{ minHeight: '200px' }}>
              <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
                <tr role="row">
                  <th className='col-2'>Tipo despacho</th>

                  <th>
                    <div className='d-flex gap-2'>
                      Bodegas / Notas de venta

                      <SelectFilter
                        altSeparator
                        sizeDesk={`col-md-2`}
                        labelText={'Filtrar por bodega:'}
                        optionObj={bodegaFilter.filters.map(({ id, nombre }) => ({ id, name: nombre }))}
                        handleValue={handleLineasAgrupadasFilter}
                        value={bodegaFilter.current.split('%-%')[1] ?? 'Seleccione'}
                      />
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {lineasAgrupadasFiltered?.length
                  ? lineasAgrupadasFiltered?.map(({ tipo_despacho, bodegas }, i) => {
                    return (
                      <tr key={`planificacion-rw-${i}`} className={`odd multi-data`}>
                        {/* TIPO DESTINO*/}
                        <td><b>{tipo_despacho ?? 'Sin asignar'}</b></td>

                        {/* BODEGAS / NOTAS VENTAS */}
                        <td>
                          <AccordionTable title='Resumen'>
                            <Table pb={false}>
                              <thead>
                                <tr>
                                  <th className='col-2' style={{ width: 0 }}>Bodega</th>
                                  <th style={{ width: 0, paddingLeft: 0, paddingRight: 0 }}></th>
                                  <th style={{ width: '100%' }}>Notas de venta</th>
                                </tr>
                              </thead>
                              <tbody>
                                {bodegas.map(({ bodega, notas_venta }) => (
                                  <tr key={`notas-ventas-${bodega}-${notas_venta}`} className={`odd multi-data`}>
                                    <td className='text-center fw-bold'>{bodega}</td>

                                    <td style={{ paddingLeft: 0, paddingRight: 0 }}>
                                      <div className='d-flex' onClick={() => copy(notas_venta)}>
                                        <Icon icon='Clipboard' size={20} className='cursor-pointer' />
                                      </div>
                                    </td>
                                    <td>{notas_venta}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </AccordionTable>
                        </td>
                      </tr>
                    )
                  })
                  : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
                }
              </tbody>
            </Table>
          </AccordionTable>

          {/* MARK: AGRUPADA POR BODEGAS */}
          <AccordionTable title={`Líneas Agrupadas por Bodega`} classes='mt-2'>
            <Table pb={false}>
              <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
                <tr role="row">
                  <th className='col-1'>Bodega</th>

                  <th>
                    <div className='d-flex gap-2'>
                      Notas de venta
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {response?.agendamientos_validos?.agrupadas_bodega?.length
                  ? response?.agendamientos_validos?.agrupadas_bodega?.map(({ notas_venta, bodega }, i) => {
                    return (
                      <tr key={`notas-ventas-${bodega}-${notas_venta}`} className={`odd multi-data`}>
                        <td className='text-center fw-bold'>{bodega}</td>

                        {/* <td>

                        </td> */}
                        <td style={{ paddingLeft: 0, paddingRight: 0 }}>
                          <div className='d-flex gap-1' onClick={() => copy(notas_venta)}>
                            <Icon icon='Clipboard' size={20} className='cursor-pointer' />
                            <b>{notas_venta}</b>
                          </div>

                        </td>
                      </tr>

                    )
                  })
                  : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
                }
              </tbody>
            </Table>
          </AccordionTable>

          {/* MARK: AGRUPADA POR TIPO DESPACHO */}
          <AccordionTable title={`Líneas Agrupadas por Tipos de Despacho`} classes='mt-2'>
            <Table pb={false}>
              <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
                <tr role="row">
                  <th className='col-2'>Tipo despacho</th>

                  <th>Notas de venta</th>
                </tr>
              </thead>

              <tbody>
                {response?.agendamientos_validos?.agrupadas_tipo_despacho?.length
                  ? response?.agendamientos_validos?.agrupadas_tipo_despacho?.map(({ notas_venta, tipo_despacho }, i) => {
                    return (
                      <tr key={`notas-ventas-${tipo_despacho}-${notas_venta}`} className={`odd multi-data`}>
                        <td className='text-center fw-bold'>{tipo_despacho}</td>

                        {/* <td>

                        </td> */}
                        <td style={{ paddingLeft: 0, paddingRight: 0 }}>
                          <div className='d-flex gap-1' onClick={() => copy(notas_venta)}>
                            <Icon icon='Clipboard' size={20} className='cursor-pointer' />
                            <b>{notas_venta}</b>
                          </div>

                        </td>
                      </tr>

                    )
                  })
                  : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
                }
              </tbody>
            </Table>
          </AccordionTable>
        </>

        : <div className='col-12'><h2 className='card p-2 text-center mb-0 fw-bolder'><i>Por favor realice una búsqueda</i></h2></div>}

      <ModalLineaDespachoDetalle
        display={modalLineaDetalle?.display}
        data={modalLineaDetalle?.data}
        handleDisplay={handleModalLineaDetalle}
      />

    </>
  )
}
