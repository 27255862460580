import axios from "axios"
import { getAll } from "../../endpoints/getAll"
import { successResponse } from "../../helpers/successResponse"

const URL = getAll.horarios_despacho
const THEME = 'Horarios-Despacho (update)'

export const updateHorarioDespacho = async (id, data) => {
  try {
    if (!data) return console.error(`${THEME}: Missing Data`);
    if (!id) throw Error(`${THEME}: Missing ID`)

    const response = await axios(URL + `/${id}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'PUT',
      data
    })

    if (!successResponse(response)) throw response

    return response
  } catch (err) {
    return Promise.reject(err)
  }
}