import axios from "axios";
import { useEffect, useState } from "react";


// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  metodoPagoFilter,
  comunaFilter,
  puntoEntregaFilter,
  cotizacionIdFilter,
  clearFilterCotizaciones,
  resultadosCotizaciones,
  clienteFilter,
  desdeFilter,
  hastaFilter,
  ejecutivoFilter,
  frontendFilter,
  filtrosPersistentesCotizaciones,
  resultadosResumenCotizaciones,
} from "../../redux/actions/cotizaciones";
import {
  FILTER_ADD_COMUNA,
  FILTER_ADD_EJECUTIVO,
  FILTER_ADD_FRONTEND,
  FILTER_ADD_METODO_PAGO,
  FILTER_ADD_PUNTO_ENTREGA,
  FILTER_COTIZACION_ID,
  FILTER_CREACION_DESDE,
  FILTER_CREACION_HASTA,
  FILTER_NOMBRE_CLIENTE,
} from "../../redux/redux-types";
import { fireToaster } from "../../redux/actions/toaster";


// Layouts
import ListadoHeader from "../../layout/ListadoHeader";


// Components
import FormInput from "../FormInput";
import SelectFilter from "../SelectFilter";
import FiltroAplicado from "./FiltroAplicado";


//Helpers
import { lastPosition } from "../../helpers/lastPosition";
import { queryParamsFormatter } from "../../helpers/queryParamsFormatter";
import { idExtractor } from "../../helpers/idExtractor";


// Endpoints
import { getAll } from "../../endpoints/getAll";
import { useWindowDimensions } from "../../hooks/useDimensions";
import { persistentFilters } from "../../helpers/pesistentFilters";
import { getResumenCotizaciones } from "../../services/cotizaciones/getResumen.service";
import DownloadFile from "../Download/DownloadFile";


const CONSTANT = {
  redirectUrl: '/cotizaciones',
  title: 'Búsqueda de cotizaciones',
  queryUrlGetAll: getAll.cotizaciones + '/listar',
  reduxClearFilters: clearFilterCotizaciones,
  reduxSetResults: (data) => resultadosCotizaciones(data),
  fetchEjecutivos: getAll.usuarios + '/listar?ejecutivo=1',
  fetchMetodosPago: getAll.metodos_pago,
  fetchPuntosEntrega: getAll.puntos_entrega + '/listar',
  fetchComunas: getAll.comunas + '/buscar',
  fetchFrontendId: getAll.frontend_id,
  queryExportar: getAll.cotizaciones + '/exportar',

  selectFilterInitialState: {
    metodosPago: [],
    ejecutivos: [],
    puntosEntrega: [],
    comunas: [],
    frontendId: []
  },

  queryUrl: (results) => CONSTANT.queryUrlGetAll + `?limit=${results}`
}


const dataFormatter = (filtrosObj) => {
  let data = {}

  Object.entries(filtrosObj).forEach(([key, value]) => {
    if (value.length) {
      if (['metodo_pago_id', 'ejecutivo_id', 'puntoentrega_id', 'frontend_id'].includes(key)) {
        data = {
          ...data,
          filtros: {
            ...data.filtros,
            [key]: value.map(el => idExtractor(el))
          }
        }
      }

      if (['comuna_id'].includes(key)) {
        data = {
          ...data,
          filtros_externos: {
            ...data.filtros_externos,
            [key]: idExtractor(value)
          }
        }
      }
    }
  })

  return data
}


const handleSelectsData = async () => {
  const frontendId = await axios(CONSTANT.fetchFrontendId,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => data)
    .catch(err => {
      console.error(err)
      return []
    })

  const puntosEntrega = await axios(CONSTANT.fetchPuntosEntrega,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST'
    })
    .then(({ data }) => data)
    .catch(err => {
      console.error(err)
      return []
    })

  const metodosPago = await axios(CONSTANT.fetchMetodosPago,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => data)
    .catch(err => {
      console.error(err)
      return []
    })

  const ejecutivos = await axios(CONSTANT.fetchEjecutivos,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST'
    })
    .then(({ data }) => data)
    .catch(err => console.error(err))

  const comunas = await axios(CONSTANT.fetchComunas,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST'
    })
    .then(({ data }) => data?.comunas)
    .catch(err => console.error(err))

  return { puntosEntrega, metodosPago, ejecutivos, comunas, frontendId }
}


const CotizacionesFiltros = () => {
  const { filtros } = useSelector(state => state.cotizaciones);
  const { showing_results } = useSelector(state => state.listedResults)
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
  const dispatch = useDispatch();
  const {
    cliente,
    cotizacion_id,
    desde,
    hasta,
    metodo_pago_id,
    ejecutivo_id,
    puntoentrega_id,
    comuna_id,
    frontend_id
  } = filtros;
  const { width } = useWindowDimensions()

  const [isConsulting, setIsConsulting] = useState(false)


  useEffect(() => {
    const isSameURL = window.location.pathname.includes(CONSTANT.redirectUrl)
    const filtrosPersistentesObj = persistentFilters(window.location, filtros).obj
    let url = CONSTANT.queryUrl(showing_results)
    let config = {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST',
    }

    if (filtrosPersistentesObj && isSameURL) {
      dispatch(filtrosPersistentesCotizaciones(filtrosPersistentesObj))
      url = CONSTANT.queryUrl(showing_results) + queryParamsFormatter(
        {
          cliente: filtrosPersistentesObj?.cliente ?? '',
          cotizacion_id: filtrosPersistentesObj?.cotizacion_id ?? '',
          desde: filtrosPersistentesObj?.desde ?? '',
          hasta: filtrosPersistentesObj?.hasta ?? '',
        }
      )
      config = { ...config, data: dataFormatter(filtrosPersistentesObj) }
    }

    axios(url, config)
      .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
      .catch(err => console.error(err))
  }, [showing_results, dispatch])


  useEffect(() => {
    handleSelectsData().then(data => setSelectFilter(data))
  }, [])


  // MANEJADORES DE FILTRO
  const handleAddFilter = (e, filter) => {
    const { value } = e.target;

    switch (filter) {
      case FILTER_NOMBRE_CLIENTE:
        dispatch(clienteFilter(value));
        break;

      case FILTER_COTIZACION_ID:
        dispatch(cotizacionIdFilter(value));
        break;

      case FILTER_CREACION_DESDE:
        dispatch(desdeFilter(value));
        break;

      case FILTER_CREACION_HASTA:
        dispatch(hastaFilter(value));
        break;

      case FILTER_ADD_METODO_PAGO:
        dispatch(metodoPagoFilter(value));
        break;

      case FILTER_ADD_PUNTO_ENTREGA:
        dispatch(puntoEntregaFilter(value));
        break;

      case FILTER_ADD_COMUNA:
        dispatch(comunaFilter(value));
        break;

      case FILTER_ADD_EJECUTIVO:
        dispatch(ejecutivoFilter(value));
        break;

      case FILTER_ADD_FRONTEND:
        dispatch(frontendFilter(value));
        break;

      default:
        break;
    }
  };


  const handleFetchFilter = (reset = false) => {
    setIsConsulting(true)

    let config = {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST'
    }

    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())
      return axios(CONSTANT.queryUrlGetAll + `/listar?limit=${showing_results}`, config)
        .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
        .catch(err => {
          console.error(err)
          dispatch(CONSTANT.reduxSetResults([]))
        })
        .finally(() => {
          setIsConsulting(false)
        })
    }

    config = {
      ...config,
      data: { ...dataFormatter(filtros) }
    }

    getResumenCotizaciones(filtros).then((data) => {
      dispatch(resultadosResumenCotizaciones(data))
    })

    axios(CONSTANT.queryUrlGetAll + `?limit=${showing_results}` + queryParamsFormatter({ cliente, cotizacion_id, desde, hasta }), config)
      .then(({ data }) => {
        dispatch(CONSTANT.reduxSetResults(data))
        dispatch(fireToaster({ title: 'Búsqueda realizada', icon: 'success', text: 'Filtros aplicados con éxito' }))
        window.history.pushState({}, '', persistentFilters(window.location, filtros).urlWithFilters)
      })
      .catch(err => {
        console.error(err)

        const { errores } = err.response.data
        let detalles = []

        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }


  const handleExportarErrors = ({ desde, hasta }) => {
    if (!desde) return dispatch(fireToaster({ title: 'Debe seleccionar una fecha desde', icon: 'warning' }))
    if (!hasta) return dispatch(fireToaster({ title: 'Debe seleccionar una fecha hasta', icon: 'warning' }))
  }


  return (
    <div className="card mb-50 shadow-none bg-transparent">
      <ListadoHeader
        classes="border rounded-2"
        title="Búsqueda de cotizaciones"
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetchFilter()}
        handleClickClearFilter={() => handleFetchFilter(true)}
        exportBtn={
          <DownloadFile
            url={CONSTANT.queryExportar + '?q=s' + queryParamsFormatter({ cliente, cotizacion_id, desde, hasta })}
            data={{ data: dataFormatter(filtros) }}
            handleErrors={() => handleExportarErrors({ desde, hasta })}
          />
        }
      >
        <div className="row">
          <FormInput
            labelText="Cliente"
            placeholder="Buscar..."
            size="col-12"
            sizeDesk={width > 1800 ? "col-md-2" : "col-md-6"}
            handleValue={(e) => handleAddFilter(e, FILTER_NOMBRE_CLIENTE)}
            value={cliente}
          />

          <FormInput
            type="number"
            labelText="Cotización"
            placeholder="Buscar..."
            size="col-12"
            sizeDesk={width > 1800 ? "col-md-2" : "col-md-6"}
            handleValue={(e) => handleAddFilter(e, FILTER_COTIZACION_ID)}
            value={cotizacion_id}
          />

          <FormInput
            type="date"
            labelText="Fecha desde"
            size="col-12"
            sizeDesk={width > 1800 ? "col-md-2" : "col-md-6"}
            handleValue={(e) => handleAddFilter(e, FILTER_CREACION_DESDE)}
            value={desde}
          />

          <FormInput
            type="date"
            labelText="Fecha hasta"
            size="col-12"
            sizeDesk={width > 1800 ? "col-md-2" : "col-md-6"}
            handleValue={(e) => handleAddFilter(e, FILTER_CREACION_HASTA)}
            value={hasta}
          />
        </div>

        <div className="row">
          <SelectFilter
            labelText="Método de pago"
            size="col-12"
            sizeDesk={width > 1800 ? "col-md-2" : "col-md-6"}
            optionObj={selectFilter?.metodosPago?.map(({ id, nombre }) => ({ id, name: nombre }))}
            handleValue={(e) => handleAddFilter(e, FILTER_ADD_METODO_PAGO)}
            value={lastPosition(metodo_pago_id)}
          />

          <SelectFilter
            labelText="Ejecutivo"
            size="col-12"
            sizeDesk={width > 1800 ? "col-md-2" : "col-md-6"}
            optionObj={selectFilter?.ejecutivos?.map(({ id, nombre }) => ({ id, name: nombre }))}
            handleValue={(e) => handleAddFilter(e, FILTER_ADD_EJECUTIVO)}
            value={lastPosition(ejecutivo_id)}
          />

          <SelectFilter
            labelText="Frontend"
            optionObj={selectFilter?.frontendId?.map(({ id, nombre }) => ({ id, name: nombre }))}
            size="col-12"
            sizeDesk={width > 1800 ? "col-md-2" : "col-md-6"}
            handleValue={(e) => handleAddFilter(e, FILTER_ADD_FRONTEND)}
            value={lastPosition(frontend_id)}
          />

          <SelectFilter
            labelText="Comuna entrega"
            size="col-12"
            sizeDesk={width > 1800 ? "col-md-2" : "col-md-6"}
            optionObj={selectFilter?.comunas?.map(({ id, nombre }) => ({ id, name: nombre }))}
            handleValue={(e) => handleAddFilter(e, FILTER_ADD_COMUNA)}
            value={lastPosition(comuna_id)}
          />

          <SelectFilter
            labelText="Punto Entrega"
            size="col-12"
            sizeDesk={width > 1800 ? "col-md-8" : "col-md-6"}
            optionObj={selectFilter?.puntosEntrega?.map(({ id, direccion, neu_tipo_destino, tipo_destino, comuna }) => ({ id, name: `${neu_tipo_destino?.nombre ?? tipo_destino?.nombre ?? 'Sin tipo destino'} | ${direccion ?? 'Sin dirección'} | ${comuna?.nombre ?? 'Sin comuna'}, ${comuna?.neu_region?.nombre ?? comuna?.region?.nombre ?? 'Sin región'}` }))}
            handleValue={(e) => handleAddFilter(e, FILTER_ADD_PUNTO_ENTREGA)}
            value={lastPosition(puntoentrega_id)}
          />
        </div>

        {/* FILTROS APLICADOS - PRODUCTOS */}
        <p className="mb-25 text-black">Filtros aplicados</p>
        <div className="row">
          <FiltroAplicado title="Frontend" array={frontend_id} func={frontendFilter} />
          <FiltroAplicado title="Método de pago" array={metodo_pago_id} func={metodoPagoFilter} />
          <FiltroAplicado title="Orden proveedor" array={ejecutivo_id} func={ejecutivoFilter} />
          <FiltroAplicado title="Punto de entrega" array={puntoentrega_id} func={puntoEntregaFilter} />
          <FiltroAplicado title="Comuna" array={comuna_id} func={comunaFilter} />
        </div>
        <hr className="my-1" />
      </ListadoHeader>
    </div>
  );
};

export default CotizacionesFiltros;
