
// Redux


// React-Router
import { LogisticaLineasAgendamientosRouter } from "../../../routers/Logistica/LineasAgendamientosRouter";


// Components
import Tabs from "../../../components/Tabs";
import { LogisticaLineasAgendamientosFiltros } from "../../../components/AccordionFilter/LogisticaLineasAgendamientos";


// Helpers


// Services


const CONSTANT = {
  title: 'Logistica - Líneas Agendamientos',
  path: 'logistica/lineas-agendamientos',
  reduxStateSelector: 'logisticaLineasAgendamientos',
}

const tabsList = [
  { name: 'Válidas', url: '/logistica/lineas-agendamiento/validas' },
  { name: 'Inválidas', url: '/logistica/lineas-agendamiento/invalidas' },
]


export const Listado = () => {

  return (
    <div className="row">
      <h3 className="mb-1 d-flex justify-content-between">
        <p><b>{CONSTANT.title}</b></p>

      </h3>

      <LogisticaLineasAgendamientosFiltros />

      <Tabs
        tabs={tabsList}
      />

      <LogisticaLineasAgendamientosRouter />
    </div>
  );
}
