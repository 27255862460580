import { useEffect, useState } from "react";


// Redux
import { fireToaster } from "../../redux/actions/toaster";
import { useDispatch } from "react-redux";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import SelectFilter from "../../components/SelectFilter";
import CustomLoader from "../../components/CustomLoader";
import CheckBoxInput from "../../components/CheckboxInput";


// React-router
import { useNavigate, useParams } from "react-router-dom";


// Helpers
import { successResponse } from "../../helpers/successResponse";
import { responseToasterContent } from "../../helpers/toasterContent";
import { createFormatter, updateFormatter } from "./helpers/formFormatter";


// Services
import { updateServicio } from "../../services/servicios/update.service";
import { getAllMetodosEntrega } from "../../services/metodosEntrega/getAll.service";
import { createHorarioDespacho } from "../../services/horariosDespacho/create.service";
import { updateHorarioDespacho } from "../../services/horariosDespacho/update.service";
import { getDetailedHorarioDespacho } from "../../services/horariosDespacho/getDetail.service";
import moment from "moment";


const CONSTANT = {
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  redirectUrl: '/horarios-despacho',
  componentTitle: id => `${id ? 'Editar' : 'Crear'} horario de despacho`,
  title: 'Horarios de despacho',

  selectFilter: {
    metodosEntrega: [],
  },

  form: {
    metodo_entrega_id: '', //int
    hora_cierre: '', //str (format: HH:mm)
    hora_despacho: '', //str (format: HH:mm)

    // optional
    es_despacho_express: false
  }
}


const handleSelectFiltersData = async () => {
  const metodosEntrega = await getAllMetodosEntrega()
    .then(({ data }) => {
      return [CONSTANT.unselectedObject, ...data?.data] ?? [CONSTANT.nullObject]
    })

  return { metodosEntrega }
}


const Form = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const [form, setForm] = useState(CONSTANT.form)
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilter)
  const [isConsulting, setIsConsulting] = useState(false)

  useEffect(() => {
    if (!id || !selectFilter?.metodosEntrega.length) return

    getDetailedHorarioDespacho(id)
      .then(({ data }) => {
        const defaultData = {
          id: CONSTANT.unselectedObject.id,
          nombre: CONSTANT.unselectedObject.nombre
        }
        const { id: metodoEntregaId, nombre: metodoEntregaNombre } = selectFilter?.metodosEntrega?.find(({ id }) => id === data?.metodo_entrega_id) ?? defaultData

        setForm((prev) => ({
          ...prev,
          metodo_entrega_id: `${metodoEntregaId ?? '*NULL*'}-${metodoEntregaNombre ?? 'Sin selección'}`,
          hora_cierre: moment(`01/01/0001 ${data?.hora_cierre}`).format('HH:mm'),
          hora_despacho: moment(`01/01/0001 ${data?.hora_despacho}`).format('HH:mm'),
          id: data?.id,
          es_despacho_express: data?.es_despacho_express
        }))
      })
      .catch(err => {
        console.error(err);
      })

  }, [id, selectFilter.metodosEntrega])



  // EFECTO QUE SETEA LA DATA PARA LOS SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(res => setSelectFilter(res))

    return () => {
      setForm(CONSTANT.form)
    }
  }, [])


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  // FUNCIÓN MANEJADORA QUE CREA/MODIFICA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault();
    setIsConsulting(true);

    (id)
      ? updateHorarioDespacho(id, updateFormatter({ ...form }))
        .then(res => {
          dispatch(fireToaster(responseToasterContent(res)))
          if (successResponse(res)) navigate(CONSTANT.redirectUrl)
        })
        .finally(() =>
          setIsConsulting(false)
        )

      : createHorarioDespacho(createFormatter({ ...form }))
        .then(res => {
          navigate(CONSTANT.redirectUrl)
          dispatch(fireToaster(responseToasterContent(res)))
        })
        .catch(err => {
          dispatch(fireToaster(responseToasterContent(err)))
        })
        .finally(() =>
          setIsConsulting(false)
        )
  }


  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {id && !form?.id &&
          <CustomLoader blocking={'partial'} />
        }

        {true
          ? <CustomForm size="" title={CONSTANT.componentTitle(id)}>

            <div className="row">
              <CheckBoxInput
                name='es_despacho_express'
                value={form.es_despacho_express}
                labelText='Es despacho express'
                handleValue={handleInputChange}
                classes='col-6 my-auto'
              />

              {form.es_despacho_express
                ? <FormInput
                  labelText='Método de entrega'
                  value={'Despacho express'}
                  isDisabled={true}
                  size='col-12'
                  sizeDesk='col-md-6'
                />

                : <SelectFilter
                  labelText='Método de entrega'
                  name='metodo_entrega_id'
                  value={form.metodo_entrega_id?.split('-')[1] ?? 'Seleccione'}
                  handleValue={handleInputChange}
                  optionObj={selectFilter?.metodosEntrega.map(({ id, nombre }) => ({ id, name: nombre })) ?? []}
                  size='col-12'
                  sizeDesk='col-md-6'
                />
              }

              <hr className="my-1" />

              <FormInput
                labelText='* Hora de cierre'
                name='hora_cierre'
                value={form.hora_cierre}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
                type={'time'}
              />

              <FormInput
                labelText='* Hora de despacho'
                name='hora_despacho'
                value={form.hora_despacho}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
                type={'time'}
              />
            </div>

            <Button
              type="submit"
              text={id ? 'Actualizar' : "Crear"}
              color="primary"
              disabled={isConsulting}
              icon={id ? 'Check' : "Plus"}
              onClick={(e) => handleFetch(e)}
            />
          </CustomForm>
          : <CustomLoader />
        }
      </div >
    </div >
  );
};

export default Form;
