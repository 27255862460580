import React, { useEffect, useState } from 'react'
import Modal from '../../Modal'
import FormInput from '../../FormInput';
import Button from '../../Button';
import { createHorarioDespacho } from '../../../services/horariosDespacho/create.service';
import { updateHorarioDespacho } from '../../../services/horariosDespacho/update.service';
import { useDispatch } from 'react-redux';
import { fireToaster } from '../../../redux/actions/toaster';
import { responseToasterContent } from '../../../helpers/toasterContent';
import moment from 'moment';

const CONSTANT = {
  form: {
    metodo_entrega_id: null,
    hora_cierre: '',
    hora_despacho: '',
    id: null
  }
}

export const ModalHorariosDespacho = ({ data, display, handleClose, fnFetchData }) => {
  const dispatch = useDispatch()
  const [form, setForm] = useState(CONSTANT.form)

  useEffect(() => {
    if (!data) return

    setForm(prev => ({
      metodo_entrega_id: data?.metodo_entrega_id ?? prev?.metodo_entrega_id,
      hora_cierre: moment(`01/01/0001 ${data?.hora_cierre}`).format('HH:mm') ?? prev?.hora_cierre,
      hora_despacho: moment(`01/01/0001 ${data?.hora_despacho}`).format('HH:mm') ?? prev?.hora_despacho,
      despacho_id: data?.despacho_id ?? prev?.id
    }))

    return () => {
      setForm(CONSTANT.form)
    }
  }, [data])


  const handleInputChange = (e) => {
    const { name: k, value: v } = e.target

    setForm(prev => ({ ...prev, [k]: v }))
  }


  const handleCloseModal = () => {
    setForm(CONSTANT.form)
    handleClose()
  }


  const handleFetchForm = async () => {
    (form?.despacho_id)
      ? await updateHorarioDespacho(form?.despacho_id, form)
        .then(res => {
          dispatch(fireToaster(responseToasterContent(res)))
          fnFetchData()
          handleCloseModal()
        })
        .catch((err) => {
          console.error(err);
        })

      : await createHorarioDespacho(form)
        .then(err => {
          dispatch(fireToaster(responseToasterContent(err)))
          fnFetchData()
          handleCloseModal()
        })
        .catch((err) => {
          console.error(err);
        })
  }

  return (
    <Modal display={display} handleValue={handleClose}>
      <h3>Horarios de despacho | <span>{form?.despacho_id ? `Editar #${form?.despacho_id}` : 'Crear'}</span></h3>

      <hr className='mt-0' />

      <section className='mt-3 row'>
        <FormInput
          name={'hora_cierre'}
          value={form?.hora_cierre}
          handleValue={handleInputChange}
          type={'time'}
          labelText={'Horario de cierre'}
          size={'col-12'}
          sizeDesk={'col-md-6'}
        />

        <FormInput
          name={'hora_despacho'}
          value={form?.hora_despacho}
          handleValue={handleInputChange}
          type={'time'}
          labelText={'Horario de despacho'}
          size={'col-12'}
          sizeDesk={'col-md-6'}
        />
      </section>

      <section className='d-flex justify-content-between mt-5'>
        <Button
          text={'Cancelar'}
          color={'danger'}
          icon={'X'}
          className={'w-fit p-50'}
          onClick={handleCloseModal}
        />

        <Button
          text={'Crear'}
          color={'success'}
          icon={'Check'}
          className={'w-fit p-50'}
          onClick={handleFetchForm}
        />
      </section>
    </Modal>
  )
}
