import { Navigate, Route, Routes } from "react-router-dom"
import { Listado } from "../views/Servicios/Listado"
import { Detalle } from "../views/Servicios/Detalle";
import Form from '../views/Servicios/Form';

import { useSelector } from "react-redux"
import CargaMasiva from "../views/Servicios/CargaMasiva";

export const ServiciosRouter = () => {
   const { user } = useSelector(state => state.auth)

   return (
      <Routes>
         <Route path='/' element={<Listado />} />

         {user.permisos['productos.crear'] &&
            <Route path='/create' element={<Form />} />
         }

         {user.permisos['productos.crear'] &&
            <Route path='/carga-masiva' element={<CargaMasiva />} />
         }

         {user.permisos['productos.detalle'] &&
            <Route path='/update/:id' element={<Form />} />
         }

         {user.permisos['productos.detalle'] &&
            <Route path='/detalle/:id' element={<Detalle />} />
         }

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   )
}
