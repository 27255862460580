import {
   FILTER_CLEAR,
   FILTER_METODO_ENTREGA,
   RESULTADOS_HORARIOS_DESPACHO,
} from "../redux-types";

export const metodoEntregaFilter = (data) => ({ type: FILTER_METODO_ENTREGA, payload: data })

export const resultadosHorariosDespacho = (data) => ({ type: RESULTADOS_HORARIOS_DESPACHO, payload: data })

export const clearFilterHorariosDespacho = () => ({ type: FILTER_CLEAR });
