
// Components
import PuntosEntregaFiltro from "../../components/AccordionFilter/PuntosEntrega";
import Button from "../../components/Button";
import PuntosEntregaTable from "../../components/Tables/PuntosEntregaTable";

const CONSTANT = {
	title: 'Puntos de entrega',
	path: 'puntos-entrega',
	create_btn: 'Crear punto de entrega'
}

const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b> - Listado</p>
			</h3>
			<div className="d-flex gap-50 my-50">
				<Button isLink text={CONSTANT.create_btn} url={`/${CONSTANT.path}/create`} icon={'Plus'} />
			</div>

			<PuntosEntregaFiltro />

			<PuntosEntregaTable />
		</div>
	);
}

export default Listado;