import axios from "axios"
import { getAll } from "../../../endpoints/getAll"
import { queryParamsFormatter } from "../../../helpers/queryParamsFormatter"

const URLReporteGeneral = getAll.devoluciones_reportes + '/general'
const URLReporteParcial = getAll.devoluciones_reportes + '/parcial'
const URLReporteOperativo = getAll.devoluciones_reportes + '/operativo'

export const getAllDevolucionesReporteGeneral = async (params = {}, paginationUrl = undefined) => {
  try {
    const response = await axios(`${paginationUrl ?? URLReporteGeneral}${paginationUrl ? '&' : '?'}q=s${queryParamsFormatter(params)}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })

    if (!response.status) throw response

    return response
  } catch (err) {
    return Promise.reject(err)
  }
}

export const getAllDevolucionesReporteParcial = async (params = {}, paginationUrl = undefined) => {
  try {
    const response = await axios(`${paginationUrl ?? URLReporteParcial}${paginationUrl ? '&' : '?'}q=s${queryParamsFormatter(params)}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })

    if (!response.status) throw response

    return response
  } catch (err) {
    return Promise.reject(err)
  }
}

export const getAllDevolucionesReporteOperativo = async (params = {}, paginationUrl = undefined) => {
  try {
    const response = await axios(`${paginationUrl ?? URLReporteOperativo}${paginationUrl ? '&' : '?'}q=s${queryParamsFormatter(params)}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })

    if (!response.status) throw response

    return response
  } catch (err) {
    return Promise.reject(err)
  }
}