import axios from 'axios'
import { useEffect, useState } from 'react'
import Card from '../components/Card'
import CardInfo from '../components/CardInfo'
import CustomLoader from '../components/CustomLoader'
import FormInput from '../components/FormInput'
import { getAll } from '../endpoints/getAll'
import { HomeChart } from '../components/Charts/HomeChart'
import SmallChart from '../components/Charts/SmallChart'
import { useSelector } from 'react-redux'
import capitalize from '../helpers/capitalize'
import moment from 'moment'
import { getContadorPorEstatus } from '../services/dashboard/getPedidosPorEstatus.service'
import { Link } from 'react-router-dom'
import { getAllEstatusPedido } from '../services/estatusPedido/getAll.service'
import Switch from '../components/Switch'
import { getContadorAutogestion } from '../services/dashboard/autogestiones/getAll.service'


const CONSTANT = {
  fetchHomePedidos: getAll.dashboard + '/pedidos',
  today: moment().format('YYYY-MM-DD'),

  formHomeInitialState: {
    pedidos: {
      fecha: '',
      estado_pago: 0
    }
  },

  homeDataInitialState: {
    pedidos: {},
    pedidosPagos: {}
  }
}


const filtrosPersistentesTemplate = {
  "busqueda_usuario": "",
  "pais_id": "",
  "desde": "",
  "hasta": "",
  "fecha": "",
  "usuario_id": [],
  "frontend_id": [],
  "puntoentrega_id": [],
  "metodo_entrega_id": [],
  "metodo_pago_id": [],
  "divisa_id": [],
  "tipo_facturacion_id": [],
  "estatus_pedido_id": [],
  "estado_pago": [],
  "id": ""
}


const handleExternalData = async () => {
  const estatusPedido = await getAllEstatusPedido()
    .then(({ data }) => data)

  return estatusPedido
}


const Home = () => {
  const [homeData, setHomeData] = useState(CONSTANT.homeDataInitialState)
  const [formHome, setFormHome] = useState(CONSTANT.formHomeInitialState)
  const [loader, setLoader] = useState(true)
  const { user } = useSelector(state => state.auth)
  const [urlFilter, setUrlFilter] = useState({})

  useEffect(() => {
    handleExternalData()
      .then(x => {
        let urls = {}

        x.forEach(({ id, nombre }) => {
          const key = nombre.toLowerCase()
          urls = {
            ...urls,
            [key]: JSON.stringify({
              ...filtrosPersistentesTemplate,
              estatus_pedido_id: [`${id}-${nombre}`],
              desde: formHome?.pedidos?.fecha ?? ''
            })
          }
        })

        setUrlFilter(urls);
      })
  }, [formHome?.pedidos?.fecha])



  useEffect(() => {
    setFormHome(formHome => ({
      ...formHome,
      pedidos: {
        ...formHome.pedidos,
        fecha: CONSTANT.today
      }
    }))

    return () => {
      setFormHome(CONSTANT.formHomeInitialState)
    }
  }, [])


  useEffect(() => {
    axios(CONSTANT.fetchHomePedidos + `?fecha=${formHome?.pedidos?.fecha}&estado_pago=${formHome?.pedidos?.estado_pago}`,
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => {
        setHomeData(homeData => ({
          ...homeData,
          pedidos: { ...data }
        }))
      })
      .catch(err => console.error(err))

    getContadorPorEstatus({ fecha: formHome?.pedidos?.fecha })
      .then(res => {
        setHomeData(prev => ({
          ...prev,
          pedidosPagos: res
        }))
      })

    getContadorAutogestion({ fecha: formHome?.pedidos?.fecha })
      .then(({ data }) => {
        setHomeData(prev => ({
          ...prev,
          contadorSucursales: data
        }))
      })
      .catch(err => {
        console.error(err);
        setHomeData(prev => ({
          ...prev,
          contadorSucursales: []
        }))
      })

    setLoader(false)
  }, [formHome?.pedidos?.estado_pago, formHome?.pedidos?.fecha])


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    if (name.split('%-%').length > 1) {
      const [target, key] = name.split('%-%')

      return setFormHome({
        ...formHome,
        [target]: {
          ...formHome[target],
          [key]: value
        }
      })
    }

    setFormHome({
      ...formHome,
      [name]: value
    })
  }


  // const handleFetch = async (target) => {
  //   let config, url
  //   const formattedDate = moment(formHome.pedidos.fecha).format('YYYY-MM-DD')
  //   if (target === 'pedidos-fecha') {
  //     config = {
  //       method: 'GET',
  //     }
  //     url = CONSTANT.fetchHomePedidos + `?fecha=${formattedDate}&estado_pago=${formHome?.pedidos?.estado_pago}`
  //   }
  //   setLoader(true)

  //   config = {
  //     ...config,
  //     headers: {
  //       ...config?.header,
  //       'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
  //     }
  //   }

  //   axios(url, config)
  //     .then(({ data }) => {
  //       if (target === 'pedidos-fecha') {
  //         setHomeData({
  //           ...homeData,
  //           pedidos: data
  //         })
  //       }
  //     })
  //     .catch(err => {
  //       console.error(err)
  //     })

  //   getContadorPorEstatus({ fecha: formattedDate }).then(res => {
  //     console.log(res);
  //   })
  //   setLoader(false)
  // }


  return (
    <>
      {loader
        ? <CustomLoader />

        : <div className='row match-height'>

          <div className='row match-height'>
            <Card
              title="Pedidos totales"
              headerChildren={
                <div className='d-flex flex-grow-1 justify-content-between'>
                  <Switch
                    value={formHome?.pedidos?.estado_pago}
                    handleValue={handleInputChange}
                    labelText='Estado pagado'
                    name='pedidos%-%estado_pago'
                    size='col-4'
                    classes='mt-25'
                  />

                  <div className='col'></div>

                  <FormInput
                    type='date'
                    name={'pedidos%-%fecha'}
                    value={formHome.pedidos.fecha}
                    handleValue={handleInputChange}
                    size='col-6'
                    sizeDesk='col-md-3'
                    margin='my-auto me-50'
                  />

                  {/* <div className='my-auto'>
                      <Button icon='Search' onClick={() => handleFetch('pedidos-fecha')} />
                    </div> */}
                </div>
              }
            >
              <CardInfo icon={'TrendingUp'} value={homeData?.pedidos?.ventas ?? 0} text={"Pedidos"} />
              <CardInfo icon={'User'} text={'Clientes'} value={homeData?.pedidos?.clientes ?? 0} />
              <CardInfo icon={'Package'} text={'SKU'} value={homeData?.pedidos?.productos ?? 0} />
              <CardInfo icon={'DollarSign'} text={'Ingresos Brutos'} value={homeData?.pedidos?.ingresos ?? 0} />
            </Card>

            <div className='col col-md-4'>
              <SmallChart title={`¡Bienvenido, ${user?.nombre ?? 'usuario'}!`} url={`/usuarios/detalle/${user.id}`}>

                <div className='d-flex justify-content-between'>
                  <span>RUT:</span>
                  <b className='text-end'>{capitalize(user?.rut) ?? 'No encontrado'}</b>
                </div>

                <div className='d-flex justify-content-between'>
                  <span>Email:</span>
                  <b className='text-end'>{capitalize(user?.email) ?? 'No encontrado'}</b>
                </div>

                <div className='d-flex justify-content-between'>
                  <span>Rol:</span>
                  <b className='text-end'>{capitalize(user?.roles[0]?.name) ?? 'No encontrado'}</b>
                </div>

                <div className='d-flex justify-content-between'>
                  <span>Tlf:</span>
                  <b className='text-end'>{capitalize(user?.fono) ?? 'No encontrado'}</b>
                </div>
              </SmallChart>
            </div>
          </div>

          <div className='row'>
            <Card
              title="Neumáticos por estatus de pedido"
              classes='col-12'
              headerChildren={<></>}
            >
              <CardInfo classes='col' icon={'ArrowDownCircle'} text={<Link to={`/pedidos?filtros_persistentes=${urlFilter?.['ingresado'] ?? '{}'}`}>Ingresado</Link>} value={homeData?.pedidosPagos?.ingresado ?? 0} />
              <CardInfo classes='col' icon={'Edit'} text={<Link to={`/pedidos?filtros_persistentes=${urlFilter?.['procesado'] ?? '{}'}`}>Procesado</Link>} value={homeData?.pedidosPagos?.procesado ?? 0} />
              <CardInfo classes='col' icon={'Calendar'} text={<Link to={`/pedidos?filtros_persistentes=${urlFilter?.['agendado'] ?? '{}'}`}>Agendado</Link>} value={homeData?.pedidosPagos?.agendado ?? 0} />
              <CardInfo classes='col' icon={'Truck'} text={<Link to={`/pedidos?filtros_persistentes=${urlFilter?.['transito'] ?? '{}'}`}>Transito</Link>} value={homeData?.pedidosPagos?.transito ?? 0} />
              <CardInfo classes='col' icon={'Flag'} text={<Link to={`/pedidos?filtros_persistentes=${urlFilter?.['destino'] ?? '{}'}`}>Destino</Link>} value={homeData?.pedidosPagos?.destino ?? 0} />
              <CardInfo classes='col' icon={'CheckCircle'} text={<Link to={`/pedidos?filtros_persistentes=${urlFilter?.['finalizado'] ?? '{}'}`}>Finalizado</Link>} value={homeData?.pedidosPagos?.finalizado ?? 0} />
              <CardInfo classes='col' icon={'List'} text={<Link to={`/pedidos?filtros_persistentes=${urlFilter?.['total'] ?? '{}'}`}>Total</Link>} value={homeData?.pedidosPagos?.total ?? 0} />
            </Card>
          </div>

          <div className='row'>
            <Card
              title="Notas de ventas atendidas por sucursal"
              classes='col-12'
              headerChildren={<></>}
            >
              {homeData?.contadorSucursales?.length
                ? homeData?.contadorSucursales.map(({ sucursal, cantidad }) => (
                  <div className='d-flex flex-column w-fit rounded border p-1'>
                    <span className='fs-5'>{sucursal}</span>
                    <b className='fs-3 text-center'>{cantidad ?? 0}</b>
                  </div>
                ))
                : <span>No hay datos para mostrar...</span>
              }
            </Card>
          </div>

          <HomeChart />
        </div>
      }
    </>
  )
}

export default Home