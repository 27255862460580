import axios from "axios"
import moment from "moment/moment"
import { getAll } from "../../../endpoints/getAll"
import { queryParamsFormatter } from "../../../helpers/queryParamsFormatter"
import { successResponse } from "../../../helpers/successResponse"


const today = moment().format('YYYY-MM-DD')
const URL = getAll.dashboard_autogestiones


export const getContadorAutogestion = async (params) => {
  try {
    if (!params?.fecha) params = { ...params, fecha: today }

    const response = await axios(URL + '?q=s' + queryParamsFormatter(params), {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })

    if (!successResponse(response)) throw response

    return response
  } catch (err) {
    return Promise.reject(err)
  }
}