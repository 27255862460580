import { Navigate, Route, Routes } from "react-router-dom"
import { Form } from "../../views/Autoservicio/Sucursales/Form";
import { Listado } from "../../views/Autoservicio/Sucursales/Listado";
// import { Detalle } from "../../views/Autoservicio/Sucursales/Detalle";


export const SucursalesRouter = () => {
   return (
      <Routes>
         <Route path='/' element={<Listado />} />

         <Route path='/create' element={<Form />} />

         <Route path='/update/:id' element={<Form />} />

         {/* <Route path='/detalle/:id' element={<Detalle />} /> */}

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   );
}
