import { Navigate, Route, Routes } from "react-router-dom"
import { Listado } from "../views/HorariosDespacho/Listado"
import { Detalle } from "../views/HorariosDespacho/Detalle";
import Form from '../views/HorariosDespacho/Form';


export const HorariosDespachoRouter = () => {
   return (
      <Routes>
         <Route path='/' element={<Listado />} />

         <Route path='/create' element={<Form />} />

         <Route path='/update/:id' element={<Form />} />

         <Route path='/detalle/:id' element={<Detalle />} />

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   )
}
