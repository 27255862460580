
// Components
import { DevolucionesBancosFiltros } from "../../../components/AccordionFilter/Devoluciones/DevolucionesBancos";
import DropdownMenuLink from "../../../components/Dropdown/Link";
import DropdownWrapper from "../../../components/Dropdown/Wrapper";
import { DevolucionesBancosTable } from "../../../components/Tables/Devoluciones/DevolucionesBancosTable";

const CONSTANT = {
	title: 'Devoluciones - bancos',
	path: 'devoluciones/bancos',
	create_btn: 'Nuevo banco'
}

export const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b> - Listado</p>
				<DropdownWrapper text="Acciones" classes="ms-auto">
					<DropdownMenuLink text={CONSTANT.create_btn} href={`/${CONSTANT.path}/create`} icon="Plus" />
				</DropdownWrapper>
			</h3>

			<DevolucionesBancosFiltros />

			<DevolucionesBancosTable />
		</div>
	);
}
