
// Components
import { DevolucionesFiltros } from "../../components/AccordionFilter/Devoluciones/Devoluciones";
import Button from "../../components/Button";
import { DevolucionesTable } from "../../components/Tables/Devoluciones/DevolucionesTable";

const CONSTANT = {
	title: 'Devoluciones',
	path: 'devoluciones',
	create_btn: 'Nueva devolución'
}

export const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b> - Listado</p>
				
				<Button isLink text={'Nueva devolución'} icon='Plus' url={`/${CONSTANT.path}/create`} />
			</h3>

			<DevolucionesFiltros />

			<DevolucionesTable />
		</div>
	);
}
