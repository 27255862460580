import axios from "axios"
import { getOne } from "../../endpoints/getOne"
import { successResponse } from "helpers/successResponse"


export const getDetailedDevolucion = async (id) => {
  try {
    if (!id) throw Error(`Missing "id" devolucion`)

    const response = await axios(getOne.devoluciones(id), {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }
    })

    if (!successResponse(response)) throw response

    return response
  } catch (err) {
    return Promise.reject(err)
  }
}
