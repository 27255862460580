import React, { useEffect, useState } from 'react'
import axios from 'axios'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { FILTER_ADD_ESTATUS, FILTER_BUSQUEDA, FILTER_CREACION_DESDE, FILTER_CREACION_HASTA, FILTER_ADD_EXTENSION_TELEFONICA, FILTER_ADD_NOMBRE, FILTER_PATRON_IDENTIFICACION, FILTER_ADD_COD_MAXIMISE, FILTER_ADD_LOCALE } from '../../redux/redux-types'
import { busquedaFilter, clearFilterPaises, codMaximiseFilter, creacionDesdeFilter, creacionHastaFilter, estatusFilter, extensionTelefonicaFilter, localeFilter, nombreFilter, patronIdentificacionFilter, resultadosPaises } from '../../redux/actions/paises'
import { fireToaster } from '../../redux/actions/toaster'


// Components
import FormInput from '../FormInput'
import SelectFilter from '../SelectFilter'
import Select from '../Select'
import FiltroAplicado from './FiltroAplicado'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Endpoints
import { getAll } from '../../endpoints/getAll'


// Helpers
import { lastPosition } from '../../helpers/lastPosition'
import { filtrosAplicadosTotal } from '../../helpers/filtrosAplicadosTotal'


const mockEstatus = [
  { name: 'Activo' },
  { name: 'Inactivo' },
]


const dataFormatter = (objFiltros) => {
  let data = {}

  Object.entries(objFiltros).forEach(([key, value]) => {
    if (key === 'busqueda') return

    if (key === 'patron_identificacion' && value.length) {
      data = {
        ...data,
        filtros: {
          ...data.filtros,
          [key]: [value]
        }
      }
      return
    }

    if (key === 'creacion_desde' && value.length > 0) {
      data = {
        ...data,
        filtros: {
          ...data.filtros,
          created_at_desde: value
        }
      }
      return
    }

    if (key === 'creacion_hasta' && value.length > 0) {
      data = {
        ...data,
        filtros: {
          ...data.filtros,
          created_at_hasta: value
        }
      }
      return
    }

    if (value.length > 0 && key === 'estatus') {
      data = {
        ...data,
        filtros: {
          ...data.filtros,
          [key]: value.map(value => value === 'Activo' ? 1 : 0)
        }
      }
      return
    }

    if (value.length > 0) {
      data = {
        ...data,
        filtros: {
          ...data.filtros,
          [key]: value
        }
      }
    }
  })

  return data
}


const PaisesFiltros = () => {
  const { filtros } = useSelector(state => state.paises)
  const { showing_results } = useSelector(state => state.listedResults)
  const [filtrosPosibles, setFiltrosPosibles] = useState(null)
  const [paisesList, setPaisesList] = useState(null)
  const dispatch = useDispatch()
  const { busqueda, estatus, nombre, creacion_desde, creacion_hasta, extension_telefonica, patron_identificacion, locale, cod_maxi } = filtros

  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE CONSULTA LOS DATOS DE LA TABLA LA PRIMERA VEZ
  useEffect(() => {
    axios(getAll.paises + `/buscar?limit=${showing_results}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }, method: 'POST'
    })
      .then(({ data }) => dispatch(resultadosPaises(data?.paises)))
      .catch(err => console.error(err))
  }, [dispatch, showing_results])


  // EFECTO QUE SETEA LOS VALORES DE LOS SELECT FILTERS
  useEffect(() => {
    axios(getAll.paises + '/buscar', {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }, method: 'POST'
    })
      .then(({ data }) => setPaisesList(data?.paises))
      .catch(err => console.error(err))

    axios(getAll.paises + '/filtros', {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
      .then(({ data }) => setFiltrosPosibles(data?.filtros_posibles?.[0]))
      .catch(err => console.error(err))

  }, [])


  // EFECTO QUE SETEA LA TABLA A SU VALOR INICIAL
  useEffect(() => {
    return () => dispatch(clearFilterPaises())
  }, [dispatch])


  const handleFetchFilter = (reset = false) => {
    let data = {
      busqueda,
    }

    setIsConsulting(true)

    if (reset) {
      dispatch(clearFilterPaises())
      return axios(getAll.paises + `/buscar?limit=${showing_results}`, {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST'
      })
        .then(({ data }) => dispatch(resultadosPaises(data?.paises)))
        .catch(err => console.error(err))
        .finally(() => {
          setIsConsulting(false)
        })
    }

    data = { ...data, ...dataFormatter(filtros) }

    axios(getAll.paises + `/buscar?limit=${showing_results}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      data
    }).then(res => {
      dispatch(resultadosPaises(res?.data.paises))
      dispatch(fireToaster({ title: 'Búsqueda realizada', icon: 'success', text: 'Filtros aplicados con éxito' }))
    })
      .catch(err => {
        console.error(err)

        const { errores } = err.response.data
        let detalles = []

        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
          Operación fallida
          (${err.response.status} - ${err.response.statusText})
        `,
          html: `
          <b>Detalle: </b>
          ${detalles.map(error => `<br /><i>-${error}</i>`)}
        `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }

  // MANEJADORES DE FILTRO - ADICIONALES
  const handleAddFilter = (e, filter) => {
    const { value } = e.target

    switch (filter) {
      case FILTER_BUSQUEDA:
        dispatch(busquedaFilter(value))
        break;

      case FILTER_ADD_ESTATUS:
        dispatch(estatusFilter(value))
        break;

      case FILTER_ADD_NOMBRE:
        dispatch(nombreFilter(value.split('-')[0]))
        break;

      case FILTER_CREACION_DESDE:
        dispatch(creacionDesdeFilter(value))
        break;

      case FILTER_CREACION_HASTA:
        dispatch(creacionHastaFilter(value))
        break;

      case FILTER_ADD_EXTENSION_TELEFONICA:
        dispatch(extensionTelefonicaFilter(value.split('-')[0]))
        break;

      case FILTER_PATRON_IDENTIFICACION:
        dispatch(patronIdentificacionFilter(value))
        break;

      case FILTER_ADD_LOCALE:
        dispatch(localeFilter(value.split('-')[0]))
        break;

      case FILTER_ADD_COD_MAXIMISE:
        dispatch(codMaximiseFilter(value.split('-')[0]))
        break;

      default:
        break;
    }
  }


  return (
    <div className="card mb-50 shadow-none bg-transparent">
      <ListadoHeader
        classes='border rounded-2'
        title={`Búsqueda de paises (${filtrosAplicadosTotal(filtros)})`}
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetchFilter()}
        handleClickClearFilter={() => handleFetchFilter(true)}
      >
        {filtrosPosibles &&
          <>
            <FormInput
              labelText='Búsqueda'
              placeholder='Ingrese su búsqueda'
              size='col-12'
              sizeDesk='col-md-4'
              handleValue={(e) => handleAddFilter(e, FILTER_BUSQUEDA)}
              value={busqueda}
            />

            <Select
              labelText='Estatus'
              placeholder='Selecciona'
              size='col-12'
              sizeDesk='col-md-2'
              options={mockEstatus}
              handleValue={(e) => handleAddFilter(e, FILTER_ADD_ESTATUS)}
            />

            <SelectFilter
              labelText='Nombre'
              placeholder='Selecciona'
              size='col-12'
              sizeDesk='col-md-2'
              optionObj={paisesList?.map(({ nombre }) => ({ id: nombre, name: nombre }))}
              handleValue={(e) => handleAddFilter(e, FILTER_ADD_NOMBRE)}
              value={lastPosition(nombre)}
            />

            <SelectFilter
              labelText='Locale'
              placeholder='Selecciona'
              size='col-12'
              sizeDesk='col-md-2'
              optionObj={filtrosPosibles?.locale.map(value => ({ name: value }))}
              handleValue={(e) => handleAddFilter(e, FILTER_ADD_LOCALE)}
              value={lastPosition(locale)}
            />

            <FormInput
              labelText='Patrón identificación'
              placeholder='Buscar'
              size='col-12'
              sizeDesk='col-md-2'
              handleValue={(e) => handleAddFilter(e, FILTER_PATRON_IDENTIFICACION)}
              value={patron_identificacion}
            />

            <SelectFilter
              labelText='Extensión telefónica'
              placeholder='Selecciona'
              size='col-12'
              sizeDesk='col-md-2'
              optionObj={filtrosPosibles?.extension_telefonica.map(value => ({ name: value }))}
              handleValue={(e) => handleAddFilter(e, FILTER_ADD_EXTENSION_TELEFONICA)}
              value={lastPosition(extension_telefonica)}
            />

            <SelectFilter
              labelText='Cod. MAXIMISE'
              placeholder='Selecciona'
              size='col-12'
              sizeDesk='col-md-2'
              optionObj={filtrosPosibles?.cod_maxi.map(value => ({ name: value }))}
              handleValue={(e) => handleAddFilter(e, FILTER_ADD_COD_MAXIMISE)}
              value={lastPosition(cod_maxi)}
            />

            {/* <div className='col-md-2'></div> */}

            <FormInput
              type='date'
              labelText='Creación (desde)'
              size='col-12'
              sizeDesk='col-md-2'
              handleValue={(e) => handleAddFilter(e, FILTER_CREACION_DESDE)}
              value={creacion_desde}
            />

            <FormInput
              type='date'
              labelText='Creación (hasta)'
              size='col-12'
              sizeDesk='col-md-2'
              handleValue={(e) => handleAddFilter(e, FILTER_CREACION_HASTA)}
              value={creacion_hasta}
            />
          </>
        }
        {/* FILTROS APLICADOS - ADICIONALES */}
        <p className='mb-25 text-black'>Filtros aplicados</p>
        <div className='row'>
          <FiltroAplicado array={estatus} func={estatusFilter} title={'Estatus'} />
          <FiltroAplicado array={nombre} func={nombreFilter} title={'Nombres'} />
          <FiltroAplicado array={extension_telefonica} func={extensionTelefonicaFilter} title={'Extensión telefónica'} />
          <FiltroAplicado array={cod_maxi} func={codMaximiseFilter} title={'Cod. Maximise'} />
          <FiltroAplicado array={locale} func={localeFilter} title={'Cod. Maximise'} />
        </div>

        <hr className='my-1' />

      </ListadoHeader>
    </div>
  )
}

export default PaisesFiltros