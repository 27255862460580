
// Components
import { DevolucionesMotivosFiltros } from "components/AccordionFilter/Devoluciones/DevolucionesMotivos";
import DropdownMenuLink from "../../../components/Dropdown/Link";
import DropdownWrapper from "../../../components/Dropdown/Wrapper";
import { DevolucionesMotivosTable } from "components/Tables/Devoluciones/DevolucionesMotivosTable";


const CONSTANT = {
	title: 'Devoluciones - motivos',
	path: 'devoluciones/motivos',
	create_btn: 'Nuevo motivo de devolución'
}


export const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b> - Listado</p>
				<DropdownWrapper text="Acciones" classes="ms-auto">
					<DropdownMenuLink text={CONSTANT.create_btn} href={`/${CONSTANT.path}/create`} icon="Plus" />
				</DropdownWrapper>
			</h3>

			<DevolucionesMotivosFiltros />

			<DevolucionesMotivosTable />
		</div>
	);
}
