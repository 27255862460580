// Redux
import { useSelector } from 'react-redux'
import { clearFilterDevolucionesReportes, resultadosDevolucionesReportes } from '../../../redux/actions/devolucionesReportes';


// Components
import Table from "../../Table";


// Layouts
import AccordionTable from "../../../layout/AccordionTable";


const CONSTANT = {
	permissionsPath: 'pedidos',
	reduxSelector: 'devolucionesReportes',
	pathUrl: '/devoluciones/reportes/operativo',
	title: 'Devoluciones - Reporte General',
	reduxClearFilters: clearFilterDevolucionesReportes,
	reduxSetResults: resultadosDevolucionesReportes,
}


export const DevolucionesReporteGeneralTable = () => {
	const { resultados: response, } = useSelector(state => state[CONSTANT.reduxSelector])

	return (
		response?.['reporte_general']
		
			? <>
				<div className="col">
					<div className="card">
						<h2 className='text-center pt-2 fs-1'>Resumen</h2>

						<hr className='my-50 mx-5' />

						<section className='row p-1'>
							<div className="col-12 col-md-4 d-flex flex-column p-3">
								<h3 className='text-decoration-underline fw-bold'>Cantidades</h3>
								<span className='fs-4'>Neumáticos: <b>{response?.['reporte_general']?.totalizacion?.cantidad_neumaticos ?? 'Error'}</b></span>
								<span className='fs-4'>Notas de venta: <b>{response?.['reporte_general']?.totalizacion?.cantidad_nv ?? 'Error'}</b></span>
							</div>

							<div className="col-12 col-md-4 d-flex flex-column p-3">
								<h3 className='text-decoration-underline fw-bold'>Neumáticos a devolver</h3>
								<span className='fs-4'>Despacho Express: <b>{response?.['reporte_general']?.neumaticos?.express ?? 'Error'}</b></span>
								<span className='fs-4'>Despacho Taller: <b>{response?.['reporte_general']?.neumaticos?.taller ?? 'Error'}</b></span>
								<span className='fs-4'>Marketplaces: <b>{response?.['reporte_general']?.neumaticos?.marketplace ?? 'Error'}</b></span>
								<span className='fs-4'>Aliados: <b>{response?.['reporte_general']?.neumaticos?.aliado ?? 'Error'}</b></span>
							</div>

							<div className="col-12 col-md-4 d-flex flex-column p-3">
								<h3 className='text-decoration-underline fw-bold'>Días promedio</h3>
								<span className='fs-4'>Devolución de dinero: <b>{response?.['reporte_general']?.dias_promedio?.dinero ?? 'Error'}</b></span>
								<span className='fs-4'>Traslado a bodega: <b>{response?.['reporte_general']?.dias_promedio?.traslado ?? 'Error'}</b></span>
								<span className='fs-4'>Confirmación en bodega: <b>{response?.['reporte_general']?.dias_promedio?.confirmacion ?? 'Error'}</b></span>
							</div>
						</section>

						<hr className='my-50 mx-5' />

						<section className='row p-1'>
							<div className='col d-flex flex-column p-3'>
								<h3 className='text-decoration-underline fw-bold'>Total</h3>
								<span className='fs-4'>Despacho Express: <b>{response?.['reporte_general']?.totalizacion_montos?.total ?? 'Error'}</b></span>
							</div>

							<div className='col d-flex flex-column p-3'>
								<h3 className='text-decoration-underline fw-bold'>Qty. NV</h3>
								<span className='fs-4'>Despacho Express: <b>{response?.['reporte_general']?.totalizacion_montos?.cantidad_nv ?? 'Error'}</b></span>
							</div>

							<div className='col d-flex flex-column p-3'>
								<h3 className='text-decoration-underline fw-bold'>Qty. NKTOS</h3>
								<span className='fs-4'>Despacho Express: <b>{response?.['reporte_general']?.totalizacion_montos?.cantidad_neumaticos ?? 'Error'}</b></span>
							</div>

							<div className='col d-flex flex-column p-3'>
								<h3 className='text-decoration-underline fw-bold'>Precio promedio</h3>
								<span className='fs-4'>Despacho Express: <b>{response?.['reporte_general']?.totalizacion_montos?.precio_promedio ?? 'Error'}</b></span>
							</div>
						</section>
					</div>
				</div>

				<AccordionTable title={`${CONSTANT.title} (${response?.['reporte_general']?.listado?.length ?? 0} resultados)`}>
					<Table response={response}>
						<thead className="table-light position-sticky top-0 w-100 z-100 shadow">
							<tr role="row">
								<th>Solicitud</th>
								<th>Rev. en Logística</th>
								<th>Recep. en Bodega</th>
								<th>Anulación GEV</th>
								<th>Emisión NC</th>
								<th>Anulación NV</th>
								<th>En aliados comerciales</th>
							</tr>
						</thead>

						<tbody>
							{response?.['reporte_general']?.listado.length

								? response['reporte_general'].listado.map(({
									solicitud, revision_logistica, revision_bodega,
									gev, notas_credito, anulacion_nv, en_aliado_comercial }, i) => {
									return (
										<tr className="odd" key={`table-reporte-general-row-${i}`}>
											<td className='col-1 fw-bolder text-uppercase'>{solicitud ?? 'N/D'}</td>
											<td className='col-1 fw-bolder text-uppercase'>{revision_logistica ?? 'N/D'}</td>
											<td className='col-1 fw-bolder text-uppercase'>{revision_bodega ?? 'N/D'}</td>
											<td className='col-1 fw-bolder text-uppercase'>{gev ?? 'N/D'}</td>
											<td className='col-1 fw-bolder text-uppercase'>{notas_credito ?? 'N/D'}</td>
											<td className='col-1 fw-bolder text-uppercase'>{anulacion_nv ?? 'N/D'}</td>
											<td className='col-1 fw-bolder text-uppercase'>{en_aliado_comercial ?? 'N/D'}</td>
										</tr>
									)
								})

								: <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
							}
						</tbody>
					</Table>
				</AccordionTable>
			</>

			: <div className="col">
				<div className="card">
					<h3 className='p-2 text-center'>Por favor, realice una búsqueda</h3>
				</div>
			</div>
	)
}