import axios from 'axios'
import { useEffect, useState } from 'react'


// Redux
import { fireToaster } from '../../redux/actions/toaster'
import { useDispatch } from 'react-redux'


// Components
import Accordion from '../../components/Accordion'
import AccordionDetailData from '../../components/AccordionDetailData'
import Button from '../../components/Button'
import Switch from '../../components/Switch'
import CustomLoader from '../../components/CustomLoader'


// Router Dom
import { useNavigate, useParams } from 'react-router-dom'


// Endpoints
import { getOne } from '../../endpoints/getOne'
import { historicoData } from '../../helpers/historicoData'
import { ModalHistorico } from '../../components/Historico/ModalHistorico'
import TablaDetalle from '../../components/Historico/TablaDetalle'
import moment from 'moment'
import { deleteHorarioDespacho } from '../../services/horariosDespacho/delete.service'
import { ModalHorariosDespacho } from '../../components/Modal/MetodosEntrega/ModalHorariosDespacho'


const CONSTANT = {
  queryUrlGetOne: getOne.metodos_entrega,
  title: 'Métodos de entrega',
  redirectListado: '/generales/metodos-entrega',
  redirectUpdate: id => `/generales/metodos-entrega/update/${id}`,
  modelo: 'App\\Models\\NeuMetodoEntrega',
}


const Detalle = () => {
  const [data, setData] = useState(null)
  const { id } = useParams()
  const dispatch = useDispatch()
  const redirect = useNavigate()
  const [historico, setHistorico] = useState(null)
  const [modal, setModal] = useState({ id: null, display: false, data: null })
  const [horariosDespachoModal, setHorariosDespachoModal] = useState({ display: false, data: null })


  const fetchData = async () => {
    await axios(CONSTANT.queryUrlGetOne(id),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => {
        setData(data)
        historicoData(Number(id), CONSTANT.modelo).then(data => setHistorico(data))
      })
      .catch(err => {
        console.error(err)
        const toasterContent = {
          title: 'Registro no encontrado',
          icon: 'warning'
        }

        dispatch(fireToaster(toasterContent))
        redirect(CONSTANT.redirectListado)
      })
  }


  // EFECTO QUE CONSULTA LA INFORMACIÓN DEL REGISTRO
  useEffect(() => {
    fetchData()

    return () => {
      setData(null)
    }
  }, [id, dispatch, redirect])


  // FUNCIÓN MANEJADORA PARA ACTUALIZACIÓN DEL ESTATUS
  const handleFetch = async (objData, target) => {
    const newEstatus = objData[target] ? 0 : 1

    let config = {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    }

    if (['usar_despacho_express', 'estatus'].includes(target)) {
      config = {
        ...config,
        method: 'POST',
        data: { [target]: newEstatus, _method: 'PUT' }
      }
    }

    if (target === 'delete') {
      config = {
        ...config,
        method: 'DELETE',
      }
    }

    await axios(CONSTANT.queryUrlGetOne(objData.id), config)
      .then(res => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${target} actualizado con éxito`,
          icon: 'success'
        }

        dispatch(fireToaster(toasterContent))
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }

        dispatch(fireToaster(toasterContent))
      })
      .finally(async () => {
        await fetchData()
      })
  }


  // FUNCION MANEJADORA DE LA DATA DEL MODAL
  const handleModal = (data) => {
    const { id, anterior, nuevo } = data

    setModal({
      display: true,
      id,
      data: {
        anterior,
        nuevo
      }
    })
  }


  // MARK: FUNCION MANEJADORA DE MODAL HORARIOS DEPACHO 
  const handleHorariosDespachoModal = (data) => {
    setHorariosDespachoModal(() => ({
      display: true,
      data: {
        ...data,
        metodo_entrega_id: data?.id ?? data?.metodo_entrega_id
      }
    }))
  }


  // MARK: FUNCION MANEJADORA DE MODAL HORARIOS DEPACHO (close)
  const handleCloseHorariosDespachoModal = () => {
    setHorariosDespachoModal(() => ({ display: false, data: null }))
  }


  const handleDeleteHorarioDespacho = async (id) => {
    await deleteHorarioDespacho(id)
      .then(res => {
        fetchData()
      })
      .catch(err => {
        console.error(err)
      })
  }


  return (
    <div className='row'>
      <div className='col-12 mx-auto'>
        <div className='col mb-50'>
          <div className='d-flex gap-1'>
            <div className='col'>
              <Button text={CONSTANT.title} icon='ArrowLeft' isLink url={CONSTANT.redirectListado} />
            </div>

            <Button text='Editar' icon='Settings' color='success' isLink url={CONSTANT.redirectUpdate(id)} />

            <Button text='Borrar' icon='Trash' color='danger' onClick={() => handleFetch(data, 'delete')} />
          </div>
        </div>

        {/* MARK: INFORMACION GENERAL */}
        {data
          ? <div className='row'>
            <Accordion
              title={<h3 className='m-0 w-100 text-center text-white'>Datos generales</h3>}
              classes='col-md-6 mb-2'
              isOpen
            >
              <AccordionDetailData dataKey={'ID'} value={data?.id} />
              <AccordionDetailData dataKey={'Nombre'} value={data?.nombre ?? 'N/A'} />
              <AccordionDetailData dataKey={'Código ERP'} value={data?.cod_erp ?? 'N/A'} />
              <AccordionDetailData dataKey={'Descripción'} value={data?.descripcion ?? 'N/A'} />
              <AccordionDetailData dataKey={'Estatus'} value={
                <Switch
                  value={data?.estatus}
                  labelText={""}
                  margin='my-0'
                  handleValue={() => handleFetch(data, 'estatus')}
                />}
              />
              <AccordionDetailData dataKey={'Creación'} value={data?.created_at?.split('T')[0] ?? 'N/A'} />
              <AccordionDetailData dataKey={'Modicación'} value={data?.updated_at?.split('T')[0] ?? 'N/A'} last />
            </Accordion>


            {/* MARK: HORARIOS-DESPACHO */}
            <div className='col-12 col-md-6 mb-1'>
              <div className='rounded overflow-hidden border'>
                <div className='d-flex justify-content-between border-bottom bg-color-brand p-50'>
                  <h3 className='m-0 h-fit text-center text-white d-flex justify-content-between ms-1'>Horarios despacho</h3>

                  <Button text='Añadir horario' icon='Plus' color='success' className='me-1 p-25' onClick={() => handleHorariosDespachoModal(data)} />
                </div>

                <div className='bg-white'>
                  {data?.horarios?.length
                    ? data?.horarios.map(({ hora_cierre, hora_despacho, id, metodo_entrega_id }) => (
                      <div key={`${hora_cierre}-${hora_despacho}-${id}`} className='border-bottom'>
                        <div className='d-flex justify-content-between p-25 px-50'>
                          <span>
                            Hora cierre:{' '}
                            <b className='text-center h-fit my-auto'>{moment(`01/01/0001 ${hora_cierre}`).format('HH:mm') ?? '?'}</b>
                          </span>

                          <span>
                            Hora despacho:{' '}
                            <b className='text-center h-fit my-auto'>{moment(`01/01/0001 ${hora_despacho}`).format('HH:mm') ?? '?'}</b>
                          </span>
                          <div className='d-flex gap-50'>
                            <Button className='p-25' text='Editar' icon='Settings' onClick={() => handleHorariosDespachoModal({ hora_cierre, hora_despacho, despacho_id: id, metodo_entrega_id })} />

                            <Button className='p-25' text='Eliminar' icon='X' color='danger' onClick={() => handleDeleteHorarioDespacho(id)} />
                          </div>
                        </div>
                      </div>
                    ))

                    : <div className='p-50'>Sin horarios asignados...</div>
                  }
                </div>
              </div>
            </div>

            <TablaDetalle historicoData={historico} fnHandleModal={handleModal} />

          </div>

          : <CustomLoader />
        }
      </div>

      <ModalHistorico modal={modal} fnHandleModal={setModal} />

      <ModalHorariosDespacho
        display={horariosDespachoModal.display}
        handleClose={handleCloseHorariosDespachoModal}
        data={horariosDespachoModal.data}
        fnFetchData={fetchData}
      />

    </div>
  )
}

export default Detalle