import { Link } from 'react-router-dom';


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from '../../../redux/actions/toaster';
import { clearFilterAutoservicioSucursales, resultadosAutoservicioSucursales } from '../../../redux/actions/autoservicioSucursales';


// Components
import Table from "../../Table";
import Button from '../../Button';


// Layouts
import AccordionTable from "../../../layout/AccordionTable";


// Helpers
import { responseToasterContent } from '../../../helpers/toasterContent';
import { queryParamsFormatter } from '../../../helpers/queryParamsFormatter';


// Services
import { getAllSucursales } from '../../../services/autogestion/sucursales/getAll.service';
import { deleteSucursal } from '../../../services/autogestion/sucursales/delete.service';


const CONSTANT = {
	permissionsPath: 'autoservicio/sucursales',
	pathUrl: '/autoservicio/sucursales',
	reduxStateSelector: 'autoservicioSucursales',

	title: 'Autoservicio - Sucursales',

	reduxClearFilters: clearFilterAutoservicioSucursales,
	reduxSetResults: resultadosAutoservicioSucursales,
}


const fetchData = async (params, limit, url = undefined) => {
	return await getAllSucursales(params, limit, url)
		.then(res => res)
		.catch(err => {
			console.error(err);
			return Promise.reject(err)
		})
}


export const SucursalesTable = () => {
	const { resultados: response, filtros } = useSelector(state => state[CONSTANT.reduxStateSelector])
	const { showing_results } = useSelector(state => state.listedResults)
	const dispatch = useDispatch()


	// FUNCION MANEJADORA DE PAGINACIÓN
	const handleUrl = async (url) => {
		await fetchData(filtros, showing_results, url)
			.then(res => {
				dispatch(CONSTANT.reduxSetResults(res.data))
			})
			.catch(err => {
				dispatch(fireToaster(responseToasterContent(err)))
				dispatch(CONSTANT.reduxSetResults([]))
			})
	}


	const handleDelete = async (id, url) => {
		await deleteSucursal(id)
			.then(res => {
				handleUrl(url)
			})
			.catch(err => {
				console.error(err);
			})
	}


	return (
		<>
			<AccordionTable title={`${CONSTANT.title} (${response?.data?.length ?? 0} resultados)`}>
				<Table response={response} handlePagination={handleUrl}>
					<thead className="table-light position-sticky top-0 w-100 z-100 shadow">
						<tr role="row">
							<th>ID</th>
							<th>Nombre</th>
							<th>Creación</th>
							<th>Acciones</th>
						</tr>
					</thead>

					<tbody>
						{response?.data.length
							? response.data?.map((data) => {
								return (
									<tr
										className="odd"
										key={`user-${data.id}-${data.titulo}`}
									>
										<td className='col'>{data?.id}</td>

										<td className='col'>
											<Link to={`${CONSTANT.pathUrl}/detalle/${data?.id}`}>
												{data?.nombre}
											</Link>
										</td>

										<td className='col-2'>{data?.created_at?.split("T")[0] || 'N/A'}</td>

										<td className='col-2'>
											<div className='d-flex flex-column gap-25'>
												<Button
													isLink
													text={'Editar'}
													className='w-fit p-25 px-50'
													icon='Settings'
													color={'warning'}
													url={`${CONSTANT.pathUrl}/update/${data?.id}`}
												/>

												<Button
													text={'Borrar'}
													className='w-fit py-25 px-50'
													icon='Trash'
													onClick={() => handleDelete(data?.id, response.path + '?page=' + response.current_page + `&limit=${showing_results}` + queryParamsFormatter(filtros))}
													color={'danger'}
												/>
											</div>
										</td>
									</tr>
								)
							})

							: <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
						}
					</tbody>
				</Table>
			</AccordionTable >
		</>
	)
}