import { useEffect, useState } from "react";


// Redux
import { fireToaster } from "../../../redux/actions/toaster";
import { useDispatch } from "react-redux";


//	Components
import FormInput from "../../../components/FormInput";
import Button from "../../../components/Button";
import CustomForm from "../../../components/CustomForm";
import CustomLoader from "../../../components/CustomLoader";
import SelectFilter from "../../../components/SelectFilter";


// React-router
import { useNavigate, useParams } from "react-router-dom";


// Services
import { createUsuarioSucursal } from "../../../services/autogestion/usuariosSucursales/create.service";
import { updateUsusarioSucursal } from "../../../services/autogestion/usuariosSucursales/update.service";
import { getAllSucursales } from "../../../services/autogestion/sucursales/getAll.service";
import { getAllUsuariosSucursales } from "../../../services/autogestion/usuariosSucursales/getAll.service";


// Helpers
import { createFormatter, updateFormatter } from "./helpers/formFormatter";
import { responseToasterContent } from "../../../helpers/toasterContent";
import { successResponse } from "../../../helpers/successResponse";
import { getDetailedUsuarioSucursal } from "../../../services/autogestion/usuariosSucursales/getDetail.service";
import Switch from "../../../components/Switch";


const CONSTANT = {
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },

  redirectUrl: '/autoservicio/usuarios-sucursales',

  componentTitle: id => `${id ? 'Editar' : 'Crear'} Usuario`,
  title: 'Usuarios/Sucursales',

  form: {
    sucursal_id: '', //int
    usuario: '', // str
    clave: '', //str

    cambiar_clave: false
  }
}


const fetchDetailedData = async (id) => {
  return await getDetailedUsuarioSucursal(id)
    .then(res => res)
    .catch(err => {
      console.error(err);
      return Promise.reject(err)
    })
}


export const fetchSelectsData = async () => {
  const sucursales = await getAllSucursales()
    .then(res => {
      return [...res.data.data]
    })
    .catch(err => {
      console.error(err);
      return []
    })

  return { sucursales }
}


export const Form = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const [form, setForm] = useState(CONSTANT.form)
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilter)
  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE SETEA LOS DATOS PARA EL FORMULARIO DE EDICION
  useEffect(() => {
    if (!id) return
    setIsConsulting(true)

    fetchDetailedData(id)
      .then(({ data }) => {
        const defaultSucursalId = `${data?.sucursal?.id ? data?.sucursal?.id + '-' : ''}${data?.sucursal?.nombre}`


        setForm((prev) => ({
          ...prev,
          ...data,
          sucursal_id: defaultSucursalId
        }))
      })
      .finally(() => {
        setIsConsulting(false)
      })

  }, [id])


  useEffect(() => {
    fetchSelectsData().then(x => setSelectFilter(x))
    return () => {
      setForm(CONSTANT.form)
    }
  }, [])


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  // FUNCIÓN MANEJADORA QUE CREA/MODIFICA EL REGISTRO EN LA BBDD
  const handleSubmit = async (e) => {
    e.preventDefault()

    setIsConsulting(true)

    const fnSubmit = (id = undefined) => id
      ? updateUsusarioSucursal(id, updateFormatter({ ...form }))
      : createUsuarioSucursal(createFormatter({ ...form }))

    fnSubmit(id)
      .then(res => {
        dispatch(fireToaster(responseToasterContent(res)))
        if (successResponse(res)) navigate(CONSTANT.redirectUrl)
      })
      .finally(() =>
        setIsConsulting(false)
      )

    return
  }


  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='row'>
          <Button
            text={CONSTANT.title}
            icon='ArrowLeft'
            style='flat'
            isLink
            url={CONSTANT.redirectUrl}
            className='col-6'
          />

          {id &&
            <Button
              text={`Volver a la sucursal #${id}`}
              icon='File'
              style='flat'
              isLink
              url={`${CONSTANT.redirectUrl}/detalle/${id}`}
              className='col-6'
            />
          }
        </div>

        {id && !form?.id &&
          <CustomLoader blocking={'partial'} />
        }

        <CustomForm size="" title={CONSTANT.componentTitle(id)}>
          <div className="row">
            <hr className="my-1" />

            <FormInput
              labelText='* Nombre'
              placeholder='Ingrese un nombre'
              name='usuario'
              value={form.usuario}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
            />

            <SelectFilter
              labelText='* Sucursal'
              name='sucursal_id'
              value={form.sucursal_id?.split('-')[1] ?? 'Seleccione'}
              handleValue={handleInputChange}
              optionObj={selectFilter?.sucursales?.map(({ id, nombre }) => ({ id, name: nombre })) ?? []}
              size='col-12'
              sizeDesk='col-md-6'
            />

            {id &&
              <Switch
                name="cambiar_clave"
                value={form?.cambiar_clave}
                labelText="Cambiar clave"
                handleValue={handleInputChange}
                size="col-12"
              />
            }

            <FormInput
              labelText='* Clave'
              placeholder='12345'
              name='clave'
              value={form.clave}
              handleValue={handleInputChange}
              isDisabled={!form?.cambiar_clave}
              size='col-12'
              sizeDesk='col-md-6'
            />
          </div>

          <Button
            type="submit"
            text={id ? 'Actualizar' : "Crear"}
            color="primary"
            disabled={isConsulting}
            icon={id ? 'Check' : "Plus"}
            onClick={(e) => handleSubmit(e)}
          />
        </CustomForm>
      </div >
    </div >
  );
};
