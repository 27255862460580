
// Redux
import { useDispatch, useSelector } from "react-redux";


// React-Router
import { LogisticaReportesRouter } from "../../routers/Logistica/ReportesRouter";


// Components
import { filtrosFormatter, LogisticaReportesFiltros } from "../../components/AccordionFilter/LogisticaReportes";
import DownloadFile from "../../components/Download/DownloadFile";
import Tabs from "../../components/Tabs";

// endpoints
import { getAll } from "../../endpoints/getAll";

// Helpers
import { queryParamsFormatter } from "../../helpers/queryParamsFormatter";
import { fireToaster } from "../../redux/actions/toaster";


// Services


const CONSTANT = {
  title: 'Logistica',
  path: 'logistica/reportes',
  reduxStateSelector: 'logisticaReportes',

  queryExportar: (filters) => getAll.notas_ventas + `/reportes/reposicion-stock/exportar?q=s${queryParamsFormatter(filters)}`
}

const tabsList = [
  { name: 'Reposición stock', url: '/logistica/reportes/reposicion-stock' },
  { name: 'No reposición stock', url: '/logistica/reportes/no-reposicion-stock' },
]


export const Listado = () => {
  const { filtros } = useSelector(state => state[CONSTANT.reduxStateSelector])
  const dispatch = useDispatch()


  const handleDownloadBtnErrors = (filtros) => {
    if (!filtros?.fecha_desde) return dispatch(fireToaster({ title: 'Debe seleccionar una fecha inicial', icon: 'warning' }))
    if (!filtros?.fecha_hasta) return dispatch(fireToaster({ title: 'Debe seleccionar una fecha final', icon: 'warning' }))
  }


  return (
    <div className="row">
      <h3 className="mb-1 d-flex justify-content-between">
        <p><b>{CONSTANT.title}</b></p>

        <div>
          <DownloadFile
            url={CONSTANT.queryExportar(filtrosFormatter(filtros))}
            method="GET"
            btnText='Exportar Reposición de Stock (formato MAXIMISE)'
            handleErrors={() => handleDownloadBtnErrors(filtros)}
          />
        </div>
      </h3>

      <LogisticaReportesFiltros />

      <Tabs
        tabs={tabsList}
      />

      <LogisticaReportesRouter />
    </div>
  );
}
