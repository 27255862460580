
// Redux


// React-Router
import { LogisticaLineasDespachoRouter } from "../../../routers/Logistica/LineasDespachoRouter";


// Components
import Tabs from "../../../components/Tabs";
import { LogisticaLineasDespachoFiltros } from "../../../components/AccordionFilter/LogisticaLineasDespacho";


// Helpers


// Services


const CONSTANT = {
  title: 'Logistica - Líneas Despacho',
  path: 'logistica/lineas-despacho',
  reduxStateSelector: 'logisticaLineasDespacho',
}

const tabsList = [
  { name: 'Válidas', url: '/logistica/lineas-despacho/validas' },
  { name: 'Inválidas', url: '/logistica/lineas-despacho/invalidas' },
]


export const Listado = () => {

  return (
    <div className="row">
      <h3 className="mb-1 d-flex justify-content-between">
        <p><b>{CONSTANT.title}</b></p>

      </h3>

      <LogisticaLineasDespachoFiltros />

      <Tabs
        tabs={tabsList}
      />

      <LogisticaLineasDespachoRouter />
    </div>
  );
}
