import axios from "axios";


// Redux
import { fireToaster } from "../../redux/actions/toaster";
import { useDispatch } from "react-redux";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";


// React-router
import { useNavigate, useParams } from "react-router-dom";


// Endpoints
import { useEffect, useState } from "react";
import SelectFilter from "../../components/SelectFilter";
import CustomLoader from "../../components/CustomLoader";
import { getOne } from "../../endpoints/getOne";
import CheckBoxInput from "../../components/CheckboxInput";
import TextareaInput from "../../components/TextareaInput";
import FileUploaderB from "../../components/FileUploader_B";
import { searchCategorias } from "../../services/categorias/search.service";
import { createFormatter, updateFormatter } from "./helpers/formFormatter";
import { createServicio } from "../../services/servicios/create.service";
import { responseToasterContent } from "../../helpers/toasterContent";
import { getDetailedServicio } from "../../services/servicios/getDetail.service";
import { updateServicio } from "../../services/servicios/update.service";
import { successResponse } from "../../helpers/successResponse";


const CONSTANT = {
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  redirectUrl: '/servicios',
  componentTitle: id => `${id ? 'Editar' : 'Crear'} Servicio`,
  title: 'Servicios',

  fecthData: getOne.servicios,

  selectFilter: {
    categorias: [],
  },

  form: {
    categoria_id: '',
    sku: '',
    nombre: '',
    descripcion: '',
    precio_final: undefined,
    nuevo: 1,
    es_variante: 1,
    publicado: 1,
    mostrar_en_cliente: 0,
    "meta-descripcion": '',
    "datos-estructurados": '',
    "meta-titulo": '',

    // optional
    ultimo_costo: undefined,
  }
}


const handleSelectFiltersData = async () => {
  const categorias = await searchCategorias(undefined, undefined, undefined, { filtros: { tipo_elemento_id: [2] } })
    .then((res) => {
      return [CONSTANT.unselectedObject, ...res?.data?.categorias?.data] ?? [CONSTANT.nullObject]
    })
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  return { categorias }
}


const Form = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const [imagenes, setImagenes] = useState([])
  const [form, setForm] = useState(CONSTANT.form)
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilter)
  const [isConsulting, setIsConsulting] = useState(false)

  useEffect(() => {
    if (!id || !selectFilter.categorias.length) return

    getDetailedServicio(id).then(({ data }) => {
      console.log(data);
      const { id, nombre, nuevo, es_variante, publicado, mostrar_en_cliente, categoria_id, sku, descripcion, precio_final, ultimo_costo } = data.servicio
      const { id: categoriaId, nombre: categoriaIdNombre } = selectFilter.categorias.find(({ id }) => id === categoria_id)

      setForm((prev) => ({
        ...prev,
        categoria_id: `${categoriaId ?? '*NULL*'}-${categoriaIdNombre ?? 'Sin selección'}`,
        id,
        nombre,
        nuevo,
        es_variante,
        publicado,
        mostrar_en_cliente,
        sku,
        descripcion,
        precio_final,
        ultimo_costo,
        "meta-descripcion": data.servicio["meta-descripcion"],
        "datos-estructurados": data.servicio["datos-estructurados"],
        "meta-titulo": data.servicio["meta-titulo"]
      }))
    })

  }, [id, selectFilter.categorias])



  // EFECTO QUE SETEA LA DATA PARA LOS SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(res => setSelectFilter(res))

    return () => {
      setForm(CONSTANT.form)
      setImagenes([])
    }
  }, [])


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  // FUNCIÓN MANEJADORA QUE CREA/MODIFICA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()
    setIsConsulting(true)

    if (id) {
      updateServicio(id, updateFormatter({ ...form, imagenes }))
        .then(res => {
          dispatch(fireToaster(responseToasterContent(res)))
          if (successResponse(res)) navigate(CONSTANT.redirectUrl)
        })
        .finally(() =>
          setIsConsulting(false)
        )

      return
    }

    createServicio(createFormatter({ ...form, imagenes }))
      .then(res => {
        dispatch(fireToaster(responseToasterContent(res)))
        if (successResponse(res)) navigate(CONSTANT.redirectUrl)
      })
      .finally(() =>
        setIsConsulting(false)
      )

    return
  }


  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {id && !form?.id &&
          <CustomLoader blocking={'partial'} />
        }

        {true
          ? <CustomForm size="" title={CONSTANT.componentTitle(id)}>

            <div className="row">
              <CheckBoxInput
                name='publicado'
                value={form.publicado}
                labelText='Publicado'
                handleValue={handleInputChange}
                classes='col-6 my-auto'
              />

              <CheckBoxInput
                name='mostrar_en_cliente'
                value={form.mostrar_en_cliente}
                labelText='Mostrar en cliente'
                handleValue={handleInputChange}
                classes='col-6 my-auto'
              />

              <CheckBoxInput
                name='nuevo'
                value={form.nuevo}
                labelText='Nuevo'
                handleValue={handleInputChange}
                classes='col-6 my-auto'
              />

              <CheckBoxInput
                name='es_variante'
                value={form.es_variante}
                labelText='Es variante'
                handleValue={handleInputChange}
                classes='col-6 my-auto'
              />

              <hr className="my-1" />

              <FormInput
                labelText='* Nombre'
                placeholder='Nombre'
                name='nombre'
                value={form.nombre}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />

              {!id
                ? <SelectFilter
                  labelText='* Categoría'
                  name='categoria_id'
                  value={form.categoria_id?.split('-')[1] ?? 'Seleccione'}
                  handleValue={handleInputChange}
                  optionObj={selectFilter?.categorias.map(({ id, nombre }) => ({ id, name: nombre })) ?? []}
                  size='col-12'
                  sizeDesk='col-md-6'
                />

                : <FormInput
                  labelText='Categoría'
                  value={form?.categoria_id.split('-')[1] ?? 'Error'}
                  isDisabled={true}
                  size='col-12'
                  sizeDesk='col-md-6'
                />
              }

              <FormInput
                labelText='* SKU'
                placeholder='Escriba un SKU'
                name='sku'
                value={form.sku}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <FormInput
                labelText='* Descripción'
                placeholder='Escriba una descripción'
                name='descripcion'
                value={form.descripcion}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <FormInput
                labelText='Ultimo costo'
                placeholder='10000'
                name='ultimo_costo'
                value={form.ultimo_costo}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <FormInput
                labelText='* Precio final'
                placeholder='10000'
                name='precio_final'
                value={form.precio_final}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <TextareaInput
                labelText='* Meta-título'
                name='meta-titulo'
                value={form['meta-titulo']}
                rows={1}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-12'
                placeholder='Ingrese un texto'
              />

              <TextareaInput
                labelText='* Datos-estruturados'
                name='datos-estructurados'
                value={form['datos-estructurados']}
                rows={1}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-12'
                placeholder='Ingrese un texto'
              />

              <TextareaInput
                labelText='* Meta-descripción'
                name='meta-descripcion'
                value={form['meta-descripcion']}
                rows={4}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-12'
                placeholder='Ingrese un texto'
              />

              {!id &&
                <FileUploaderB handleValue={setImagenes} value={imagenes} labelText='Imagen' />
              }
            </div>

            <Button
              type="submit"
              text={id ? 'Actualizar' : "Crear"}
              color="primary"
              disabled={isConsulting}
              icon={id ? 'Check' : "Plus"}
              onClick={(e) => handleFetch(e)}
            />
          </CustomForm>
          : <CustomLoader />
        }
      </div >
    </div >
  );
};

export default Form;
