import { useEffect, useState } from 'react'


// Redux
import { fireToaster } from '../../../redux/actions/toaster'
import { useDispatch } from 'react-redux'


// Components
import Accordion from '../../../components/Accordion'
import AccordionDetailData from '../../../components/AccordionDetailData'
import Button from '../../../components/Button'
import CustomLoader from '../../../components/CustomLoader'
import { ModalHistorico } from '../../../components/Historico/ModalHistorico'
import TablaDetalle from '../../../components/Historico/TablaDetalle'


// Router Dom
import { Link, useNavigate, useParams } from 'react-router-dom'


// Endpoints
import { historicoData } from '../../../helpers/historicoData'


// Services
import { getDetailedUsuarioSucursal } from '../../../services/autogestion/usuariosSucursales/getDetail.service'
import { deleteUsuarioSucursal } from '../../../services/autogestion/usuariosSucursales/delete.service'


const CONSTANT = {
   title: 'Usuarios/Sucursales',
   redirectListado: '/autoservicio/usuarios-sucursales',
   modelo: 'App\\Models\\NeuDevolucion',
   redirectUpdate: (id) => `/autoservicio/usuarios-sucursales/update/${id}`,
}


export const Detalle = () => {
   const [data, setData] = useState(null)
   const { id } = useParams()
   const dispatch = useDispatch()
   const redirect = useNavigate()
   const [historico, setHistorico] = useState(null)
   const [modal, setModal] = useState({ id: null, display: false, data: null })

   const fetchData = () => {
      getDetailedUsuarioSucursal(id)
         .then(res => {
            setData(res.data)
         })
         .catch((err) => {
            console.error(err)
            const toasterContent = {
               title: 'Registro no encontrado',
               icon: 'warning'
            }

            dispatch(fireToaster(toasterContent))
            redirect(CONSTANT.redirectListado)
         })

      historicoData(Number(id), CONSTANT.modelo).then(data => setHistorico(data))
   }


   // EFECTO QUE CONSULTA LA INFORMACIÓN DEL REGISTRO
   useEffect(() => {
      fetchData()

      return () => {
         setData(null)
      }
   }, [id, dispatch, redirect])


   // FUNCION MANEJADORA DE LA DATA DEL MODAL
   const handleModal = (data) => {
      const { id, anterior, nuevo } = data

      setModal({
         display: true,
         id,
         data: {
            anterior,
            nuevo
         }
      })
   }

   const handleDelete = (id) => {
      deleteUsuarioSucursal(id).finally(() => fetchData())
   }


   return (
      <div className='row'>
         <ModalHistorico modal={modal} fnHandleModal={handleModal} />

         <div className='col-12'>
            <div className='col mb-50'>
               <div className='d-flex gap-1'>
                  <div className='col'>
                     <Button text={CONSTANT.title} icon='ArrowLeft' isLink url={CONSTANT.redirectListado} />
                  </div>
                  <Button text='Editar' icon='Settings' color='success' isLink url={CONSTANT.redirectUpdate(id)} />
                  <Button text='Borrar' icon='Trash' color='danger' onClick={() => handleDelete(id)} />
               </div>
            </div>

            <div className='row'>
               {data
                  ? <>
                     <div className='mx-auto col-md-6'>
                        <Accordion
                           title={<h3 className='m-0 w-100 text-center text-white'>Datos generales</h3>}
                           classes='mb-2'
                           isOpen
                        >
                           <AccordionDetailData dataKey={'ID'} value={data?.id} />
                           <AccordionDetailData dataKey={'Usuario'} value={data?.usuario ?? 'N/A'} />
                           <AccordionDetailData dataKey={'Sucursal'} value={data?.sucursal?.nombre ?? 'N/A'} />
                           <AccordionDetailData dataKey={'Creación'} value={data?.created_at?.split('T')[0] ?? 'N/A'} />
                           <AccordionDetailData dataKey={'Modicación'} value={data?.updated_at?.split('T')[0] ?? 'N/A'} last />
                        </Accordion>
                     </div>

                     <TablaDetalle historicoData={historico} fnHandleModal={handleModal} />
                  </>

                  : <CustomLoader />
               }
            </div>
         </div>
      </div>
   )
}
