import React, { useState } from 'react'


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { FILTER_FECHA_INICIO, FILTER_FECHA_FINAL } from '../../../redux/redux-types'
import { clearFilterDevolucionesReportes, fechaFinalFilter, fechaInicialFilter, resultadosDevolucionesReportes } from '../../../redux/actions/devolucionesReportes'


// Components
import FormInput from '../../FormInput'


// Layouts
import ListadoHeader from '../../../layout/ListadoHeader'


// Helpers
import { filtrosAplicadosTotal } from '../../../helpers/filtrosAplicadosTotal'
import { getAllDevolucionesReporteGeneral } from '../../../services/devoluciones/reportes/getAll.service'
import CustomLoader from '../../CustomLoader'


// Services


const CONSTANT = {
  redirectUrl: '/devoluciones/reportes',
  reduxStateSelector: 'devolucionesReportes',
  title: 'Filtros: Devoluciones - Reporte General',
  reduxClearFilters: clearFilterDevolucionesReportes,
  reduxSetResults: resultadosDevolucionesReportes,
}


export const filtrosFormatter = (filtros) => {
  let validFormat = {}

  Object.entries(filtros).forEach(([k, v]) => {
    if (['fecha_inicial', 'fecha_final'].includes(k) && v.length) {
      validFormat = {
        ...validFormat,
        [k]: v
      }
    }
  })

  return validFormat
}


const fetchData = async (data) => {
  return await Promise.all([
    getAllDevolucionesReporteGeneral(data)
      .then(({ data }) => ({ reporte_general: data }))
      .catch(err => {
        console.error(err);
        return { reporte_general: null }
      }),
  ])
    .then(x => {
      let data = {}

      x.forEach(d => {
        data = { ...data, ...d }
      })

      return data
    })
}


export const DevolucionesReporteGeneralFiltros = () => {
  const dispatch = useDispatch()
  const { filtros } = useSelector(state => state[CONSTANT.reduxStateSelector])
  const { fecha_inicial, fecha_final } = filtros

  const [isConsulting, setIsConsulting] = useState(false)


  // FUNCION QUE CONSULTA EL BACKEND CON LOS FILTROS SELECCIONADOS
  const handleFetchFilter = async (reset = false) => {
    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())
      return
    }

    setIsConsulting(true)

    await fetchData(filtrosFormatter(filtros))
      .then(res => {
        dispatch(CONSTANT.reduxSetResults(res))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }


  // MANEJADORES DE FILTRO
  const handleFilter = (e, filter) => {
    const { value } = e.target

    switch (filter) {
      case FILTER_FECHA_INICIO:
        dispatch(fechaInicialFilter(value))
        break;

      case FILTER_FECHA_FINAL:
        dispatch(fechaFinalFilter(value))
        break;


      default:
        break;
    }
  }


  return (
    <div className="card mb-50 shadow-none bg-transparent">
      {isConsulting && <CustomLoader blocking={'full'} />}

      <ListadoHeader
        classes='border rounded-2'
        title={`${CONSTANT.title} (${filtrosAplicadosTotal(filtros)})`}
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetchFilter()}
        handleClickClearFilter={() => handleFetchFilter(true)}
      >
        <>
          <FormInput
            labelText='Fecha inicial'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleFilter(e, FILTER_FECHA_INICIO)}
            value={fecha_inicial}
            type={'date'}
          />

          <FormInput
            labelText='Fecha final'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleFilter(e, FILTER_FECHA_FINAL)}
            value={fecha_final}
            type={'date'}
          />
        </>
      </ListadoHeader>
    </div>
  )
}