import {
   FILTER_CLEAR,
   FILTER_FECHA_INICIO,
   FILTER_FECHA_FINAL,
   RESULTADOS_DEVOLUCIONES_REPORTES,
} from "../redux-types";

const initialState = {
   filtros: {
      fecha_inicial: '',
      fecha_final: '',
   },
   resultados: null
}


export const devolucionesReportesReducer = (state = initialState, action) => {
   switch (action.type) {
      case FILTER_FECHA_INICIO:
         return {
            ...state, filtros: {
               ...state.filtros,
               fecha_inicial: action.payload
            }
         };

      case FILTER_FECHA_FINAL:
         return {
            ...state, filtros: {
               ...state.filtros,
               fecha_final: action.payload
            }
         };

      case RESULTADOS_DEVOLUCIONES_REPORTES:
         return {
            ...state, resultados: action.payload
         };

      case FILTER_CLEAR:
         return {
            ...state,
            filtros: initialState.filtros
         };

      default:
         return state;
   }
};