import React from 'react'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { clearFilterLogisticaReportes, resultadosLogisticaReportes } from '../../../redux/actions/logisticaReportes';


// Components
import Table from "../../Table";


// Layouts
import AccordionTable from "../../../layout/AccordionTable";


// Services
import { getAllLogisticaReportes } from '../../../services/logistica/reportes/getAll.service';


// Helpers
import { filtrosFormatter } from '../../AccordionFilter/LogisticaReportes';


const CONSTANT = {
	permissionsPath: 'notas-venta',
	reduxSelector: 'logisticaReportes',
	pathUrl: '/logistica/reportes/no-reposicion-stock',
	title: 'No Reposición de Stock',
	reduxClearFilters: clearFilterLogisticaReportes,
	reduxSetResults: resultadosLogisticaReportes,
}


export const NoResposicionStockTable = () => {
	const { resultados: response, filtros } = useSelector(state => state[CONSTANT.reduxSelector])
	const dispatch = useDispatch()


	// FUNCION MANEJADORA DE PAGINACIÓN
	const handleUrl = (url) => {
		// console.log(url);
		dispatch(CONSTANT.reduxSetResults(null))

		getAllLogisticaReportes(filtrosFormatter(filtros), url).then(res => {
			if (res.status >= 400) {
				// dispatch(fireToaster(toasterErrorFormatter({ errors: res.data, response: res })))
				dispatch(CONSTANT.reduxSetResults([]))
				return
			}

			dispatch(CONSTANT.reduxSetResults(res))
		})
	}


	return (
		<>
			{response?.no_reposicion !== null
				? <AccordionTable title={`${CONSTANT.title} (${response?.data?.length ?? 0} resultados)`}>
					<Table response={response} handlePagination={handleUrl}>
						<thead className="table-light position-sticky top-0 w-100 z-100 shadow">
							<tr role="row">
								<th>DESC_TEXT</th>
								<th>PART_CODE</th>
								<th>QTY</th>
							</tr>
						</thead>

						<tbody>
							{response?.no_reposicion?.length
								? response.no_reposicion?.map((data) => {
									return (
										<tr className="odd" key={`user-${data.DESC_TEXT}-${data.PART_CODE}`}>
											<td className='col-1'>{data?.DESC_TEXT}</td>
											<td className='col-1'>{data?.PART_CODE}</td>
											<td className='col-1'>{data?.QTY}</td>
										</tr>
									)
								})
								: <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={8}><i>Sin datos para mostrar...</i></td></tr>
							}
						</tbody>
					</Table >
				</AccordionTable >

				: <div className='col-12'><h2 className='card p-2 text-center mb-0 fw-bolder'><i>Por favor realice una búsqueda</i></h2></div>}

		</>
	)
}