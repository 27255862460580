
// Components
import { SucursalesFiltros } from "../../../components/AccordionFilter/Autoservicio/Sucursales";
import Button from "../../../components/Button";
import { SucursalesTable } from "../../../components/Tables/Autoservicio/SucursalesTable";


const CONSTANT = {
	title: 'Autoservicio - Sucursales',
	path: 'autoservicio/sucursales',
	create_btn: 'Nueva sucursal'
}


export const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b></p>
			</h3>

			<div className="d-flex gap-50 my-50">
				<Button isLink text={CONSTANT.create_btn} url={`/${CONSTANT.path}/create`} icon="Plus" />
			</div>

			<SucursalesFiltros />

			<SucursalesTable />
		</div>
	);
}
