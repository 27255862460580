import axios from "axios";
import validator from "validator";


//	Components
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import FormInput from "../../components/FormInput";


// Redux
import { useDispatch, useSelector } from "react-redux";
import { fireToaster } from "../../redux/actions/toaster";


// React-router
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Accordion from "../../components/Accordion";
import SelectFilter from "../../components/SelectFilter";
import AccordionTable from "../../layout/AccordionTable";
import Table from "../../components/Table";
import CheckBoxInput from "../../components/CheckboxInput";
import { NoResults } from "../../components/Tables/Misc/NoResults";
import { getAll } from "../../endpoints/getAll";
import { getOne } from "../../endpoints/getOne";
import { idExtractor } from "../../helpers/idExtractor";
import CustomLoader from "../../components/CustomLoader";
import { PrecioFormatter } from "../../helpers/precioFormatter";
import { renderRecargoInstalacion } from "../../helpers/renderRecargoInstalacion";
import { validateRut } from "../../helpers/validateRut";
import { getAllPaises } from "../../services/paises/getAll.service";



const Precio = (v) => new PrecioFormatter(v).producto()
const PrecioRaw = (v) => new PrecioFormatter(v).producto(true)


const instalacionProductosFormatter = (arrProd, isTarifaPlana, instalacionHasta, prod) => {
  if (prod?.linea_oferta?.precio_instalacion === null) return null
  const hasInstPermanente = arrProd?.find(({ linea_oferta }) => linea_oferta?.instalacion_permanente) ?? 0
  const totalQty = arrProd?.reduce((prev, current) => prev += current.cantidad, 0)
  const higherInst = Number(arrProd?.sort((a, b) => Number(b?.linea_oferta?.precio_instalacion) - Number(a?.linea_oferta?.precio_instalacion))?.[0]?.linea_oferta?.precio_instalacion) ?? 0
  const subtotalLineasProductos = arrProd?.reduce((prev, current) => prev += Number(current.precio_unidad) * Number(current.cantidad), 0) ?? 0

  return (isTarifaPlana)
    ? (hasInstPermanente)
      ? Number(higherInst / totalQty)
      : (subtotalLineasProductos >= instalacionHasta)
        ? '0.00'
        : Number(higherInst / totalQty)
    : (hasInstPermanente)
      ? Number(prod?.linea_oferta?.precio_instalacion)
      : (subtotalLineasProductos >= instalacionHasta)
        ? '0.00'
        : Number(prod?.linea_oferta?.precio_instalacion)
}


const renderInstalacionTotal = (isTarifaPlana, instalacionHasta, arrlineasProductos) => {
  const hasInstPermanente = arrlineasProductos?.find(({ linea_oferta }) => linea_oferta?.instalacion_permanente) ?? 0
  const subtotalLineasProductos = arrlineasProductos?.reduce((prev, current) => prev += Number(current.precio_unidad) * Number(current.cantidad), 0) ?? 0
  const instalacionLineasProductos = arrlineasProductos?.reduce((prev, current) => prev += Number(current?.linea_oferta?.precio_instalacion) * Number(current.cantidad), 0) ?? 0
  const higherInst = Number(arrlineasProductos?.sort((a, b) => Number(b?.linea_oferta?.precio_instalacion) - Number(a?.linea_oferta?.precio_instalacion))?.[0]?.linea_oferta?.precio_instalacion) ?? 0

  if (subtotalLineasProductos >= instalacionHasta) {
    if (hasInstPermanente) {
      if (isTarifaPlana) {
        return renderRecargoInstalacion(higherInst)
      }
      else return renderRecargoInstalacion(instalacionLineasProductos)
    }
    else return `Gratis`
  }

  else return (isTarifaPlana)
    ? renderRecargoInstalacion(higherInst)
    : renderRecargoInstalacion(instalacionLineasProductos)
}


const CONSTANT = {
  queryUrlGetOne: getOne.cotizaciones,
  componentTitle: 'Generar pedido',
  title: 'Pedidos',
  redirectListado: `/pedidos`,
  redirectUrl: (id) => `/pedidos/detalle/${id}`,
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },

  queryUrlGetAllPaises: getAll.paises + '/buscar',
  queryUrlGetAllComunas: getAll.comunas + '/buscar',
  queryUrlGetAllPuntosEntrega: getAll.puntos_entrega + '/listar',
  queryUrlGetAllMetodosPago: getAll.metodos_pago,
  queryUrlGetAllTiposFacturacion: getAll.tipos_facturacion,
  queryUrl: getAll.cotizaciones + '/pedido',
  fetchStockProducto: getAll.erp_bodegas_stock_productos + '?bodegas_con_saldo=1',
  fetchFrontendId: getAll.frontend_id,
  fetchRoles: getAll.roles,

  selectFilterInitialState: {
    comunas: [],
    paises: [],
    puntosEntrega: [],
    metodosPago: [],
    tiposFacturacion: [],
    frontendId: [],
    roles: []
  }
}


const validPrecioDescuento = (precio, descuento) => {
  return (precio * ((100 - descuento) / 100))
}


const paisSetter = (data, selectFilterObj) => {
  const defaultNull = {
    id: '*NULL*',
    nombre: 'Error'
  }
  // if (data?.punto_entrega?.comuna?.region?.pais_id && data?.punto_entrega?.comuna?.region?.pais?.nombre) {
  if (data?.pais_cliente?.id && data?.pais_cliente?.nombre) {
    // return `${data?.punto_entrega?.comuna?.region?.pais_id}-${data?.punto_entrega?.comuna?.region?.pais?.nombre}`
    return `${data?.pais_cliente?.id}-${data?.pais_cliente?.nombre}`

  }
  else {
    const { id, nombre } = selectFilterObj?.paises?.find(({ nombre }) => nombre?.toLowerCase().includes('chile')) ?? defaultNull
    return `${id}-${nombre}`
  }

}


const handleSelectFiltersData = async () => {
  const roles = await axios(CONSTANT.fetchRoles,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => data.filter(({ user_type }) => ['web'].includes(user_type)))
    .catch(err => {
      console.error(err)
      return []
    })

  const frontendId = await axios(CONSTANT.fetchFrontendId,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => data)
    .catch(err => {
      console.error(err)
      return []
    })

  const comunas = await axios(CONSTANT.queryUrlGetAllComunas,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }, method: 'POST'
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data.comunas] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const metodosPago = await axios(CONSTANT.queryUrlGetAllMetodosPago,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const paises = await getAllPaises()
    .then(({ data }) => [CONSTANT.unselectedObject, ...data])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const puntosEntrega = await axios(CONSTANT.queryUrlGetAllPuntosEntrega,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }, method: 'POST'
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const tiposFacturacion = await axios(CONSTANT.queryUrlGetAllTiposFacturacion,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  return { comunas, metodosPago, paises, puntosEntrega, tiposFacturacion, frontendId, roles }
}


const validateOferta = (linea_oferta, cantidad) => {
  const criterio = linea_oferta?.criterio?.simbolo ?? undefined
  const limite = Number(linea_oferta?.cantidad_limite ?? 0)
  if (!criterio) return false

  switch (criterio) {
    case '>':
      return cantidad > limite

    case '>=':
      return cantidad >= limite

    case '=':
      return cantidad === limite

    case '<=':
      return cantidad <= limite

    case '<':
      return cantidad < limite

    default:
      return false
  }
}


const validateAplicarInstalacion = (total, totalInstalacion, instalacion) => {
  if (totalInstalacion === null || instalacion === null) return renderRecargoInstalacion(instalacion)
  return (total <= totalInstalacion) ? renderRecargoInstalacion(instalacion) : 'Gratis'
}


const Generate = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
  const [data, setData] = useState(null)
  const [pedido, setPedido] = useState(null)
  const [codigosProductos, setCodigosProductos] = useState(null)
  const [isWaiting, setIsWaiting] = useState(false)
  const { user } = useSelector(state => state.auth)


  // EFFECTO QUE DISPARA EL MANEJADOR DE DATA PARA SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  // EFECTO QUE CONSULTA LA INFORMACIÓN DEL REGISTRO
  useEffect(() => {
    if (!selectFilter.paises.length) return

    axios(CONSTANT.queryUrlGetOne(id),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => {
        setCodigosProductos(data.lineas_cotizacion.map(({ producto }) => String(producto?.sku) ?? null).filter(el => el !== null))

        setPedido({
          pais_id: paisSetter(data, selectFilter),
          cotizacion_id: data.id,
          divisa_id: `${data?.divisa_id ?? 'N/A'}-${data?.divisa?.nombre ?? 'N/A'}` ?? null,
          metodo_pago_id: `${data?.metodo_pago_id ?? 'N/A'}-${data.metodo_pago?.nombre ?? 'N/A'}` ?? null,
          direccion_entrega: data?.punto_entrega?.direccion ?? data.direccion_entrega ?? null,
          usuario_id: data.usuario_id ?? null,
          tipo_cliente_id: `${data?.tipo_cliente?.id ?? '*NULL*'}-${data?.tipo_cliente?.name ?? 'Error'}`,
          frontend_id: `${data?.frontend?.id ?? '*NULL*'}-${data.frontend?.nombre ?? 'chileneumaticos'}` ?? null,
          aplicar_instalacion_hasta: data?.aplicar_instalacion_hasta,

          // usuario
          nombre: data.nombre_cliente ?? null,
          fono: data.telefono_cliente ?? null,
          email: data?.email_cliente ?? null,
          tipo_facturacion_id: `${data?.tipo_facturacion?.id}-${data?.tipo_facturacion?.nombre}`,
          rut: data?.rut_cliente ?? data?.usuario?.rut ?? '',

          // facturacion
          comuna_id: `${data?.punto_entrega?.comuna?.id ?? 'N/A'}-${data?.punto_entrega?.comuna?.nombre ?? 'N/A'}` ?? null,
          empresa_rut: data.rut_empresa ?? null,

          // productos
          productos: data?.lineas_cotizacion.map((producto) => ({ ...producto, cantidad: producto.cantidad_cotizada, seleccionado: true, recargo: Number(producto?.recargo) }))
        })

        setData(data)
      })
      .catch(err => console.error(err))

    return () => {
      setData(null)
    }
  }, [id, selectFilter?.paises, selectFilter])
  console.log(pedido);


  // CONDICIONALES DE TIPO DE FACTURACION
  useEffect(() => {
    if (!selectFilter?.roles.length) return

    if (pedido?.tipo_facturacion_id?.includes('Factura')) {
      const { id, name } = selectFilter?.roles?.find(({ name }) => name === 'empresa')

      setPedido(pedido => ({
        ...pedido,
        tipo_cliente_id: `${id}-${name}`
      }))
    }

    if (pedido?.tipo_facturacion_id?.includes('Boleta')) {
      const { id, name } = selectFilter?.roles?.find(({ name }) => name === 'particular')

      setPedido(pedido => ({
        ...pedido,
        tipo_cliente_id: `${id}-${name}`
      }))
    }

  }, [pedido?.tipo_facturacion_id, selectFilter?.roles])


  useEffect(() => {
    if (!codigosProductos && !data) return
    let newData = pedido?.productos

    axios(CONSTANT.fetchStockProducto,
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST',
        data: { codigos_productos: codigosProductos }
      })
      .then(({ data: erp_data }) => {

        Object.entries(erp_data).forEach(([k, v]) => {
          const current = newData?.findIndex(({ producto }) => producto.sku === k)
          const newProducto = {
            ...newData?.[current],
            stock_erp: v
          }

          newData = newData.map((el, i) => current === i
            ? { ...newProducto, cantidad: newProducto.cantidad_cotizada, seleccionado: true }
            : { ...el, cantidad: el.cantidad_cotizada, seleccionado: true }
          )
        })
        setPedido(pedido => ({ ...pedido, productos: newData }))
      })
      .catch(err => console.error(err))

  }, [data, codigosProductos])


  // FUNCION MANEJADORA DE LOS DATOS DEL PEDIDO
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setPedido({
      ...pedido,
      [name]: value
    })
  }


  const handleCheckboxInputProductoPedido = (e) => {
    const targetId = e.target.name

    setPedido({
      ...pedido, productos: pedido.productos.map(el => el.id === Number(targetId)
        ? { ...el, seleccionado: !el.seleccionado }
        : el
      )
    })
  }


  const handleInputCantidadProductoPedido = (e) => {
    let { name: id, value } = e.target
    if (value <= 0) value = '1'

    setPedido({
      ...pedido, productos: pedido.productos.map(el => el.id === Number(id)
        ? { ...el, cantidad: Number(value) }
        : el
      )
    })
  }


  const handleInputPrecioProducto = (e) => {
    let { name: id, value } = e.target
    if (value < 0) value = '1'

    setPedido({
      ...pedido, productos: pedido.productos.map(el => Number(el.id) === Number(id)
        ? { ...el, precio_unidad: Number(value) }
        : el
      )
    })
  }


  const handleInputRecargoProducto = (e) => {
    let { name: id, value } = e.target
    const regExNum = /\d+/

    let cleanRecargo = regExNum.test(value)
      ? (Number(value) < 0) ? 0 : Number(value)
      : null

    setPedido({
      ...pedido, productos: pedido.productos.map(el => Number(el.id) === Number(id)
        ? { ...el, recargo: cleanRecargo }
        : el
      )
    })
  }


  // FUNCIÓN MANEJADORA QUE CREA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()
    let badPrecioUnitario = []
    const { patron_identificacion, locale, extension_telefonica } = selectFilter.paises.find((({ id }) => id === idExtractor(pedido.pais_id)))
    const isChileSelected = pedido.pais_id.includes('Chile')

    if (isChileSelected && !validateRut(pedido?.rut, patron_identificacion)) return dispatch(fireToaster({ title: 'El RUT no es válido para el país seleccionado', icon: 'info' }))
    if (!pedido?.email?.length) return dispatch(fireToaster({ title: 'El email es requerido', icon: 'info' }))
    if (!validator.isEmail(pedido?.email)) return dispatch(fireToaster({ title: 'El formato del email no es válido', icon: 'info' }))
    if (pedido?.fono?.length) {
      if (!validator.isMobilePhone(`${extension_telefonica}${pedido.fono}`, [locale], { strictMode: true })) return dispatch(fireToaster({ title: 'El teléfono no es válido para el país seleccionado', icon: 'info' }))
    }

    if (pedido.tipo_facturacion_id.toLowerCase().includes('factura') &&
      !validateRut(pedido?.empresa_rut, patron_identificacion)) return dispatch(fireToaster({ title: 'El RUT de empresa no es válido para el país seleccionado', icon: 'info' }))

    let dataPedido = {
      cotizacion_id: pedido.cotizacion_id,
      divisa_id: idExtractor(pedido.divisa_id),
      metodo_pago_id: idExtractor(pedido.metodo_pago_id),
      direccion_entrega: pedido.direccion_entrega,
      tipo_facturacion_id: idExtractor(pedido.tipo_facturacion_id),
      tipo_cliente_id: idExtractor(pedido.tipo_cliente_id),

      nombre: pedido.nombre,
      email: pedido.email,
      rut: pedido.rut,
      fono: pedido.fono,
      pais_id: idExtractor(pedido.pais_id),
      comuna_id: idExtractor(pedido.comuna_id),
      empresa_razon_social: pedido.empresa_razon_social,
      empresa_giro: pedido.empresa_giro,
      empresa_direccion: pedido.empresa_direccion,
      empresa_rut: pedido.empresa_rut,

      // modificar este formateo cuando los inputs y el checkbox esten implementados
      productos: pedido.productos.filter(el => el.seleccionado !== false).map((producto) => ({
        id: producto.id,
        cantidad: producto.cantidad,
        precio: Number(producto?.precio_unidad),
        recargo: producto?.recargo,
        precio_instalacion: instalacionProductosFormatter(pedido.productos.filter(({ seleccionado }) => seleccionado), data?.es_tarifa_plana, Number(pedido?.aplicar_instalacion_hasta), producto)
      }))
    }

    // REVISION DE PRECIOS LUEGO DE FORMATEADOS LOS PRODUCTOS QUE SE VAN A ENVIAR
    dataPedido.productos.forEach(prod => {
      const porcentajeValido = (prod.precio_final * (100 - user?.porcentaje_descuento_max ?? 30)) / 100
      if (prod.precio_ingresado < porcentajeValido) return badPrecioUnitario = [...badPrecioUnitario, prod.sku]
    })

    if (badPrecioUnitario.length) {
      return dispatch(fireToaster({
        title: 'Existen productos seleccionados con precios por debajo del límite permitido',
        html: `
      Lista de productos (SKU):
      ${badPrecioUnitario.map(sku => (
          `<br /><b>✖ ${sku}</b>`
        ))}
    `,
        icon: 'warning',
        timer: 180000
      }))
    }

    if (!pedido?.frontend_id.includes('*NULL*')) {
      dataPedido = {
        ...dataPedido,
        frontend_id: idExtractor(pedido?.frontend_id)
      }
    }

    if (pedido.usuario_id) {
      dataPedido = {
        ...dataPedido,
        usuario_id: pedido.usuario_id
      }
    }
    if (!data.usuario_id && (!dataPedido.rut || dataPedido.rut.length < 1)) { // Aquí (Mario)
      const toasterContent = {
        title: 'El RUT es requerido',
        text: `Por favor, ingrese un RUT de cliente`,
        icon: 'warning'
      }
      return dispatch(fireToaster(toasterContent))
    }
    if (!data.usuario_id && (!dataPedido.nombre || dataPedido.nombre.length < 1)) { // Aquí (Mario)
      const toasterContent = {
        title: 'El nombre es requerido',
        text: `Por favor, ingrese un nombre`,
        icon: 'warning'
      }
      return dispatch(fireToaster(toasterContent))
    }
    if (!data.usuario_id && (!dataPedido.email || dataPedido.email.length < 1)) { // Aquí (Mario)
      const toasterContent = {
        title: 'El nombre es requerido',
        text: `Por favor, ingrese un email`,
        icon: 'warning'
      }
      return dispatch(fireToaster(toasterContent))
    }
    if (!dataPedido.direccion_entrega || dataPedido.direccion_entrega.length < 1) {
      const toasterContent = {
        title: 'La dirección de entrega es requerida',
        text: `Por favor, ingrese una dirección de entrega`,
        icon: 'warning'
      }
      return dispatch(fireToaster(toasterContent))
    }
    if (!dataPedido.metodo_pago_id || dataPedido.metodo_pago_id.length < 1) {
      const toasterContent = {
        title: 'El método de pago es requerido',
        text: `Por favor, ingrese un método de pago`,
        icon: 'warning'
      }
      return dispatch(fireToaster(toasterContent))
    }

    // VALIDACIONES CUANDO ES TIPO FACTURACION: FACTURA
    if (pedido.tipo_facturacion_id.split('-')[1] === 'Factura') {
      if (!dataPedido.comuna_id || dataPedido.comuna_id.length < 1) {
        const toasterContent = {
          title: 'La comuna es requerida',
          text: `Por favor, ingrese una comuna`,
          icon: 'warning'
        }
        return dispatch(fireToaster(toasterContent))
      }
      if (!dataPedido.empresa_rut || dataPedido.empresa_rut.length < 1) {
        const toasterContent = {
          title: 'El RUT de empresa es requerido',
          text: `Por favor, ingrese un RUT de empresa`,
          icon: 'warning'
        }
        return dispatch(fireToaster(toasterContent))
      }
      if (!dataPedido.empresa_razon_social || dataPedido.empresa_razon_social.length < 1) {
        const toasterContent = {
          title: 'La razón social es requerida',
          text: `Por favor, ingrese una razón social`,
          icon: 'warning'
        }
        return dispatch(fireToaster(toasterContent))
      }
      if (!dataPedido.empresa_giro || dataPedido.empresa_giro.length < 1) {
        const toasterContent = {
          title: 'El giro es requerido',
          text: `Por favor, ingrese un giro`,
          icon: 'warning'
        }
        return dispatch(fireToaster(toasterContent))
      }
      if (!dataPedido.empresa_direccion || dataPedido.empresa_direccion.length < 1) {
        const toasterContent = {
          title: 'La dirección de facturación es requerida',
          text: `Por favor, ingrese una dirección`,
          icon: 'warning'
        }
        return dispatch(fireToaster(toasterContent))
      }
    }

    setIsWaiting(true)

    axios(CONSTANT.queryUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
      },
      data: dataPedido
    })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: creado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        return navigate(CONSTANT.redirectUrl(data?.id))
      })
      .catch(err => {
        console.error(err)
        const { errores, message } = err.response.data

        if (message?.includes('code "550"')) {
          const toasterContent = {
            title: 'Operación realizada',
            text: `${CONSTANT.title}: creado con éxito, con error al enviar el correo`,
            icon: 'success'
          }
          dispatch(fireToaster(toasterContent))
          return navigate(CONSTANT.redirectListado)
        }

        let detalles = []
        Object.values(errores).forEach(errores => errores.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
          Operación fallida
          (${err.response.status} - ${err.response.statusText})
        `,
          html: `
          <b>Detalle: </b>
          ${detalles.map(error => `<br /><i>-${error}</i>`)}
        `,
          icon: 'error'
        }

        return dispatch(fireToaster(toasterContent))
      })
      .finally(() => { setIsWaiting(false) })
  }


  return (
    <div className="row">
      <div className="col col-md-12 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectListado} />
        </div>

        {data && pedido && selectFilter.comunas.length && selectFilter.metodosPago.length
          && selectFilter.paises.length && selectFilter.puntosEntrega.length && selectFilter.tiposFacturacion.length

          ? <CustomForm size="" title={CONSTANT.componentTitle}>
            <div>
              <div className="row">
                {/* COLUMNA IZQUIERDA */}
                <div className="col">
                  {/* CLIENTE */}
                  <Accordion classes={"py-50"} title="Cliente" isOpen>
                    <div className="card-body">
                      <div className="row">

                        <SelectFilter
                          name='frontend_id'
                          labelText="* Frontend"
                          size="col-12"
                          sizeDesk="col-md-6"
                          handleValue={handleInputChange}
                          value={pedido.frontend_id?.split('-')[1] ?? 'Seleccione'}
                          optionObj={selectFilter.frontendId?.map(({ id, nombre }) => ({ id, name: nombre ?? 'Error' }))}
                        />

                        <SelectFilter
                          name='tipo_cliente_id'
                          labelText="* Tipo cliente"
                          size="col-12"
                          sizeDesk="col-md-6"
                          handleValue={handleInputChange}
                          value={pedido.tipo_cliente_id?.split('-')[1] ?? 'Seleccione'}
                          optionObj={selectFilter.roles?.map(({ id, name }) => ({ id, name: name ?? 'Error' }))}
                        />

                        <SelectFilter
                          labelText="País"
                          name='pais_id'
                          size="col-12"
                          sizeDesk="col-md-6"
                          optionObj={selectFilter.paises?.map(({ nombre, id }) => ({ id, name: nombre })) ?? []}
                          handleValue={handleInputChange}
                          value={pedido?.pais_id?.split('-')?.[1] ?? 'Seleccione'}
                        />

                        <FormInput
                          labelText={!pedido?.pais_id?.includes('Chile') ? "* DNI" : "* RUT"}
                          placeholder="Rut cliente"
                          name='rut'
                          size="col-12"
                          sizeDesk="col-md-6"
                          handleValue={handleInputChange}
                          value={pedido?.rut ?? undefined}
                        />

                        <FormInput
                          labelText="* Nombre"
                          name='nombre'
                          placeholder="Luis Hernandez"
                          size="col-12"
                          sizeDesk="col-md-6"
                          handleValue={handleInputChange}
                          value={pedido?.nombre ?? undefined}
                        />

                        <FormInput
                          labelText="* Email"
                          name='email'
                          placeholder="rick@gmail.com"
                          size="col-12"
                          sizeDesk="col-md-6"
                          type="email"
                          handleValue={handleInputChange}
                          value={pedido?.email ?? undefined}
                        />

                        <FormInput
                          type="number"
                          name='fono'
                          labelText="Teléfono"
                          placeholder="961151346"
                          size="col-12"
                          sizeDesk="col-md-6"
                          handleValue={handleInputChange}
                          value={pedido?.fono ?? undefined}
                        />

                        <SelectFilter
                          labelText="* Facturación"
                          name={'tipo_facturacion_id'}
                          size="col-12"
                          sizeDesk="col-md-6"
                          optionObj={selectFilter.tiposFacturacion?.map(({ nombre, id }) => ({ id, name: nombre })) ?? []}
                          handleValue={handleInputChange}
                          value={pedido?.tipo_facturacion_id?.split('-')[1] ?? 'Seleccione'}
                        />
                      </div>
                    </div>
                  </Accordion>

                </div>
                {/* FIN COLUMNA IZQUIERDA */}

                {/* COLUMNA DERECHA */}
                <div className="col col-md-6">
                  {/* PUNTO ENTREGA - MÉTODO DE PAGO */}
                  <Accordion classes={"py-50"} title="Punto de entrega - Método de pago" isOpen>
                    <div className="card-body">
                      <div className="row">
                        <FormInput
                          isDisabled
                          labelText="* Punto de entrega"
                          size="col-12"
                          sizeDesk="col-md-12"
                          value={`${data?.punto_entrega?.tipo_destino_id ? data?.punto_entrega?.tipo_destino?.nombre + ', ' : ''}${data?.punto_entrega?.descripcion ? data?.punto_entrega?.descripcion + ', ' : ''}${data?.punto_entrega?.nombre ? data?.punto_entrega?.nombre : 'N/A'}`}
                        />

                        <FormInput
                          name='direccion_entrega'
                          isDisabled={data?.punto_entrega?.direccion ? true : false}
                          labelText="* Dirección de entrega"
                          placeholder="Coloque una dirección"
                          size="col-12"
                          sizeDesk="col-md-12"
                          handleValue={handleInputChange}
                          value={data?.punto_entrega?.direccion ?? pedido?.direccion_entrega ?? undefined}
                        />

                        <SelectFilter
                          labelText="* Método de pago"
                          name='metodo_pago_id'
                          size="col-12"
                          sizeDesk="col-md-12"
                          optionObj={selectFilter.metodosPago?.map(({ nombre, id }) => ({ id, name: nombre })) ?? []}
                          handleValue={handleInputChange}
                          value={pedido?.metodo_pago_id?.split('-')[1] ?? 'Seleccione'}
                        />
                      </div>
                    </div>
                  </Accordion>
                </div>
                {/* FIN COLUMNA DERECHA */}
              </div>

              {/* DATOS FACTURACION */}
              {pedido?.tipo_facturacion_id.split('-')[1] === 'Factura' &&
                <Accordion classes={"py-50"} title="Datos de facturación" isOpen>
                  <div className="card-body">
                    <div className="row">
                      <SelectFilter
                        labelText="* Comuna"
                        name='comuna_id'
                        size="col-12"
                        sizeDesk="col-md-4"
                        optionObj={selectFilter.comunas?.map(({ nombre, id }) => ({ id, name: nombre })) ?? []}
                        handleValue={handleInputChange}
                        value={pedido?.comuna_id?.split('-')[1] ?? 'Seleccione'}
                      />

                      <FormInput
                        labelText="* RUT empresa"
                        name='empresa_rut'
                        placeholder="RUT empresa"
                        size="col-12"
                        sizeDesk="col-md-4"
                        handleValue={handleInputChange}
                        value={pedido?.empresa_rut ?? undefined}
                      />

                      <FormInput
                        labelText="* Razón social"
                        name='empresa_razon_social'
                        placeholder="Razón social"
                        size="col-12"
                        sizeDesk="col-md-4"
                        handleValue={handleInputChange}
                        value={pedido?.empresa_razon_social}
                      />

                      <FormInput
                        labelText="* Giro"
                        name='empresa_giro'
                        placeholder="Giro"
                        size="col-12"
                        sizeDesk="col-md-4"
                        handleValue={handleInputChange}
                        value={pedido?.empresa_giro}
                      />

                      <FormInput
                        labelText="* Dirección"
                        name='empresa_direccion'
                        placeholder="Dirección"
                        size="col-12"
                        sizeDesk="col-md-4"
                        handleValue={handleInputChange}
                        value={pedido?.empresa_direccion}
                      />
                    </div>

                  </div>
                </Accordion>
              }

              <div className="d-flex gap-1 w-100 justify-content-center">
                {/* {console.log(pedido)} */}
                {!pedido?.productos.filter(({ seleccionado }) => seleccionado).find(({ linea_oferta }) => linea_oferta?.instalacion_permanente)
                  ? <span className="tag px-50 mb-50">Instalación hasta: <b>{renderRecargoInstalacion(data?.aplicar_instalacion_hasta)}</b></span>
                  : <span className="tag px-50 mb-50">Inst. permanente: <b>{pedido?.productos.filter(({ seleccionado }) => seleccionado).find(({ linea_oferta }) => linea_oferta?.instalacion_permanente) ? 'Sí' : 'No'}</b></span>
                }
                <span className="tag px-50 mb-50">Es tarifa plana: <b>{data?.es_tarifa_plana ? 'Sí' : 'No'}</b></span>
              </div>

              {/* PRODUCTOS */}
              <AccordionTable title="Productos" classes='mb-3'>
                <Table pb={false} style={{ overflow: 'auto' }}>
                  <thead className="table-light">
                    <tr role="row">
                      <th className="pe-25"></th>
                      <th className="ps-25">Descripción</th>
                      <th>SKU</th>
                      <th style={{ maxWidth: '150px' }}>Qty</th>
                      <th>Stock</th>
                      <th>Oferta</th>
                      <th style={{ minWidth: '120px' }}>P. unit.</th>
                      <th style={{ minWidth: '120px' }}>Recargo</th>
                      <th style={{ minWidth: '120px' }}>subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pedido?.productos.length
                      ? pedido?.productos.map(({ id, producto, precio_unidad, seleccionado, stock_erp, precios_mostrar, linea_oferta, cantidad_cotizada, recargo, cantidad, valor_impuesto }, i, arr) => {
                        return (
                          <React.Fragment key={`tr-${id}`}>

                            <tr key={`${producto?.id}-${producto?.nombre}`}>
                              <td className="pe-25 position-relative">
                                {linea_oferta?.instalacion_permanente === 1
                                  ? <span className="position-absolute h-100 top-0 left-0 text-black" style={{ backgroundColor: '#b6fcc9', width: '20px', writingMode: 'vertical-rl', textOrientation: 'mixed' }}>INST. PERMA.</span>
                                  : <></>
                                }
                                <CheckBoxInput
                                  name={`${id}`}
                                  labelText=''
                                  handleValue={handleCheckboxInputProductoPedido}
                                  value={seleccionado}
                                />
                              </td>

                              <td className="ps-25" style={{ maxWidth: '400px' }}>
                                <div className="d-flex flex-column gap-25">
                                  <a href={`/productos/detalle/${producto?.id}`} target="_blank" rel="noreferrer">
                                    <b>{producto?.nombre.toUpperCase() ?? 'N/A'}</b>
                                  </a>
                                </div>
                              </td>
                              <td>{producto?.sku ?? 'N/A'}</td>

                              <td style={{ maxWidth: '130px' }}>
                                <div className="d-flex">
                                  <FormInput
                                    type="number"
                                    labelText=""
                                    name={`${id}`}
                                    size="col-12"
                                    margin='m-0'
                                    handleValue={handleInputCantidadProductoPedido}
                                    value={cantidad ?? cantidad_cotizada ?? 0}
                                  />
                                </div>
                              </td>

                              <td className="text-center">{producto?.neu_stock_propio?.[0]?.stock ?? '?'}</td>

                              <td>
                                <div className="d-flex flex-column">
                                  <div className={`text-center fw-bolder p-25 tag ${validateOferta(linea_oferta, cantidad) ? 'success' : 'gray'}`}>
                                    {linea_oferta?.nombre_oferta ?? 'N/A'}
                                  </div>
                                  {linea_oferta?.nombre_oferta &&
                                    <p className="sub-text-3 w-max m-0">{linea_oferta?.criterio?.nombre}: <b>{linea_oferta?.cantidad_limite}</b></p>
                                  }
                                </div>
                              </td>

                              <td>
                                <div className="d-flex flex-column">
                                  <FormInput
                                    type='number'
                                    name={`${id}`}
                                    value={Number(precio_unidad)}
                                    margin='my-auto'
                                    classes='d-flex'
                                    size={'col-12 col-md-9'}
                                    handleValue={handleInputPrecioProducto}
                                  />
                                  <span className="sub-text-3 w-max">Ingresado:  <b>{Precio(precios_mostrar?.precio_unitario)}</b></span>
                                  <span className="sub-text-3 w-max">Neto: <b>{Precio((Number(precio_unidad)) / ((100 + Number(valor_impuesto)) / 100))}
                                  </b>
                                  </span>
                                </div>
                              </td>

                              <td>
                                <div className="d-flex flex-column">
                                  {user.permisos['cotizaciones.recargo-dinamico']
                                    ? <FormInput
                                      type='number'
                                      name={`${id}`}
                                      value={recargo ?? undefined}
                                      margin='my-auto'
                                      classes='d-flex'
                                      size={'col-12 col-md-9'}
                                      handleValue={handleInputRecargoProducto}
                                    />

                                    : <span className='w-max'><b>{renderRecargoInstalacion(recargo, 1)}</b></span>
                                  }
                                </div>
                              </td>

                              <td style={{ minWidth: '150px' }}>
                                <div className="d-flex flex-column text-nowrap">
                                  <b className="text-bold fs-4 text-black">{Precio(Number(precio_unidad) * cantidad)}</b>
                                  <span className='w-max'>Inst: {data?.es_tarifa_plana
                                    ? <b>Tarifa plana</b>

                                    : <b>{validateAplicarInstalacion(
                                      pedido?.productos.reduce((prev, current) => {
                                        const currentPrecio = Math.round((Number(current.precio_unidad)))
                                        return prev += (currentPrecio) * current.cantidad
                                      }, 0),
                                      Number(pedido.aplicar_instalacion_hasta),
                                      linea_oferta?.precio_instalacion)}
                                    </b>
                                  }
                                  </span>

                                  <span className='w-max'>Reca: <b>{renderRecargoInstalacion(recargo, cantidad)}</b></span>
                                </div>
                              </td>
                            </tr>

                            <tr style={{ borderBottom: '1px solid #041c62' }} key={`tr2-producto-${data?.id}-${data?.nombre}`}>
                              <td colSpan={9} className="pt-0 px-50">
                                <AccordionTable
                                  title={`Stock disponible`}
                                  classes='ms-25'
                                  isOpen={data.filtro_stock ? true : false}
                                >
                                  <Table>
                                    <thead className='table-light position-sticky top-0 w-100 z-100 shadow'>
                                      <tr key={`tr3-producto-${data?.id}-${data?.nombre}`}>
                                        <th>Bodega</th>
                                        <th>Disponible</th>
                                        <th>Física</th>
                                        <th>Reservado</th>
                                        <th>Facturado sin despachar</th>
                                        <th>por llegar</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {stock_erp?.length
                                        ? stock_erp.map(({ bodega, stock_disponible, stock_fisico, stock_facturado_sin_despachar, stock_por_llegar, stock_reservado }) => (
                                          <tr key={`erp-${data?.id}-${bodega}`}>
                                            <td>
                                              <b>{bodega}</b>
                                            </td>
                                            <td>
                                              <b>{stock_disponible}</b>
                                            </td>
                                            <td>
                                              <b>{stock_fisico}</b>
                                            </td>
                                            <td>
                                              <b>{stock_reservado}</b>
                                            </td>
                                            <td>
                                              <b>{stock_facturado_sin_despachar}</b>
                                            </td>
                                            <td>
                                              <b>{stock_por_llegar}</b>
                                            </td>
                                          </tr>

                                        ))
                                        : <tr><td colSpan={6}>No hay disponibilidad</td></tr>
                                      }
                                    </tbody>
                                  </Table>
                                </AccordionTable>
                              </td>
                            </tr>
                          </React.Fragment>
                        )
                      })
                      : <tr><td><NoResults /></td></tr>
                    }
                  </tbody>
                </Table>
              </AccordionTable>

              {/* TOTALES */}
              <AccordionTable classes={"py-50"} title=<h4 className="m-0 w-100 text-center text-white">Totales</h4> >
                <Table pb={false}>
                  <thead className="table-light">
                    <tr role="row">
                      <th>Despacho</th>
                      <th>Instalación</th>
                      <th>Subtotal</th>
                      {/* <th>Impuestos</th> */}
                      <th>Total</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>
                        <b>
                          {PrecioRaw(pedido?.productos.filter(({ seleccionado }) => seleccionado).reduce((prev, current) => prev += (Number(current.recargo) * current.cantidad), 0))}
                        </b>
                      </td>

                      <td>
                        <b>
                          {renderInstalacionTotal(data?.es_tarifa_plana, Number(pedido?.aplicar_instalacion_hasta), pedido?.productos?.filter(({ seleccionado }) => seleccionado))}
                        </b>
                      </td>

                      <td>
                        <b>
                          {Precio(pedido?.productos.filter(({ seleccionado }) => seleccionado).reduce((prev, current) => {
                            const currentPrecio = Math.round((Number(current.precio_unidad)))

                            return prev += validPrecioDescuento(currentPrecio, current?.porcentaje_descuento) * current?.cantidad
                          }, 0))}
                        </b>
                      </td>

                      <td>
                        <b>
                          {Precio(pedido?.productos.filter(({ seleccionado }) => seleccionado).reduce((prev, current) => {
                            const currentPrecio = Math.round((Number(current.precio_unidad)))

                            return prev += (currentPrecio + Number(current?.recargo) + Number(current?.precio_instalacion)) * current.cantidad
                          }, 0))}
                        </b>
                      </td>

                    </tr>
                  </tbody>
                </Table>
              </AccordionTable>

              <div className="col d-flex py-50  justify-content-center">
                <Button text="Generar pedido" disabled={isWaiting} onClick={handleFetch} />
              </div>
            </div>
          </CustomForm>

          : <CustomLoader />
        }
      </div>
    </div >
  );
};

export default Generate;
