import {
   FILTER_AUTOSERVICIO_SUCURSALES_INPUT,
   FILTER_CLEAR,
   RESULTADOS_AUTOSERVICIO_SUCURSALES,
} from "../redux-types";

export const autoservicioSucursalesInputFilter = ({ key, value }) => ({ type: FILTER_AUTOSERVICIO_SUCURSALES_INPUT, payload: { key, value } })

export const resultadosAutoservicioSucursales = (data) => ({ type: RESULTADOS_AUTOSERVICIO_SUCURSALES, payload: data })

export const clearFilterAutoservicioSucursales = () => ({ type: FILTER_CLEAR });
