
// Components
import { UsuariosSucursalesFiltros } from "../../../components/AccordionFilter/Autoservicio/UsuariosSucursales";
import Button from "../../../components/Button";
import { UsuariosSucursalesTable } from "../../../components/Tables/Autoservicio/UsuariosSucursalesTable";


const CONSTANT = {
	title: 'Autoservicio - Usuarios/Sucursales',
	path: 'autoservicio/usuarios-sucursales',
	create_btn: 'Nuevo usuario'
}


export const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b></p>
			</h3>

			<div className="d-flex gap-50 my-50">
				<Button isLink text={CONSTANT.create_btn} url={`/${CONSTANT.path}/create`} icon="Plus" />
			</div>

			<UsuariosSucursalesFiltros />

			<UsuariosSucursalesTable />
		</div>
	);
}
