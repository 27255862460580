// Redux
import { useSelector } from 'react-redux'
import { clearFilterDevolucionesReportes, resultadosDevolucionesReportes } from '../../../redux/actions/devolucionesReportes';


// Components
import Table from "../../Table";


// Layouts
import AccordionTable from "../../../layout/AccordionTable";


// Helpers
import { responseStringHandler } from '../../../helpers/responseStringHandler';


const CONSTANT = {
	permissionsPath: 'pedidos',
	reduxSelector: 'devolucionesReportes',
	pathUrl: '/devoluciones/reportes/general',
	title: 'Devoluciones - Reporte Operativo',
	reduxClearFilters: clearFilterDevolucionesReportes,
	reduxSetResults: resultadosDevolucionesReportes,
}



export const DevolucionesReporteOperativoTable = () => {
	const { resultados: response } = useSelector(state => state[CONSTANT.reduxSelector])

	
	return (
		response?.['reporte_operativo']

			?
			<>
				<div className="col">
					<div className="card">

						{/* MARK: RESUMEN */}
						<h2 className='text-center pt-2 fs-1'>Resumen</h2>

						<hr className='my-50 mx-5' />

						<section className='row p-1'>
							<div className="col-12 col-md-6 d-flex flex-column p-3">
								<h3 className='text-decoration-underline fw-bold'>Cantidades</h3>

								<span className='fs-4'>Anulación de notas de venta: <b>{responseStringHandler(response?.['reporte_operativo']?.cantidades?.anulacion_nv)}</b></span>

								<span className='fs-4'>GEV: <b>{responseStringHandler(response?.['reporte_operativo']?.cantidades?.gev)}</b></span>

								<span className='fs-4'>Notas de crédito: <b>{responseStringHandler(response?.['reporte_operativo']?.cantidades?.notas_credito)}</b></span>

								<span className='fs-4'>Recepción en bodega: <b>{responseStringHandler(response?.['reporte_operativo']?.cantidades?.revision_bodega)}</b></span>

								<span className='fs-4'>Revisión logística: <b>{responseStringHandler(response?.['reporte_operativo']?.cantidades?.revision_logistica)}</b></span>

								<span className='fs-4'>Solicitudes: <b>{responseStringHandler(response?.['reporte_operativo']?.cantidades?.solicitudes)}</b></span>
							</div>

							<div className="col-12 col-md-6 d-flex flex-column p-3">
								<h3 className='text-decoration-underline fw-bold'>Totalización</h3>

								<span className='fs-4'>Neumáticos: <b>{responseStringHandler(response?.['reporte_operativo']?.totalizacion?.cantidad_neumaticos)}</b></span>

								<span className='fs-4'>Notas de venta: <b>{responseStringHandler(response?.['reporte_operativo']?.totalizacion?.cantidad_nv)}</b></span>

							</div>



							<article className='col-12 col-md-12'>
								<div className="col-12 col-md-4 d-flex flex-column p-3 w-100">
									<h3 className='text-decoration-underline fw-bold text-center'>Notas de venta</h3>

									<span className='fs-4'>Solicitadas: <b>{responseStringHandler(response?.['reporte_operativo']?.notas_venta?.nvs)}</b></span>

									<hr className='my-50 mx-1' />

									<span className='fs-4'>Revisión logística: <b>{responseStringHandler(response?.['reporte_operativo']?.notas_venta?.logistica)}</b></span>

									<hr className='my-50 mx-1' />

									<span className='fs-4'>Recepcionadas en bodega: <b>{responseStringHandler(response?.['reporte_operativo']?.notas_venta?.bodega)}</b></span>

									<hr className='my-50 mx-1' />

									<span className='fs-4'>Anulación de guías de despacho: <b>{responseStringHandler(response?.['reporte_operativo']?.notas_venta?.gev)}</b></span>
								</div>
							</article>
						</section>
					</div>
				</div>

				{/* MARK: TABLA */}
				{/* <AccordionTable title={`${CONSTANT.title} (${response?.['reporte_operativo']?.listado?.length ?? 0} resultados)`}>
					<Table response={response}>
						<thead className="table-light position-sticky top-0 w-100 z-100 shadow">
							<tr role="row">
								<th>Solicitud</th>
								<th>Rev. en Logística</th>
								<th>Recep. en Bodega</th>
								<th>Anulación GEV</th>
								<th>Emisión NC</th>
								<th>Anulación NV</th>
							</tr>
						</thead>

						<tbody>
							{response?.['reporte_operativo']?.listado?.length

								? response['reporte_operativo'].listado.map(({
									solicitud, revision_logistica, revision_bodega,
									gev, notas_credito, anulacion_nv }, i) => {
									return (
										<tr className="odd" key={`table-reporte-general-row-${i}`}>
											<td className='col-1 fw-bolder text-uppercase'>{solicitud ?? 'N/D'}</td>
											<td className='col-1 fw-bolder text-uppercase'>{revision_logistica ?? 'N/D'}</td>
											<td className='col-1 fw-bolder text-uppercase'>{revision_bodega ?? 'N/D'}</td>
											<td className='col-1 fw-bolder text-uppercase'>{gev ?? 'N/D'}</td>
											<td className='col-1 fw-bolder text-uppercase'>{notas_credito ?? 'N/D'}</td>
											<td className='col-1 fw-bolder text-uppercase'>{anulacion_nv ?? 'N/D'}</td>
										</tr>
									)
								})

								: <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
							}
						</tbody>
					</Table>
				</AccordionTable> */}
			</>

			: <div className="col">
				<div className="card">
					<h3 className='p-2 text-center'>Por favor, realice una búsqueda</h3>
				</div>
			</div>
	)
}