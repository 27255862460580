import { useEffect, useState } from "react"


// Redux
import { useDispatch, useSelector } from "react-redux"
import { resultadosDevoluciones } from "../../../redux/actions/devoluciones"
import { fireToaster } from "../../../redux/actions/toaster"


// Components
import CustomLoader from "../../CustomLoader"
import Modal from "../../Modal"
import FormInput from "../../FormInput"
import Button from "../../Button"


// Services
import { getAllDevoluciones } from "../../../services/devoluciones/getAll.service"
import { updateDevolucion } from "../../../services/devoluciones/update.service"


// Helpers
import { toasterErrorFormatter } from "../../../helpers/toasterErrorFormatter"
import { responseToasterContent } from "../../../helpers/toasterContent"
import { filtrosFormatter } from "../../AccordionFilter/Devoluciones/Devoluciones"
import { Link } from "react-router-dom"


const CONSTANT = {
  reduxStateSelector: 'devoluciones',
  reduxSetResults: resultadosDevoluciones,

  form: {
    id: null,
    nueva_nota_venta: ''
  }
}

export const ModalBitacoraNV = ({ data, isOpen, handleClose }) => {
  const { filtros } = useSelector(state => state[CONSTANT.reduxStateSelector])
  const { showing_results } = useSelector(state => state.listedResults)
  const dispatch = useDispatch()
  const [form, setForm] = useState(CONSTANT.form)
  const [editNV, setEditNV] = useState(false)
  const [isConsulting, setIsConsulting] = useState(false)

  useEffect(() => {
    if (!data && !data?.nueva_nota_venta?.length) return

    setForm(prev => ({
      ...prev,
      nueva_nota_venta: data?.nueva_nota_venta ?? '',
      id: data?.id
    }))

    if (!data?.nueva_nota_venta) setEditNV(true)

    return () => {
      setEditNV(false)
      setForm(CONSTANT.form)
    }

  }, [data])


  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm(prev => ({
      ...prev,
      [name]: value
    }))
  }


  const handleEditNV = () => {
    setEditNV(prev => !prev)
  }


  const updateNV = async () => {
    const formData = {
      nueva_nota_venta: Number(form?.nueva_nota_venta)
    }

    setIsConsulting(true)

    await updateDevolucion(form?.id, formData)
      .then(async res => {
        dispatch(fireToaster(responseToasterContent(res)))
        await fetchDevoluciones()
        handleClose()
      })
      .catch(err => {
        console.error(err);
        dispatch(fireToaster(responseToasterContent(err)))
      })
      .finally(() => {
        setIsConsulting(false)
        setEditNV(false)
      })
  }


  const fetchDevoluciones = async () => {
    await getAllDevoluciones(filtrosFormatter(filtros), showing_results)
      .then(res => {
        if (res.status >= 400) {
          dispatch(fireToaster(toasterErrorFormatter({ errors: res.data, response: res })))
          dispatch(CONSTANT.reduxSetResults([]))
          return
        }

        dispatch(CONSTANT.reduxSetResults(res.data))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }


  return (
    <Modal
      display={isOpen}
      handleValue={handleClose}
    >
      {isConsulting && <CustomLoader blocking='partial' />}

      <h4>Nota de venta</h4>

      <section className="d-flex gap-1">
        <FormInput
          name='nueva_nota_venta'
          type='number'
          value={form?.nueva_nota_venta ?? ''}
          handleValue={handleInputChange}
          size='col'
          sizeDesk='col-md-6'
          isDisabled={!editNV}
        />

        {editNV
          ? <div className="d-flex gap-50 h-100 mt-50">
            <Button
              className={'p-50'}
              color={'success'}
              icon={'Check'}
              onClick={() => updateNV()}
            />

            <Button
              className={'p-50'}
              color={'danger'}
              icon={'X'}
              onClick={handleEditNV}
            />
          </div>

          : <div className="d-flex gap-50 h-100 mt-50">
            <Button className={'p-50'} icon={'Settings'} onClick={handleEditNV} />
          </div>
        }
      </section>

      <hr className="m-50" />
      <section>
        {data?.nueva_nota_venta &&
          <Link to={`/erp/notas-ventas/detalle/${data?.nueva_nota_venta}`} target="_blank" rel="noreferrer">Ir a la nota de venta</Link>
        }
      </section>
    </Modal>
  )
}