import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";


// Components
import Modal from "../../components/Modal";
import FileUploaderB from "../../components/FileUploader_B";
import Button from "../../components/Button";
import SelectFilter from '../../components/SelectFilter';
import DownloadFile from '../../components/Download/DownloadFile';


// Helpers
import { getAll } from "../../endpoints/getAll";
import { fireToaster } from "../../redux/actions/toaster";
import { idExtractor } from '../../helpers/idExtractor';

// Services
import { searchCategorias } from "../../services/categorias/search.service";


const CONSTANT = {
  title: 'Servicios',
  path: 'servicios',
  redirectListado: '/servicios',

  download_template_btn: 'Plantilla de carga masiva de productos',

  // TODO: no se toma en cuenta la categoria_id y el endpoint se rompe si se toma en cuenta con el idExtractor
  urlDownloadTemplate: getAll.servicios + `/plantilla-carga-masiva`,
  urlUploadExcel: (categoriaId) => getAll.servicios + `/carga-masiva/${categoriaId}`,

  fetchCategorias: `${getAll.categorias}/buscar`,
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },

  initialFormState: {
    categoria_id: '',
  },

  selectFilterInitialState: {
    categorias: [],
  },

  fetchTarget: {
    plantilla_masivo: 'download productos masivo',
  },

  modalState: { display: false, target: null }
}


const handleSelectFiltersData = async () => {
  const categorias = await searchCategorias(undefined, { solo_servicios: 1 })
    .then((res) => {
      return [CONSTANT.unselectedObject, ...res?.data?.categorias?.data] ?? [CONSTANT.unselectedObject]
    })
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  return { categorias }
}


const CargaMasiva = () => {
  const dispatch = useDispatch()
  const [modal, setModal] = useState(CONSTANT.modalState)
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
  const [form, setForm] = useState(CONSTANT.initialFormState)
  const [excelFile, setExcelFile] = useState([])

  const [rowsErrors, setRowsErrors] = useState([])


  // EFFECTO QUE DISPARA EL MANEJADOR DE DATA PARA SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  // EFECTO QUE LIMPIA LOS ERRORES
  useEffect(() => {
    return () => {
      setRowsErrors([]);
    }
  }, []);


  // MANEJADOR DE LOS INPUTS
  const handleInputChange = (e) => {
    const { value, name } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  const handleFetch = async () => {
    let config
    let url

    let modalData = new FormData()
    excelFile.length && modalData.append('excel', excelFile[0]?.file)

    if (!form.categoria_id || form.categoria_id.includes('*NULL*')) return dispatch(fireToaster({ title: 'La categoría es obligatoria', icon: 'info' }))
    if (!excelFile[0]?.file) return dispatch(fireToaster({ title: 'Debe seleccionar un archivo', icon: 'info' }))

    url = CONSTANT.urlUploadExcel(idExtractor(form.categoria_id))
    config = {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: modalData
    }

    config = {
      ...config,
      headers: {
        ...config?.headers,
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }
    }

    setRowsErrors([])

    await axios(url, config)
      .then(res => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: carga masiva realizada con exito`,
          icon: 'success'
        }

        dispatch(fireToaster(toasterContent))
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.entries(errores).filter(([clave]) => !clave.includes('fila-')).forEach(([_, errorArray]) => errorArray.forEach(error => detalles.push(error)))

        if (detalles.length) {
          const toasterContent = {
            title: `
                     Operación fallida
                     (${err.response.status} - ${err.response.statusText})
                  `,
            html: `
                     <b>Detalle: </b>
                     ${detalles.map(error => `<br /><i>-${error}</i>`)}
                  `,
            icon: 'error',
            timer: 60000
          }

          dispatch(fireToaster(toasterContent))
        }

        const erroresFilas = Object.entries(errores).filter(([clave]) => clave.includes('fila-'))

        const mappedErroresFilas = erroresFilas.map(([clave, err]) => ({
          row: clave.replace('fila-', ''),
          errors: err
        }))

        setRowsErrors(mappedErroresFilas)
      })

      .finally(() => {
        setModal(CONSTANT.modalState)
      })
  }


  const handleSubmit = async e => {
    e.preventDefault()

    await handleFetch()
  }


  const handleDownloadErrors = () => {
    if (!form.categoria_id || form.categoria_id.includes('*NULL*')) {
      dispatch(fireToaster({ title: 'La categoría es obligatoria', icon: 'info' }))
      return true;
    }

    return false;
  }


  const closeModal = () => setModal({ ...modal, display: false })


  return (
    <div className="row">
      <Modal display={modal.display} handleValue={closeModal}>
        <form className='m-2'>
          <h3 className='p-50 rounded-2 m-0'>Descargar plantilla</h3>
          <hr className='mt-0 mb-1' />

          <div className='col-12 mb-50'>
            <SelectFilter
              labelText="* Categoría"
              name="categoria_id"
              value={form?.categoria_id?.split('-')[1] ?? 'Seleccione'}
              handleValue={handleInputChange}
              optionObj={selectFilter.categorias?.map(({ nombre, id }) => ({ name: nombre, id }))}
              size="col-12"
              sizeDesk='col-12'
            />
          </div>

          <div className='d-flex'>
            <DownloadFile url={CONSTANT.urlDownloadTemplate} method={'GET'} handleErrors={handleDownloadErrors} />
          </div>
        </form>
      </Modal>

      <div className='col-12 mb-1'>
        <div className='d-flex gap-1'>
          <div className='col'>
            <Button text={CONSTANT.title} icon='ArrowLeft' isLink url={CONSTANT.redirectListado} />
          </div>

          <Button text='Descargar plantilla' icon='Download' color='success' onClick={() => setModal({ display: true, target: CONSTANT.fetchTarget.plantilla_masivo })} />
        </div>
      </div>

      <div className='col-12 mb-1'>
        <div className='card'>
          <form className='card-body' onSubmit={handleSubmit}>
            <h3 className='p-50 rounded-2 m-0'>Carga masiva de servicios</h3>
            <hr className='mt-0 mb-1' />

            <div className='row'>
              <SelectFilter
                labelText="* Categoría"
                name="categoria_id"
                value={form?.categoria_id?.split('-')[1] ?? 'Seleccione'}
                handleValue={handleInputChange}
                optionObj={selectFilter.categorias?.map(({ nombre, id }) => ({ name: nombre, id }))}
                size="col-12"
                sizeDesk='col-md-6'
              />

              <div className='col-12 col-md-6 mb-2'>
                <FileUploaderB value={excelFile} handleValue={setExcelFile} containerClassName='col-12 col-md-6' labelText='Excel' />
              </div>
            </div>

            <div className='d-flex'>
              <Button text='Cargar' className='mx-auto' color='success' type='submit' />
            </div>
          </form>
        </div>
      </div>

      {rowsErrors.length > 0 && (
        <div className='col-12'>
          <div className='card'>
            <div className='card-header'>
              <h4 className='card-title'>Errores</h4>
            </div>

            <div className='card-body px-0'>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th style={{ width: 0, whiteSpace: 'nowrap' }} className='text-center'>Fila</th>
                      <th className='text-center'>Errores</th>
                    </tr>
                  </thead>

                  <tbody>
                    {rowsErrors.map(row => (
                      <tr>
                        <td style={{ width: 0, whiteSpace: 'nowrap' }} className='text-center align-middle'>{row.row}</td>

                        <td>
                          <div className='d-flex flex-column'>
                            {row.errors.map(err => (
                              <span className='text-center'>{err}</span>
                            ))}
                          </div>
                          F</td>
                      </tr>
                    ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )
      }
    </div>
  )
}

export default CargaMasiva;