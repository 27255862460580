// Components
import Modal from "../../Modal"


// Helpers
import { PrecioFormatter } from "../../../helpers/precioFormatter";


const PrecioFormat = (v) => new PrecioFormatter(v).producto()

export const ModalBitacoraSolicitud = ({ data, isOpen, handleClose }) => {

  return (
    <Modal
      display={isOpen}
      handleValue={handleClose}
    >
      <h4>Devolución #{data?.devolucion_id}</h4>

      <hr className="mt-50" />

      <h5 className="text-center text-decoration-underline">Información de solicitud </h5>

      <section className="devolucion-info-section">
        <div className="devolucion-info-data">
          <span>Solicitante:</span>
          <b>{data?.usuario?.nombre ?? 'Error'}</b>
        </div>

        <div className="devolucion-info-data">
          <span>Motivo:</span>
          <b>{data?.motivo?.nombre ?? 'Error'}</b>
        </div>
      </section>

      <hr className="mt-50" />

      <h5 className="text-center text-decoration-underline">Información del pedido</h5>

      <section className="devolucion-pedido-info-section">

        <div className="pedido-info-data">
          <span># Pedido:</span>
          <b>{data?.pedido_id ?? 'Error'}</b>
        </div>

        <div className="pedido-info-data">
          <span>Monto total:</span>
          <b>{PrecioFormat(data?.pedido?.monto_total_pedido ?? 0) ?? 'Error'}</b>
        </div>

        <div className="pedido-info-data">
          <span>Punto de entrega:</span>
          <b>{data?.pedido?.direccion_entrega ?? 'Error'}</b>
        </div>

        <div className="pedido-info-data">
          <span>Cliente:</span>
          <b>{data?.pedido?.usuario?.nombre ?? 'Error'}</b>
        </div>
      </section>
    </Modal>
  )
}