
// Components
import { ServiciosFiltros } from "../../components/AccordionFilter/Servicios";
import Button from "../../components/Button";
import { ServiciosTable } from "../../components/Tables/ServiciosTable";

const CONSTANT = {
	title: 'Servicios',
	path: 'servicios',
	create_btn: 'Crear servicio'
}

export const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b> - Listado</p>
			</h3>

			<div className="d-flex gap-1 my-50">
				<Button isLink text={'Crear servicio'} icon={'Plus'} url={`/${CONSTANT.path}/create`} />
				
				<Button isLink text={'Carga masiva'} icon={'Upload'} color={'warning'} url={`/${CONSTANT.path}/carga-masiva`} />
			</div>

			<ServiciosFiltros />

			<ServiciosTable />
		</div>
	);
}
