import { Bar } from 'react-chartjs-2'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  BarElement
} from 'chart.js'
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { responseStringHandler } from '../../../helpers/responseStringHandler';
import Table from '../../Table';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
)

const defaultDatasetData = {
  tension: 1,
  fill: true,
  borderColor: `rgb(114, 252, 109)`,
  backgroundColor: `rgba(114, 252, 109, 0.5)`,
  pointRadius: 5,
  pointBorderColor: `rgba(114, 252, 109, 0.5)`,
  pointBackgroundColor: `rgba(114, 252, 109, 0.5)`
}


const options = {
  scales: {
    x: {
      grid: {
        color: "rgba(0, 0, 0, 0.05)",
      }
    },
    y: {
      grid: {
        color: "rgba(0, 0, 0, 0.05)",
      }
    }
  }
}

export const ChartMotivos = () => {
  const resultados = useSelector(({ devolucionesReportes }) => devolucionesReportes.resultados)
  const [chartDevoluciones, setChartDevoluciones] = useState(null)
  const [chartMontos, setChartMontos] = useState(null)
  const [chartNeumaticos, setChartNeumaticos] = useState(null)


  useEffect(() => {
    if (!resultados?.reporte_parcial?.motivos?.length) return

    const data = resultados?.reporte_parcial?.motivos

    setChartDevoluciones(() => ({
      labels: data?.map(({ motivo }) => responseStringHandler(motivo)),
      datasets: [{
        label: 'Devoluciones',
        data: data?.map(({ devoluciones }) => devoluciones),
        ...defaultDatasetData
      }]
    }))

    setChartMontos(() => ({
      labels: data?.map(({ motivo }) => responseStringHandler(motivo)),
      datasets: [{
        label: 'Montos',
        data: data?.map(({ monto }) => monto),
        ...defaultDatasetData
      }]
    }))

    setChartNeumaticos(() => ({
      labels: data?.map(({ motivo }) => responseStringHandler(motivo)),
      datasets: [{
        label: 'Neumaticos',
        data: data?.map(({ neumaticos }) => neumaticos),
        ...defaultDatasetData
      }]
    }))

    return () => {
      setChartDevoluciones(null)
      setChartMontos(null)
      setChartNeumaticos(null)
    }
  }, [resultados?.reporte_parcial?.motivos])


  return (
    resultados?.reporte_parcial?.motivos?.length
      ?
      <div className='row'>
        <div className='col'>
          <div className='bg-white p-1 my-1'>

            <Table response={resultados?.reporte_parcial?.motivos}>
              <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
                <tr role="row">
                  <th>Motivo</th>
                  <th>Qty. Ventas</th>
                  <th>Qty. NTKO</th>
                  <th>Monto</th>
                  <th>Canal Ppal</th>
                </tr>
              </thead>

              <tbody>
                {resultados?.reporte_parcial?.motivos.length

                  ? resultados?.reporte_parcial?.motivos.map(({
                    motivo, monto, neumaticos, devoluciones, canal_principal
                  }, i) => {
                    return (
                      <tr className="odd" key={`table-reporte-general-row-${i}`}>
                        <td className='col-1 fw-bolder text-uppercase'>{responseStringHandler(motivo)}</td>
                        <td className='col-1 fw-bolder text-uppercase'>{devoluciones ?? 'N/D'}</td>
                        <td className='col-1 fw-bolder text-uppercase'>{neumaticos ?? 'N/D'}</td>
                        <td className='col-1 fw-bolder text-uppercase'>{monto ?? 'N/D'}</td>
                        <td className='col-1 fw-bolder text-uppercase'>{canal_principal ?? 'N/D'}</td>
                      </tr>
                    )
                  })

                  : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
                }
              </tbody>
            </Table>
          </div>
        </div>

        <div className='col'>
          <div className='col card border rounded p-2 my-1'>
            {chartNeumaticos &&
              <Bar data={chartNeumaticos} options={options} />
            }
          </div>

          <div className='col card border rounded p-2 my-1'>
            {chartDevoluciones &&
              <Bar data={chartDevoluciones} options={options} />
            }
          </div>

          <div className='col card border rounded p-2 my-1'>
            {chartMontos &&
              <Bar data={chartMontos} options={options} />
            }
          </div>
        </div>
      </div>

      :
      <div className="col-12">
        <div className="card">
          <h3 className='p-2 text-center'>Por favor, realice una búsqueda</h3>
        </div>
      </div>
  )
}
