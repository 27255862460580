
// Components

import { DevolucionesReporteOperativoFiltros } from "components/AccordionFilter/Devoluciones/DevolucionesReporteOperativo";
import { DevolucionesReporteOperativoTable } from "components/Tables/Devoluciones/DevolucionesReporteOperativoTable";


const CONSTANT = {
	title: 'Devoluciones - Reporte Operativo',
	path: 'devoluciones/reportes',
}


export const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b></p>
			</h3>

			<DevolucionesReporteOperativoFiltros />

			<DevolucionesReporteOperativoTable />
		</div>
	);
}
