import { Navigate, Route, Routes } from "react-router-dom"
import { AutoservicioRouter as IndexRouter } from "./AutoservicioRouter";
import { SucursalesRouter } from "./SucursalesRouter";
import { UsuariosSucursalesRouter } from "./UsuariosSucursalesRouter";

export const AutoservicioRouter = () => {
   return (
      <Routes>
         <Route path='/' element={<IndexRouter />} />

         <Route path='/sucursales/*' element={<SucursalesRouter />} />

         <Route path='/usuarios-sucursales/*' element={<UsuariosSucursalesRouter />} />

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   );
}
