import axios from "axios"
import { getOne } from "../../endpoints/getOne"
import { successResponse } from "helpers/successResponse"

const URL = (id) => getOne.pedidos(id)

export const getDetailedPedido = async (id) => {
  try {
    if (!id) throw Error(`Missing "id" devolucion`)

    const response = await axios(URL(id), {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }
    })

    if (!successResponse(response)) throw response
    
    return response
  } catch (err) {
    return Promise.reject(err)
  }
}
