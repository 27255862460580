
// Components
import { HorariosDespachoFiltros } from "../../components/AccordionFilter/HorariosDespacho";
import Button from "../../components/Button";
import { HorariosDespachoTable } from "../../components/Tables/HorariosDespachoTable";

const CONSTANT = {
	title: 'Horarios de despacho',
	path: 'horarios-despacho',
	create_btn: 'Crear horario de despacho'
}

export const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b> - Listado</p>

				<Button text={'Crear horario de despacho'} icon={'Plus'} isLink url={`/${CONSTANT.path}/create`} />
			</h3>

			<HorariosDespachoFiltros />

			<HorariosDespachoTable />
		</div>
	);
}
