
// Components

import { DevolucionesReporteGeneralFiltros } from "components/AccordionFilter/Devoluciones/DevolucionesReporteGeneral";
import { DevolucionesReporteGeneralTable } from "components/Tables/Devoluciones/DevolucionesReporteGeneralTable";


const CONSTANT = {
	title: 'Devoluciones - Reporte General',
	path: 'devoluciones/reportes',
}


export const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b></p>
			</h3>

			<DevolucionesReporteGeneralFiltros />

			<DevolucionesReporteGeneralTable />
		</div>
	);
}
