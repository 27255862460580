import React, { useEffect, useState } from 'react'


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from '../../../redux/actions/toaster'
import { FILTER_AUTOGESTION_INPUT } from '../../../redux/redux-types'
import {
  autoservicioSucursalesInputFilter,
  resultadosAutoservicioSucursales,
  clearFilterAutoservicioSucursales
} from '../../../redux/actions/autoservicioSucursales'


// Components
import FormInput from '../../FormInput'


// Layouts
import ListadoHeader from '../../../layout/ListadoHeader'


// Helpers
import { filtrosAplicadosTotal } from '../../../helpers/filtrosAplicadosTotal'


// Services
import { getAllSucursales } from '../../../services/autogestion/sucursales/getAll.service'
import { responseToasterContent } from '../../../helpers/toasterContent'
import CustomLoader from '../../CustomLoader'


const CONSTANT = {
  redirectUrl: '/autoservicio/sucursales',
  reduxStateSelector: 'autoservicioSucursales',
  title: 'Filtros de sucursales',

  reduxClearFilters: clearFilterAutoservicioSucursales,
  reduxSetResults: resultadosAutoservicioSucursales,

  nullValue: { id: 'NULL', nombre: 'Sin selección' },
}


const fetchData = async (params, limit) => {
  return await getAllSucursales(params, limit)
    .then(res => res)
    .catch(err => {
      console.error(err);
      return Promise.reject(err)
    })
}


export const SucursalesFiltros = () => {
  const { filtros } = useSelector(state => state[CONSTANT.reduxStateSelector])
  const { showing_results } = useSelector(state => state.listedResults)
  const { busqueda } = filtros
  const dispatch = useDispatch()

  const [isConsulting, setIsConsulting] = useState(false)


  useEffect(() => {
    return () => dispatch(CONSTANT.reduxClearFilters())
  }, [dispatch])


  // FUNCION QUE CONSULTA EL BACKEND CON LOS FILTROS SELECCIONADOS
  const handleFetchFilter = async (reset = false) => {
    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())
      return
    }

    setIsConsulting(true)

    await fetchData(filtros, showing_results)
      .then(res => {
        dispatch(CONSTANT.reduxSetResults(res.data))
      })
      .catch(err => {
        dispatch(CONSTANT.reduxSetResults([]))
        dispatch(fireToaster(responseToasterContent(err)))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }

  // MANEJADORES DE FILTRO
  const handleAddFilter = (e, filter) => {
    const { value, name: key } = e.target

    switch (filter) {
      case FILTER_AUTOGESTION_INPUT:
        dispatch(autoservicioSucursalesInputFilter({ key, value }))
        break;

      default:
        break;
    }
  }


  return (
    <div className="card mb-50 shadow-none bg-transparent">
      {isConsulting && <CustomLoader blocking={'partial'} />}

      <ListadoHeader
        classes='border rounded-2'
        title={`${CONSTANT.title} (${filtrosAplicadosTotal(filtros)})`}
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetchFilter()}
        handleClickClearFilter={() => handleFetchFilter(true)}
      >
        <>
          <FormInput
            labelText='Búsqueda'
            placeholder='Ingrese una búsqueda'
            name='busqueda'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_AUTOGESTION_INPUT)}
            value={busqueda}
          />
        </>
      </ListadoHeader>
    </div>
  )
}