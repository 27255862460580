
// Components

import { DevolucionesReporteParcialFiltros } from "components/AccordionFilter/Devoluciones/DevolucionesReporteParcial";
import Tabs from "components/Tabs";
import { ReporteParcialRouter } from "routers/Devoluciones/ReporteParcialRouter";


const CONSTANT = {
	title: 'Devoluciones - Reporte Parcial',
	path: 'devoluciones/reportes',
}


const tabsList = [
	{ name: 'Vendedores', url: '/devoluciones/reportes/parcial/vendedores' },
	{ name: 'Motivos', url: '/devoluciones/reportes/parcial/motivos' },
	{ name: 'Canales', url: '/devoluciones/reportes/parcial/canales' },
]

export const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b></p>
			</h3>

			<DevolucionesReporteParcialFiltros />

			<Tabs
				tabs={tabsList}
			/>

			<ReporteParcialRouter />
		</div>
	);
}
