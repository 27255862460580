import axios from "axios"
import { getAll as endpoint } from "../../endpoints/getAll"
import { queryParamsFormatter } from "../../helpers/queryParamsFormatter"
import { successResponse } from "helpers/successResponse"


export const searchCategorias = async (params = {}, limit = 9999, paginationUrl = undefined, data) => {
  try {
    const response = await axios(`${paginationUrl ?? `${endpoint.categorias}/buscar`}${paginationUrl ? '&' : '?'}limit=${limit}${queryParamsFormatter(params)}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST',
      data
    })

    if (!successResponse(response)) throw response

    return response
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}