import {
   FILTER_CLEAR,
   FILTER_FECHA_FINAL,
   FILTER_FECHA_INICIO,
   RESULTADOS_DEVOLUCIONES_REPORTES,
} from "../redux-types";

export const fechaInicialFilter = (data) => ({ type: FILTER_FECHA_INICIO, payload: data })

export const fechaFinalFilter = (data) => ({ type: FILTER_FECHA_FINAL, payload: data })

export const resultadosDevolucionesReportes = (data) => ({ type: RESULTADOS_DEVOLUCIONES_REPORTES, payload: data })

export const clearFilterDevolucionesReportes = () => ({ type: FILTER_CLEAR });
