import { Navigate, Route, Routes } from "react-router-dom"
import { ChartVendedores } from "../../components/Charts/DevolucionesReporteParcial/ChartVendedores";
import { ChartMotivos } from "../../components/Charts/DevolucionesReporteParcial/ChartMotivos";
import { ChartCanales } from "../../components/Charts/DevolucionesReporteParcial/ChartCanales";


export const ReporteParcialRouter = () => {
   return (
      <Routes>
         <Route path='/vendedores' element={<ChartVendedores />} />

         <Route path='/motivos' element={<ChartMotivos />} />

         <Route path='/canales' element={<ChartCanales />} />

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   );
}
