import axios from "axios"
import { getAll } from "../../../endpoints/getAll"
import { successResponse } from "../../../helpers/successResponse"


const URL = getAll.usuarios_sucursales


export const updateUsusarioSucursal = async (id, data) => {
  try {
    const response = await axios(URL + `/${id}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'PUT',
      data
    })

    if (!successResponse(response)) throw response

    return response
  } catch (err) {
    return Promise.reject(err)
  }
}